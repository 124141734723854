import {VolumeViewport} from '@cornerstonejs/core';
import {VolumeViewport3D} from '@cornerstonejs/core/dist/cjs/RenderingEngine';
import {capitalCase} from 'change-case';
import delay from 'delay';
import {Trash2Icon} from 'lucide-react';
import React, {useState} from 'react';
import {
	type SelectProps,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	IconButton,
	ListItemDecorator,
	Modal,
	ModalDialog,
	Option,
	Select,
	Stack,
	Table,
	Textarea,
	Tooltip,
	Typography,
} from '@mui/joy';
import {annotation as cornerstoneAnnotation} from '@cornerstonejs/tools';
import {Entries} from 'type-fest';

import {annotationIcons} from '@/icons';
import {
	type Annotation,
	type AnnotationData,
	type AnnotationTool,
	captureScreenshot,
} from '@/library';
import {type ViewportElementKey} from '@/library/cornerstone';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import {type VtkViewport} from '@/state/viewports';

const formatValue = (value: number | string) => {
	if (typeof value === 'number' && !Number.isInteger(value)) {
		return Math.abs(value).toFixed(2);
	}

	return value;
};

export default function Annotations({
	onSave,
	scan,
	viewports,
}: {
	readonly onSave: () => void;
	readonly scan: Scan;
	readonly viewports: Record<
		ViewportElementKey,
		VtkViewport | VolumeViewport | VolumeViewport3D | undefined
	>;
}) {
	const {
		annotations: {
			addAnnotation,
			annotations,
			areAnnotationsDirty,
			currentTool,
			notes,
			removeAnnotation,
			setAreAnnotationsDirty,
			setCurrentTool,
			setNotes,
			setState,
			updateAnnotation,
		},
		viewports: {axialViewport, sagittalViewport, coronalViewport},
	} = useGlobalState();
	const handleSelectCurrentTool: SelectProps<
		AnnotationTool,
		false
	>['onChange'] = async (_, newTool) => {
		if (newTool === null) return;

		setCurrentTool(newTool);
	};

	const [openModal, setOpenModal] = useState(false);
	const [selectedAnnotation, setSelectedAnnotation] = useState<
		Annotation | undefined
	>();

	const handleOpenModal = (annotation: Annotation) => {
		setSelectedAnnotation(annotation);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setSelectedAnnotation(undefined);
	};

	const handleConfirmDelete = () => {
		if (selectedAnnotation) {
			const annotationManager =
				cornerstoneAnnotation.state.getDefaultAnnotationManager();

			annotationManager.removeAnnotation(selectedAnnotation.id);
			removeAnnotation(selectedAnnotation.id);

			viewports[selectedAnnotation.viewportId]?.render();

			setAreAnnotationsDirty(true);

			handleCloseModal();
		}
	};

	const renderAnnotationTable = (data: AnnotationData) =>
		Object.entries(data)
			.filter(([, value]) => value !== undefined && value !== null)
			.filter(([key]) => key !== 'text')
			.map(([key, value]) => (
				<tr key={key}>
					<td>{capitalCase(key)}</td>
					<td>{formatValue(value)}</td>
				</tr>
			));

	const handleClickUpdateScreenshot = async (annotationId: string) => {
		const annotation = annotations.find((a) => a.id === annotationId);

		if (!annotation) {
			throw new Error(`Annotation with id ${annotationId} not found`);
		}

		const screenshotDataUrl = await captureScreenshot({
			viewportKey: annotation.viewportId,
		});

		if (screenshotDataUrl) {
			updateAnnotation(annotationId, {screenshotDataUrl});
		}

		setAreAnnotationsDirty(true);
	};

	const handleRevertAnnotationsChanges = async () => {
		setState('updating');

		await delay(1);

		for (const annotation of annotations) {
			const annotationManager =
				cornerstoneAnnotation.state.getDefaultAnnotationManager();

			annotationManager.removeAnnotation(annotation.id);
			removeAnnotation(annotation.id);

			viewports[annotation.viewportId]?.render();
			handleCloseModal();
		}

		for (const annotation of scan.annotations.annotations) {
			addAnnotation(annotation);
		}

		setNotes(scan.annotations.notes);

		axialViewport?.render();
		sagittalViewport?.render();
		coronalViewport?.render();

		setAreAnnotationsDirty(false);
		setState('ready');
	};

	const CurrentToolIcon = annotationIcons[currentTool];

	return (
		<Stack spacing={4}>
			{/* Dirty annotations alert / actions */}
			{areAnnotationsDirty && (
				<Alert>
					<Stack spacing={2}>
						<span>You&rsquo;ve made changes to the Annotations.</span>

						<Stack direction="row" spacing={2}>
							<Button
								color="danger"
								size="sm"
								variant="outlined"
								onClick={async () => {
									await handleRevertAnnotationsChanges();
								}}
							>
								Revert Changes
							</Button>
							<Button size="sm" onClick={onSave}>
								Save Changes
							</Button>
						</Stack>
					</Stack>
				</Alert>
			)}
			<Stack spacing={1}>
				<Typography level="title-md">Notes</Typography>
				<Textarea
					minRows={4}
					value={notes}
					onChange={(event) => {
						setNotes(event.target.value);

						setAreAnnotationsDirty(true);
					}}
				/>
			</Stack>
			<Stack spacing={1}>
				<Typography level="title-md">Select Annotation Tool</Typography>
				<Select<AnnotationTool>
					value={currentTool}
					startDecorator={<CurrentToolIcon />}
					onChange={handleSelectCurrentTool}
				>
					{(Object.entries(annotationIcons) as Entries<typeof annotationIcons>)
						.filter((toolType) => toolType[0] !== 'Screenshot')
						.map(([toolType, Icon]) => (
							<Option key={toolType} value={toolType}>
								<ListItemDecorator>
									<Icon />
								</ListItemDecorator>
								{toolType}
							</Option>
						))}
				</Select>
			</Stack>

			<Stack spacing={1}>
				<Typography level="title-md">Manage Annotations</Typography>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="flex-start"
					spacing={2}
				>
					{annotations.length > 0
						? annotations.map((annotation) => {
								const Icon = annotationIcons[annotation.toolType];

								return (
									<Accordion
										key={annotation.id}
										variant="outlined"
										sx={{width: '100%'}}
									>
										<AccordionSummary>
											<Typography
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													gap: 1,
													p: 1,
												}}
												component="span"
											>
												<Icon />
												<Divider orientation="vertical" color="primary" />
												<span style={{textTransform: 'capitalize'}}>
													{annotation.viewportId}
												</span>
												<Divider orientation="vertical" />
												{annotation.imageIndex}
											</Typography>

											<Tooltip color="primary" title="Delete Annotation">
												<IconButton
													sx={{position: 'absolute', right: '2.5rem'}}
													onClick={async (event) => {
														event.stopPropagation();
														handleOpenModal(annotation);
													}}
												>
													<Trash2Icon />
												</IconButton>
											</Tooltip>
										</AccordionSummary>

										<AccordionDetails>
											<Stack spacing={2} sx={{p: 1}}>
												{annotation.screenshotDataUrl && (
													<img
														src={annotation.screenshotDataUrl}
														alt="Annotation Screenshot"
														style={{maxWidth: '100%', borderRadius: '.5rem'}}
													/>
												)}
												<Button
													onClick={async () => {
														await handleClickUpdateScreenshot(annotation.id);
													}}
												>
													{annotation.screenshotDataUrl ? 'Update' : 'Create'}{' '}
													Screenshot
												</Button>

												{annotation.data &&
													Object.keys(annotation.data).length > 0 && (
														<Table size="md" aria-label="Annotation Data">
															<tbody>
																{renderAnnotationTable(annotation.data)}
															</tbody>
														</Table>
													)}

												<Stack spacing={1}>
													<Typography level="title-md">Notes</Typography>
													<Textarea
														minRows={2}
														value={annotation.note}
														onChange={(event) => {
															const newNote = event.target.value;
															const updatedFields = {
																note: newNote,
																data: {...annotation.data, text: newNote},
															};

															updateAnnotation(annotation.id, updatedFields);

															if (annotation.toolType === 'ArrowAnnotate') {
																const annotationManager =
																	cornerstoneAnnotation.state.getDefaultAnnotationManager();
																const annotationObject =
																	annotationManager.getAnnotation(
																		annotation.id,
																	);

																if (annotationObject === undefined) {
																	throw new Error(
																		'Failed to get annotation object',
																	);
																}

																annotationObject.data.text = newNote;
																viewports[annotation.viewportId]?.render();
															}
														}}
													/>
												</Stack>
											</Stack>
										</AccordionDetails>
									</Accordion>
								);
						  })
						: undefined}

					<Modal
						open={openModal}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
						onClose={handleCloseModal}
					>
						<ModalDialog>
							<DialogTitle>Delete Annotation</DialogTitle>

							<Divider />

							<DialogContent>
								<Typography>
									Are you sure you want to delete this annotation?
								</Typography>
							</DialogContent>

							<DialogActions>
								<Button
									variant="solid"
									color="danger"
									onClick={handleConfirmDelete}
								>
									Delete
								</Button>
								<Button variant="outlined" onClick={handleCloseModal}>
									Cancel
								</Button>
							</DialogActions>
						</ModalDialog>
					</Modal>
				</Stack>
			</Stack>
		</Stack>
	);
}
