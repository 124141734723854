import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

import {type GumballData} from '@/library/gumball';

interface InteractionState {
	currentInteractionType: 'resize' | 'translate' | 'rotate' | undefined;
	isDragging: boolean;
	isRKeyPressed: boolean;
	isRKeyPressedAtStart: boolean;
	lastPosition: {x: number; y: number} | undefined;
	pickedAxis: 'x' | 'y' | 'z' | undefined;
	gumball: GumballData | undefined;
	pickedGumballHandle: vtkActor | undefined;
	selectedDigitalTwinId: string | undefined;
}

interface InteractionActions {
	setCurrentInteractionType: (
		type: 'resize' | 'translate' | 'rotate' | undefined,
	) => void;
	setIsDragging: (isDragging: boolean) => void;
	setIsRKeyPressed: (isPressed: boolean) => void;
	setIsRKeyPressedAtStart: (isPressed: boolean) => void;
	setLastPosition: (position: {x: number; y: number} | undefined) => void;
	setPickedAxis: (axis: 'x' | 'y' | 'z' | undefined) => void;
	setGumball: (gumball: GumballData | undefined) => void;
	setPickedGumballHandle: (handle: vtkActor | undefined) => void;
	resetInteraction: () => void;
}

export const useInteractionStore = create(
	immer<InteractionState & InteractionActions>((set) => ({
		currentInteractionType: undefined,

		isDragging: false,
		isRKeyPressed: false,
		isRKeyPressedAtStart: false,
		lastPosition: undefined,
		pickedAxis: undefined,
		gumball: undefined,
		pickedGumballHandle: undefined,
		selectedDigitalTwinId: undefined,
		setCurrentInteractionType: (type) =>
			set((state) => {
				state.currentInteractionType = type;
			}),
		setIsDragging: (isDragging) =>
			set((state) => {
				state.isDragging = isDragging;
			}),
		setIsRKeyPressed: (isPressed) =>
			set((state) => {
				state.isRKeyPressed = isPressed;
			}),
		setIsRKeyPressedAtStart: (isPressed) =>
			set((state) => {
				state.isRKeyPressedAtStart = isPressed;
			}),
		setLastPosition: (position) =>
			set((state) => {
				state.lastPosition = position;
			}),
		setPickedAxis: (axis) =>
			set((state) => {
				state.pickedAxis = axis;
			}),
		setGumball: (gumball) =>
			set((state) => {
				state.gumball = gumball;
			}),
		setPickedGumballHandle: (handle) =>
			set((state) => {
				state.pickedGumballHandle = handle;
			}),
		resetInteraction: () =>
			set((state) => {
				state.isDragging = false;
				state.lastPosition = undefined;
				state.pickedAxis = undefined;
				state.pickedGumballHandle = undefined;
			}),
	})),
);
