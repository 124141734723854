/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

/* eslint-disable-next-line import/no-cycle --
 * TODO: Break this circular dependency
 */
import {pointCloudsPointSize} from '@/library/vtk/point-clouds';

import {
	getPointCloudColorScheme,
	type PointCloudColorScheme,
} from '@/library/vtk/point-cloud-color-schemes';
import {
	type AuxMeshObject,
	type AuxPointCloudObject,
	type DrcData,
} from '@/types/cornerstone';

import {getDataPath} from '../api/urls';
import vtkColorTransferFunction from '../vtkClasses/vtk-extended-color-transfer-function';

import {colorize} from './colorize';
import initializeDracoReaderPromise from './initialize-draco-reader-promise';
import vtkDracoReader from './vtk-draco-reader';

export const createAuxMeshObjects = (
	meshData: DrcData[],
	scanId,
	userId,
	version,
): AuxMeshObject[] =>
	meshData.map(({extension, fileName, id, label}, index) => ({
		color: colorize(index),
		file: getDataPath({
			path: `${fileName}.${extension}`,
			scanId,
			userId,
			version,
		}),
		id,
		label,
	}));

export const createAuxPointCloudObjects = (
	pointCloudData: DrcData[],
	scanId: string,
	userId: string,
	version: number,
): AuxPointCloudObject[] =>
	pointCloudData.map(({extension, fileName, id, label}) => {
		const file = getDataPath({
			path: `${fileName}.${extension}`,
			scanId,
			userId,
			version,
		});

		return {
			file,
			id,
			label,
		};
	});

function makeActor(reader, range: {max: number; min: number}, lut) {
	const mapper = vtkMapper.newInstance({
		scalarVisibility: true,
		useLookupTableScalarRange: true,
	});

	const actor = vtkActor.newInstance();
	actor.getProperty().setOpacity(1);
	actor.getProperty().setPointSize(pointCloudsPointSize);
	actor.getProperty().setEdgeVisibility(false);
	actor.setMapper(mapper);

	mapper.setInputConnection(reader.getOutputPort());
	mapper.setLookupTable(lut);
	mapper.setScalarRange([range.min, range.max]);
	mapper.setScalarModeToUsePointData();

	return actor;
}

export function calculateGlobalRange(actors: vtkActor[]): {
	max: number;
	min: number;
} {
	const globalRange = {max: -Infinity, min: Infinity};

	for (const actor of actors) {
		const actorRange = actor
			.getMapper()
			?.getInputData()
			?.getPointData()
			?.getScalars()
			?.getRange();

		if (actorRange) {
			globalRange.min = Math.min(globalRange.min, actorRange[0]);
			globalRange.max = Math.max(globalRange.max, actorRange[1]);
		}
	}

	return globalRange;
}

export default async function dracoPointCloudReader(urls): Promise<{
	actors: vtkActor[];
	backupActors: vtkActor[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	lut: any;
	range: {max: number; min: number};
}> {
	await initializeDracoReaderPromise;

	const readers = urls.map(() => vtkDracoReader.newInstance());

	const readerPromises = readers.map((reader, index) =>
		reader.setUrl(urls[index]),
	);

	await Promise.all(readerPromises);

	const range = {max: -Infinity, min: Infinity};

	for (const reader of readers) {
		const dataRange = reader
			.getOutputData()
			.getPointData()
			.getScalars()
			.getRange();

		range.min = Math.min(dataRange[0], range.min);
		range.max = Math.max(dataRange[1], range.max);
	}

	const lut = makeLut(range);

	const actors = [];
	const backupActors = [];

	for (const reader of readers) {
		const actor = makeActor(reader, range, lut);
		const backupActor = makeActor(reader, range, lut);

		actors.push(actor);
		backupActors.push(backupActor);
	}

	return {actors, backupActors, range, lut};
}

function addRgbPoint(
	lut,
	range: {max: number; min: number},
	colorScheme?: PointCloudColorScheme = getPointCloudColorScheme({
		id: 'nativeBone',
	}),
) {
	for (const {percentage, color} of colorScheme.colors) {
		if (percentage >= 0 && percentage <= 1) {
			const {max, min} = range;
			const pointOfReference = percentage * Math.abs(max - min) + min;

			lut.addRGBPoint(pointOfReference, ...color);
		}
	}
}

export function makeLut(
	range: {max: number; min: number},
	colorScheme?: PointCloudColorScheme,
) {
	const lut = vtkColorTransferFunction.newInstance();

	lut.setUseBelowRangeColor(true);
	lut.setUseAboveRangeColor(true);
	lut.setNanColor(0, 0, 0, 0);
	lut.setAboveRangeColor(0, 0, 0, 0);
	lut.setBelowRangeColor(0, 0, 0, 0);

	addRgbPoint(lut, range, colorScheme);
	lut.setThresholdRange(range.min, range.max);

	return lut;
}
