import delay from 'delay';

import {updateResectionPlanesVisibility} from '@/library';
import {useGlobalState} from '@/state';
import {type CheckboxChangeHandler} from '@/types';

export default function useResectionPlanesVisibility(): {
	handleVisibilityChange: CheckboxChangeHandler;
	ready: boolean;
	visibility: boolean;
} {
	const {resectionPlanes} = useGlobalState();

	const ready = resectionPlanes.state === 'ready';

	const handleVisibilityChange: CheckboxChangeHandler = async (event) => {
		await setVisibility(event.target.checked);
	};

	async function setVisibility(visibility: boolean) {
		if (!ready) return;

		resectionPlanes.setState('updating');
		resectionPlanes.setVisibility(visibility);

		await delay(0);

		updateResectionPlanesVisibility({
			isVisible: visibility,
			selectedPair: resectionPlanes.selectedPairKey,
		});

		resectionPlanes.setState('ready');
	}

	return {
		handleVisibilityChange,
		ready,
		visibility: resectionPlanes.visibility,
	};
}
