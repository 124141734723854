import {useEffect, useState} from 'react';

import {useGlobalState} from '@/state';

import loadAndEnableSegmentation from '../utils/load-and-enable-segmentation';
import {type Scan} from '../library/models';

const useLoadMasks = ({scan, volumeId}: {scan: Scan; volumeId: string}) => {
	const {
		scan: {bonemaskBinaries},
		viewports: {volumeViewport},
	} = useGlobalState();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		const loadMasks = async () => {
			if (
				isLoaded ||
				!volumeId ||
				!bonemaskBinaries ||
				!scan.hasReachedMilestone('kneeSegmented')
			) {
				return;
			}

			try {
				await loadAndEnableSegmentation(
					bonemaskBinaries,
					volumeId,
					'BONE_SEGMENTATION',
				);
				volumeViewport?.render();
				setIsLoaded(true);
			} catch (error) {
				console.error(error);
			}
		};

		loadMasks().catch((error) => {
			console.error('Error in loading masks:', error);
		});
	}, [isLoaded, volumeId, bonemaskBinaries, scan, volumeViewport]);

	return {isLoaded};
};

export default useLoadMasks;
