import {type ViewportElementKey} from './viewport-element-keys';

export type Elements = Record<ViewportElementKey, HTMLDivElement | undefined>;

export function areElementsDefined() {
	return Object.entries(elements)
		.filter(([key]) => key !== 'volume')
		.every(([, element]) => element !== undefined);
}

export const elements: Elements = {
	axial: undefined,
	coronal: undefined,
	sagittal: undefined,
	volume: undefined,
};
