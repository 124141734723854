import {meshes as meshesLibrary} from '@/library';
import {useGlobalState} from '@/state';

export default function useMeshesVisibility() {
	const {meshes: globalMeshes} = useGlobalState();

	function setAllMeshesVisibility(visibility: boolean) {
		for (const mesh of globalMeshes.meshes) {
			setMeshVisibility({
				id: mesh.id,
				visibility,
			});
		}
	}

	function setMeshVisibility({
		id,
		visibility,
	}: {
		id: string;
		visibility: boolean;
	}): void {
		meshesLibrary.setVisibility({id, visibility});
		globalMeshes.updateMesh({id, visibility});
	}

	return {
		all: {
			setVisibility: setAllMeshesVisibility,
			visibility: globalMeshes.all.visibility,
		},
		individual: globalMeshes.meshes,
		loaded: globalMeshes.loaded,
		setVisibility: setMeshVisibility,
	};
}
