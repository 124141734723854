import is from '@sindresorhus/is';
import {updatedDiff} from 'deep-object-diff';
import {merge} from 'lodash';
import {type PartialDeep} from 'type-fest';

import {useUserStore} from '@/state/user';

import isDefined from '../is-defined';
import * as firebase from '../firebase';
import getCurrentSettings from './get-current';
import getDefaultSettings from './get-default';
import {type Settings} from './settings';

const {
	firestore: {updateUser},
} = firebase;

/**
 * Get the diff between the default settings (either ours or the user's) and
 * the currently applied settings
 */
export function getDiff(): PartialDeep<Settings> | undefined {
	const {user} = useUserStore.getState();

	if (user === undefined) return;

	const defaultSettings = getDefaultSettings();

	if (defaultSettings === undefined) return;

	const baseSettings = merge(defaultSettings, user.customDefaults);
	const currentSettings = getCurrentSettings();

	const diff = updatedDiff(
		baseSettings,
		currentSettings,
	) as PartialDeep<Settings>;

	if (isDefined(diff.visibility)) {
		if (
			Object.hasOwn(diff.visibility, 'meshes') &&
			diff.visibility.meshes === undefined
		) {
			delete diff.visibility.meshes;
		}

		if (
			Object.hasOwn(diff.visibility, 'pointClouds') &&
			diff.visibility.pointClouds === undefined
		) {
			delete diff.visibility.pointClouds;
		}

		if (is.emptyObject(diff.visibility)) {
			delete diff.visibility;
		}
	}

	return diff;
}

/**
 * Save pending changes to Custom Default Settings to the user's Firestore
 * document
 */
export async function save() {
	const {user} = useUserStore.getState();

	if (user === undefined) return;

	const defaultSettings = getDefaultSettings();

	if (defaultSettings === undefined) return;

	const currentSettings = getCurrentSettings();
	const newCustomDefaults = updatedDiff(defaultSettings, currentSettings);

	await updateUser({id: user.id, customDefaults: newCustomDefaults});
}
