import {init as csRenderInit} from '@cornerstonejs/core';
import {init as csToolsInit} from '@cornerstonejs/tools';

import initializeCornerstoneWadoImageLoader from './initialize-cornerstone-wado-image-loader';
import initializeProviders from './initialize-providers';
import initializeVolumeLoader from './initialize-volume-loader';

export default async function initializeCornerstone3d() {
	initializeProviders();
	initializeCornerstoneWadoImageLoader();
	initializeVolumeLoader();
	await csRenderInit();
	csToolsInit();
}
