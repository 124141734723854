import {
	CrosshairsTool,
	Enums as CornerstoneEnums,
	PanTool,
	StackScrollMouseWheelTool,
	WindowLevelTool,
	ZoomTool,
} from '@cornerstonejs/tools';
import {useToggle} from 'react-use';
import {useEffect} from 'react';

import {cornerstone} from '@/library';

const ignoredTools = [
	PanTool.toolName,
	StackScrollMouseWheelTool.toolName,
	ZoomTool.toolName,
];

export default function useCrosshairs() {
	const [areEnabled, toggleAreEnabled] = useToggle(false);

	useEffect(() => {
		const toolGroup = cornerstone.toolGroups.ctViewport.get();

		if (!toolGroup) return;

		cornerstone.toolGroups.setAllToolsDisabled({
			toolGroup,
			except: ignoredTools,
		});

		if (areEnabled) {
			toolGroup.setToolActive(CrosshairsTool.toolName, {
				bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
			});
		} else {
			toolGroup.setToolActive(WindowLevelTool.toolName, {
				bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
			});
		}
	}, [areEnabled]);

	return [areEnabled, toggleAreEnabled] as const;
}
