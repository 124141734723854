import delay from 'delay';
import {useCallback, useEffect, useState} from 'react';

import {isDefined, updateResectionPlanesVisibility} from '@/library';
import {useGlobalState} from '@/state';

export default function useSetResectionPlanesVisibility() {
	const {
		resectionPlanes,
		user: {user},
	} = useGlobalState();
	const [applied, setApplied] = useState<boolean>(false);

	const setVisibility = useCallback(
		async (visibility: boolean) => {
			resectionPlanes.setState('updating');

			// Render
			await delay(0);

			updateResectionPlanesVisibility({
				isVisible: visibility,
				selectedPair: resectionPlanes.selectedPairKey,
			});

			resectionPlanes.setVisibility(visibility);
			resectionPlanes.setState('ready');
		},
		[resectionPlanes],
	);

	useEffect(() => {
		async function apply() {
			if (applied || resectionPlanes.state !== 'ready' || !isDefined(user)) {
				return;
			}

			const visibility = user.customDefaults?.visibility?.resectionPlanes;

			if (isDefined(visibility) && visibility !== resectionPlanes.visibility) {
				await setVisibility(visibility);
			}

			setApplied(true);
		}

		apply().catch(console.error);
	}, [
		applied,
		resectionPlanes.state,
		resectionPlanes.visibility,
		setVisibility,
		user,
	]);
}
