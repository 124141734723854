import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import {
	Contact,
	LogIn,
	logInLoader,
	LogOut,
	NewScan,
	NotFound,
	Private,
	privateLoader,
	Root,
	Scan,
	Scans,
	scansLoader,
} from '@/routes';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <Root />,
		children: [
			{
				path: 'contact',
				element: <Private />,
				children: [
					{
						path: '',
						element: <Contact />,
					},
				],
			},
			{
				path: 'log-in',
				element: <LogIn />,
				errorElement: <LogIn />,
				loader: logInLoader,
			},
			{
				path: 'log-out',
				element: <LogOut />,
			},
			{
				path: 'scans',
				element: <Private />,
				loader: privateLoader,
				children: [
					{
						path: '',
						element: <Scans />,
						loader: scansLoader,
					},
					{
						path: 'new',
						element: <NewScan />,
					},
				],
			},
			{
				path: '*',
				element: <NotFound />,
			},
		],
	},
	// Scan page is not a child of <Root> because it has its own layout
	{
		path: 'scans/:scanId',
		element: <Private />,
		loader: privateLoader,
		children: [
			{
				path: '',
				element: <Scan />,
				errorElement: <Scan />,
			},
		],
	},
]);

export default function Router() {
	return <RouterProvider router={router} />;
}
