/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import {annotation, drawing, ProbeTool} from '@cornerstonejs/tools';
import {type IEnabledElement} from '@cornerstonejs/core/dist/cjs/types';
import {type SVGDrawingHelper} from '@cornerstonejs/tools/dist/cjs/types';

const {getAnnotations} = annotation.state;
const {drawHandles, drawTextBox} = drawing;

export default class LandmarkTool extends ProbeTool {
	renderAnnotation = (
		enabledElement: IEnabledElement,
		svgDrawingHelper: SVGDrawingHelper,
	) => {
		const {viewport} = enabledElement;
		const {element} = viewport;

		let annotations = getAnnotations(element, ProbeTool.toolName);

		if (!annotations?.length) {
			return;
		}

		annotations = this.filterInteractableAnnotationsForElement(
			element,
			annotations,
		);

		if (!annotations?.length) {
			return;
		}

		const styleSpecifier = {
			toolGroupId: this.toolGroupId,
			toolName: this.getToolName(),
			viewportId: enabledElement.viewport.id,
		};

		for (const annotation of annotations) {
			const {annotationUID} = annotation;
			const {data} = annotation;
			const point = data.handles.points[0];
			const canvasCoordinates = viewport.worldToCanvas(point);

			styleSpecifier.annotationUID = annotationUID;
			const color = this.getStyle('color', styleSpecifier, annotation);

			// If rendering engine has been destroyed while rendering
			if (!viewport.getRenderingEngine()) {
				console.warn('Rendering Engine has been destroyed');
				return;
			}

			const handleGroupUid = '0';

			drawHandles(
				svgDrawingHelper,
				annotationUID,
				handleGroupUid,
				[canvasCoordinates],
				{
					color,
				},
			);

			const textLines = [data.label];
			if (textLines) {
				const textCanvasCoordinates = [
					canvasCoordinates[0] + 6,
					canvasCoordinates[1] - 6,
				];

				const textUid = '0';
				drawTextBox(
					svgDrawingHelper,
					annotationUID,
					textUid,
					textLines,
					[textCanvasCoordinates[0], textCanvasCoordinates[1]],
					this.getLinkedTextBoxStyle(styleSpecifier, annotation),
				);
			}
		}
	};
}
