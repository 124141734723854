import {useEffect, useState} from 'react';

import {api} from '@/library';
import {useGlobalState} from '@/state';

import {getSegmentationPath} from '../api/urls';
import useFirebaseAuthState from './use-firebase-auth-state';
import {type DrcData} from '../types/cornerstone';
import {
	createAuxMeshObjects,
	createAuxPointCloudObjects,
} from '../utils/draco-point-cloud-reader';
import {getRawData} from '../utils/fetch-raw-data';
import {type Scan} from '@/library/models';

const useFetchScanData = ({scan}: {scan: Scan}) => {
	const {
		scan: {
			setAuxMeshObjects,
			setAuxPointCloudObjects,
			setLandmarks,
			setBonemaskBinaries,
		},
	} = useGlobalState();
	const [error, setError] = useState();
	const [areSegmentationFilesFetched, setAreSegmentationFilesFetched] =
		useState<boolean>(false);

	const [firebaseAuthUser, isFirebaseAuthUserLoading] = useFirebaseAuthState();

	useEffect(() => {
		// Update the landmarks state when the scan's landmarks change
		setLandmarks(
			scan.landmarks ?? {
				primary: [],
				secondary: [],
			},
		);
	}, [scan.landmarks, setLandmarks]);

	useEffect(() => {
		const fetchData = async () => {
			if (!scan.segmentation) {
				throw new Error('Scan is segmented but no segmentation data found');
			}

			if (!firebaseAuthUser) {
				throw new Error('User is not authenticated');
			}

			const {
				id: scanId,
				segmentation: {version},
			} = scan;
			const {uid: userId} = firebaseAuthUser;

			const segmentationPath = getSegmentationPath({scanId, userId, version});

			// Wrap each fetch operation in a promise
			const fetchDrcFiles = api.config
				.get({scanId, userId, version})
				.then((json) => {
					const {meshFiles, pointCloudFiles} = (
						json as {
							drcFiles: {
								meshFiles: DrcData[];
								pointCloudFiles: DrcData[];
							};
						}
					).drcFiles;

					const auxMeshObjects = createAuxMeshObjects(
						meshFiles,
						scanId,
						userId,
						version,
					);

					const auxPointCloudObjects = createAuxPointCloudObjects(
						pointCloudFiles,
						scanId,
						userId,
						version,
					);

					setAuxMeshObjects(auxMeshObjects);
					setAuxPointCloudObjects(auxPointCloudObjects);
				});

			const fetchRawData = getRawData(segmentationPath)
				.then(setBonemaskBinaries)
				.catch((error) => {
					setError(error);
				});

			// Use Promise.all to wait for both fetches to complete
			Promise.all([fetchDrcFiles, fetchRawData])
				.then(() => {
					// Once all fetches have completed, update the state
					setAreSegmentationFilesFetched(true);
				})
				.catch((error) => {
					console.error('Error during fetches:', error);
					setError(error);
				});
		};

		if (
			areSegmentationFilesFetched ||
			isFirebaseAuthUserLoading ||
			!scan.hasReachedMilestone('kneeSegmented')
		) {
			return;
		}

		fetchData().catch(console.error);
	}, [
		areSegmentationFilesFetched,
		setAreSegmentationFilesFetched,
		firebaseAuthUser,
		isFirebaseAuthUserLoading,
		scan,
		setBonemaskBinaries,
		setAuxMeshObjects,
		setAuxPointCloudObjects,
	]);

	return {error};
};

export default useFetchScanData;
