import {CssVarsProvider} from '@mui/joy';
import React, {type ReactNode} from 'react';

import {darkTheme} from '@/themes';

export type DarkModeProps = {
	readonly children: ReactNode;
};

/**
 * Render children in dark mode
 *
 * @see https://stackoverflow.com/a/77430331/4411309
 */
export default function DarkMode({children}: DarkModeProps) {
	return <CssVarsProvider theme={darkTheme}>{children}</CssVarsProvider>;
}
