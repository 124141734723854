import {
	Snackbar as JoySnackbar,
	type SnackbarProps as JoySnackbarProps,
} from '@mui/joy';
import React from 'react';

import StatusIcon from './status-icon';

export type SnackbarProps = JoySnackbarProps & {
	readonly showIcon?: boolean;
};

/**
 * Enhanced version of [Joy UI's Snackbar]{@link https://mui.com/joy-ui/react-snackbar/}
 * with the following features:
 * - Automatically adds icon based on `color`
 */
export default function Snackbar({showIcon, ...props}: SnackbarProps) {
	if (showIcon) {
		props.startDecorator = <StatusIcon color={props.color} />;
	}

	return <JoySnackbar {...props} />;
}
