import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {useFirebaseAuthState} from '@/hooks';

/**
 * Root route redirects
 *
 * doing this in an effect instead of using
 * [`redirect`](@link https://reactrouter.com/en/main/fetch/redirect)
 * in loader because `redirect` was causing an infinite loop
 */
export default function useRedirect() {
	const [user, isUserLoading] = useFirebaseAuthState();
	const navigate = useNavigate();

	useEffect(() => {
		if (isUserLoading) {
			return;
		}

		if (window.location.pathname === '/') {
			if (user) {
				navigate('/scans');
			} else {
				navigate('/log-in');
			}
		}
	}, [navigate, user, isUserLoading]);
}
