import {Draft} from 'immer';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {
	type DigitalTwinMode,
	type DigitalTwinData,
	isDrillData,
	isReamData,
	isResectData,
} from '@/library/digital-twins';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

type State = {
	areDigitalTwinsDirty: boolean;
	digitalTwins: DigitalTwinData[];
	mode: DigitalTwinMode;
	selectedDigitalTwinId: string | undefined;
	state: 'ready' | 'loading' | 'updating' | 'error';
	outlineActor: vtkActor | undefined;
};

export type DigitalTwinsState = State;

type Actions = {
	addDigitalTwin: (digitalTwin: DigitalTwinData) => void;
	deleteDigitalTwin: (parameters: {id: string}) => void;
	deleteOutlineActor: () => void;
	getDigitalTwin: (parameters: {id: string}) => DigitalTwinData;
	getDigitalTwinIndex: (parameters: {id: string}) => number;
	getSelectedDigitalTwin: () => DigitalTwinData | undefined;
	getSelectedDigitalTwinIndex: () => number;
	selectDigitalTwin: (parameters: {id: string}) => void;
	setAreDigitalTwinsDirty: (areDigitalTwinsDirty: boolean) => void;
	setMode: (mode: DigitalTwinMode) => void;
	setOutlineActor: (actor: vtkActor) => void;
	setState: (state: State['state']) => void;
	updateDigitalTwin: (
		properties: Required<Pick<DigitalTwinData, 'id'>> &
			Partial<Omit<DigitalTwinData, 'id'>>,
	) => void;
	updateSelectedDigitalTwin: (
		properties: Partial<Omit<DigitalTwinData, 'id'>>,
	) => void;
};

export const useDigitalTwinsStore = create(
	immer<State & Actions>((set, get) => ({
		areDigitalTwinsDirty: false,
		digitalTwins: [],
		mode: 'remaining',
		outlineActor: undefined,
		selectedDigitalTwinId: undefined,
		state: 'loading',

		addDigitalTwin(digitalTwin) {
			set((state) => {
				state.digitalTwins.push(digitalTwin);
			});
		},
		deleteDigitalTwin({id}) {
			set((state) => {
				const index = state.getDigitalTwinIndex({id});

				if (index < 0) return;

				state.digitalTwins.splice(index, 1);

				if (state.digitalTwins.length === 0) {
					state.selectedDigitalTwinId = undefined;
				} else if (state.selectedDigitalTwinId === id) {
					state.selectedDigitalTwinId = state.digitalTwins[0].id;
				}
			});
		},
		deleteOutlineActor() {
			set((state) => {
				state.outlineActor = undefined;
			});
		},
		getDigitalTwin({id}): DigitalTwinData {
			const digitalTwin = get().digitalTwins.find(
				(digitalTwin) => digitalTwin.id === id,
			);

			if (!digitalTwin) {
				throw new Error(`Digital twin not found. [id=${id}]`);
			}

			return digitalTwin;
		},
		getDigitalTwinIndex({id}): number {
			return get().digitalTwins.findIndex(
				(digitalTwin) => digitalTwin.id === id,
			);
		},
		getSelectedDigitalTwin(): DigitalTwinData | undefined {
			const id = get().selectedDigitalTwinId;

			return id === undefined ? undefined : get().getDigitalTwin({id});
		},
		getSelectedDigitalTwinIndex(): number {
			const id = get().selectedDigitalTwinId;

			return id === undefined
				? -1
				: get().digitalTwins.findIndex((digitalTwin) => digitalTwin.id === id);
		},
		selectDigitalTwin({id}) {
			set((state) => {
				state.selectedDigitalTwinId = id;
			});
		},
		setAreDigitalTwinsDirty(areDigitalTwinsDirty) {
			set((state) => {
				state.areDigitalTwinsDirty = areDigitalTwinsDirty;
			});
		},
		setMode(mode) {
			set((state) => {
				state.mode = mode;
			});
		},
		setOutlineActor(actor) {
			set((state) => {
				state.outlineActor = actor;
			});
		},
		setState(newState: State['state']) {
			set((state) => {
				state.state = newState;
			});
		},
		updateDigitalTwin({id, ...properties}) {
			set((state) => {
				const index = state.getDigitalTwinIndex({id});

				if (index !== -1) {
					const currentTwin = state.digitalTwins[index];
					const updatedTwin = {
						...currentTwin,
						...properties,
					} as Draft<DigitalTwinData>;

					// Type guard to ensure type-specific properties are updated correctly
					if (isDrillData(currentTwin) && isDrillData(updatedTwin)) {
						state.digitalTwins[index] = updatedTwin;
					} else if (isReamData(currentTwin) && isReamData(updatedTwin)) {
						state.digitalTwins[index] = updatedTwin;
					} else if (isResectData(currentTwin) && isResectData(updatedTwin)) {
						state.digitalTwins[index] = updatedTwin;
					} else {
						console.error('Invalid update: mismatched types');
					}
				}
			});
		},
		updateSelectedDigitalTwin(properties) {
			const selectedDigitalTwin = get().getSelectedDigitalTwin();

			if (selectedDigitalTwin === undefined) {
				throw new Error('No digital twin selected');
			}

			get().updateDigitalTwin({
				id: selectedDigitalTwin.id,
				...properties,
			});
		},
	})),
);
