import {CssBaseline, CssVarsProvider, GlobalStyles} from '@mui/joy';
import React from 'react';

import Router from '@/router';
import theme from '@/themes';

import './app.css';

export default function App() {
	return (
		<CssVarsProvider theme={theme}>
			<CssBaseline>
				<GlobalStyles
					styles={{
						'html, body, #root': {
							height: '100%',
						},
						// https://mui.com/joy-ui/integrations/icon-libraries/#lucide
						'& .lucide': {
							color: 'var(--Icon-color)',
							margin: 'var(--Icon-margin)',
							fontSize: 'var(--Icon-fontSize, 20px)',
							width: '1em',
							height: '1em',
						},
					}}
				/>

				<Router />
			</CssBaseline>
		</CssVarsProvider>
	);
}
