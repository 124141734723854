import {
	FileIcon as LucideFileIcon,
	FolderArchiveIcon,
	type LucideProps,
} from 'lucide-react';
import React from 'react';

export type Props = LucideProps & {
	readonly file: File;
};

/**
 * Renders a [Lucide]{@link https://lucide.dev} icon based on the file type
 */
export default function FileIcon({file, ...props}: Props) {
	const Icon =
		file.type === 'application/zip' ? FolderArchiveIcon : LucideFileIcon;

	return <Icon {...props} />;
}
