import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

import {
	GumballData,
	PickResult,
	PickType,
	HandleType,
	AxisValue,
} from '@/library/gumball';

export const checkGumballHandles = (
	gumball: GumballData,
	pickedActor: vtkActor,
): PickResult | undefined => {
	const handleTypes: [keyof GumballData, HandleType][] = [
		['rotationHandles', HandleType.Rotation],
		['translationHandles', HandleType.Translation],
		['resizeHandles', HandleType.Resize],
	];

	for (const [handleKey, handleType] of handleTypes) {
		const handles = gumball[handleKey];

		// Find the axis (if any) where the pickedActor matches a handle
		const matchingAxis = Object.entries(handles).find(([, handle]) => {
			if (Array.isArray(handle)) {
				// For resize handles (which are arrays)
				return handle.some(({actor}) => actor === pickedActor);
			}
			// For rotation and translation handles (which are single objects)

			return handle.actor === pickedActor;
		});

		if (matchingAxis) {
			const [axis] = matchingAxis;
			return {
				picked: true,
				type: PickType.Gumball,
				axis: axis as AxisValue,
				handle: handleType,
				pickedActor: {actor: pickedActor, source: undefined},
				actorData: undefined,
			};
		}
	}

	return undefined;
};
