import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

type Layer =
	| 'digitalTwins'
	| 'landmarks'
	| 'segmentationOverlays'
	| 'surgicalPlan';

type State = Record<Layer, boolean> & {
	setVisibility: (layer: Layer, visible: boolean) => void;
};

export const useVisibilityStore = create(
	immer<State>((set) => ({
		digitalTwins: true,
		landmarks: true,
		segmentationOverlays: true,
		setVisibility(layer: Layer, isVisible: boolean) {
			set((state) => {
				state[layer] = isVisible;
			});
		},
		surgicalPlan: false,
	})),
);
