import {Slider, type SliderProps, Stack} from '@mui/joy';
import {assert} from '@sindresorhus/is';
import React, {type ReactNode} from 'react';

import NumberInput, {type NumberInputProps} from './number-input';

type OnChange = (value: number) => void;

export type SliderAndNumberInputProps = {
	readonly disabled?: boolean;
	readonly endDecorator?: ReactNode;
	readonly formatOptions?: NumberInputProps['formatOptions'];
	readonly label: string;
	readonly max?: number;
	readonly min?: number;
	readonly onNumberInputChange?: OnChange;
	readonly onChange?: OnChange;
	readonly onSliderChange?: OnChange;
	readonly onSliderChangeCommitted?: OnChange;
	readonly testId?: string;
	readonly track?: SliderProps['track'];
	readonly value?: number;
};

/**
 * Combined and synchronized MUI [Slider]{@link https://mui.com/joy-ui/react-slider/}
 * and [NumberInput]{@link NumberInput}
 */
export default function SliderAndNumberInput({
	disabled,
	endDecorator,
	formatOptions,
	label,
	max,
	min,
	onNumberInputChange,
	onChange,
	onSliderChange,
	onSliderChangeCommitted,
	testId,
	track,
	value,
}: SliderAndNumberInputProps) {
	const handleNumberInputChange: NumberInputProps['onChange'] = (value) => {
		onNumberInputChange?.(value);
		onChange?.(value);
	};

	const handleSliderChange: SliderProps['onChange'] = (event, value) => {
		assert.number(value);
		onSliderChange?.(value);
		onChange?.(value);
	};

	const handleSliderChangeCommitted: SliderProps['onChangeCommitted'] = (
		event,
		value,
	) => {
		assert.number(value);
		onSliderChangeCommitted?.(value);
	};

	return (
		<Stack alignItems="center" direction="row" spacing={2}>
			<Slider
				disabled={disabled}
				max={max}
				min={min}
				slotProps={{
					root: {'data-testid': `slider:${(testId ?? label).toLowerCase()}`},
				}}
				sx={{minWidth: 0}}
				track={track}
				value={value}
				onChange={handleSliderChange}
				onChangeCommitted={handleSliderChangeCommitted}
			/>

			<NumberInput
				disabled={disabled}
				endDecorator={endDecorator}
				formatOptions={formatOptions}
				label={label}
				maxValue={max}
				minValue={min}
				slotProps={{
					root: {
						'data-testid': `number-input:${(testId ?? label).toLowerCase()}`,
					},
				}}
				value={value}
				onChange={handleNumberInputChange}
			/>
		</Stack>
	);
}
