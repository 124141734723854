import {Button, Stack} from '@mui/joy';
import React, {type RefObject} from 'react';
import {useParams as useRouteParameters} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';

function SurgicalPlan({
	componentRef,
}: {
	readonly componentRef: RefObject<HTMLDivElement>;
}) {
	const {scanId} = useRouteParameters<{scanId: string}>();

	const handlePrint = useReactToPrint({
		content() {
			if (componentRef?.current) {
				return componentRef.current;
			}

			/* eslint-disable-next-line unicorn/no-null --
			 * This is required by react-to-print
			 */
			return null;
		},
		documentTitle: `Surgical Plan - ${scanId}`,
	});

	return (
		<Stack spacing={4}>
			<Button onClick={handlePrint}>Print Surgical Plan</Button>
		</Stack>
	);
}

export default SurgicalPlan;
