import {useAnnotationsStore} from './annotations';
import {useCustomDefaultsStore} from './custom-defaults';
import {useDensityMapStore} from './density-map';
import {useDigitalTwinsStore} from './digital-twins';
import {useLandmarksStore} from './landmarks';
import {useMeshesStore} from './meshes';
import {useResectionPlanesStore} from './resection-planes';
import {useScanStore} from './scan';
import {useToolsStore} from './tools';
import {useUserStore} from './user';
import {useViewportsStore} from './viewports';
import {useVisibilityStore} from './visibility';

const useStateStore = () => ({
	annotations: useAnnotationsStore(),
	customDefaults: useCustomDefaultsStore(),
	densityMap: useDensityMapStore(),
	digitalTwins: useDigitalTwinsStore(),
	landmarks: useLandmarksStore(),
	meshes: useMeshesStore(),
	resectionPlanes: useResectionPlanesStore(),
	scan: useScanStore(),
	tools: useToolsStore(),
	user: useUserStore(),
	viewports: useViewportsStore(),
	visibility: useVisibilityStore(),
});

export function useGlobalState() {
	return useStateStore();
}
