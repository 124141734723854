const apiPath = '/api';
const dracoDecoderFilePath = 'draco_decoder_1.4.1.wasm';
const dracoEncoderFilePath = 'draco_encoder_1.4.1.wasm';
const dracoWrapperFilePath = 'draco_wasm_wrapper_1.4.1.js';
const landmarksFilePath = 'landmarks.json';
const segmentationFilePath = 'bonemask_binary';

function getBaseUrl(): string {
	return window.location.protocol + '//' + window.location.host;
}

function getConfigPath({
	scanId,
	userId,
	version,
}: {
	scanId: string;
	userId: string;
	version: number;
}): string {
	return `config/${userId}/scans/${scanId}/outputs/${version}`;
}

function getDataPath({
	path,
	scanId,
	userId,
	version,
}: {
	path?: string;
	scanId: string;
	userId: string;
	version: number;
}): string {
	return `${apiPath}/data/${userId}/scans/${scanId}/outputs/${version}/${path}`;
}

function getDracoDecoderUrl(): string {
	return `${getBaseUrl()}/${dracoDecoderFilePath}`;
}

function getDracoEncoderUrl(): string {
	return `${getBaseUrl()}/${dracoEncoderFilePath}`;
}

function getDracoWrapperUrl(): string {
	return `${getBaseUrl()}/${dracoWrapperFilePath}`;
}

function getLandmarksPath({
	scanId,
	userId,
	version,
}: {
	scanId: string;
	userId: string;
	version: number;
}): string {
	return getDataPath({path: landmarksFilePath, scanId, userId, version});
}

function getSegmentationPath({
	userId,
	scanId,
	version,
}: {
	userId: string;
	scanId: string;
	version: number;
}): string {
	return getDataPath({path: segmentationFilePath, scanId, userId, version});
}

export {
	getBaseUrl,
	getConfigPath,
	getDataPath,
	getDracoDecoderUrl,
	getDracoEncoderUrl,
	getDracoWrapperUrl,
	getLandmarksPath,
	getSegmentationPath,
};
