import {Stack, Typography} from '@mui/joy';
import React from 'react';

import {useTitle} from '@/hooks';

export default function NotFound() {
	useTitle('Not Found');

	return (
		<Stack
			direction="row"
			sx={{alignItems: 'center', height: '100%', justifyContent: 'center'}}
		>
			<img alt="Vent Creativity logo" src="/logo.svg" style={{height: 64}} />
			<Typography level="title-lg">Ops page not found!</Typography>
		</Stack>
	);
}
