export const sidePanelTools = [
	'annotations',
	'densityMap',
	'digitalTwins',
	'resectionPlanes',
	'surgicalPlan',
	'visibility',
] as const;

export type SidePanelTool = (typeof sidePanelTools)[number];
