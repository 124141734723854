import {
	Dropdown,
	FormControl,
	Menu,
	MenuButton,
	MenuItem,
	Tooltip,
} from '@mui/joy';
import {BoxIcon, ChevronDownIcon} from 'lucide-react';
import React from 'react';

import {snapCameraToView} from '@/library';
import {useViewportsStore} from '@/state/viewports';
import {CameraView} from '@/types';

function SnapCameraButton() {
	const handleSnapCamera = (view: CameraView) => {
		const {bounds} = useViewportsStore.getState();

		if (!bounds) throw new Error('No bounds found');

		snapCameraToView(bounds, view);
	};

	const cameraViewLabels: Record<CameraView, string> = {
		[CameraView.AxialSuperior]: 'T/S',
		[CameraView.AxialInferior]: 'T/I',
		[CameraView.SagittalMedial]: 'S/M',
		[CameraView.SagittalLateral]: 'S/L',
		[CameraView.CoronalAnterior]: 'C/A',
		[CameraView.CoronalPosterior]: 'C/P',
		[CameraView.IsometricAnteromedial]: 'Iso Ant/M',
		[CameraView.IsometricAnterolateral]: 'Iso Ant/L',
	};

	return (
		<Tooltip color="primary" title="Snap Camera To View" placement="right">
			<FormControl>
				<Dropdown>
					<MenuButton
						id="snap-camera-button"
						aria-haspopup="true"
						variant="outlined"
						color="neutral"
						sx={{
							alignItems: 'center',
							aspectRatio: '1/1',
							display: 'flex',
							height: '2rem',
							justifyContent: 'center',
							minWidth: 'unset',
							padding: '8px',
							width: '3rem',
						}}
						onClick={(event) => event.stopPropagation()}
					>
						<BoxIcon />
						<ChevronDownIcon />
					</MenuButton>
					<Menu placement="bottom-end">
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: 'repeat(2, 1fr)',
								columnGap: '8px',
								padding: '8px',
							}}
						>
							{Object.values(CameraView).map((view) => (
								<MenuItem
									key={view}
									sx={{
										fontSize: '0.875rem',
										minHeight: '32px',
										paddingTop: '4px',
										paddingBottom: '4px',
										width: '100%',
									}}
									onClick={() => {
										handleSnapCamera(view);
									}}
								>
									{cameraViewLabels[view]}
								</MenuItem>
							))}
						</div>
					</Menu>
				</Dropdown>
			</FormControl>
		</Tooltip>
	);
}

export default SnapCameraButton;
