import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

/** Mixin */
type Applied = {
	applied: boolean;
	setApplied: (applied: boolean) => void;
};

type State = {
	densityMap: {
		colorScheme: Applied;
	};
	resectionPlanes: {
		pair: Applied;
		plane: Applied;
	};
};

export const useCustomDefaultsStore = create<State>()(
	immer((set) => ({
		densityMap: {
			colorScheme: {
				applied: false,
				setApplied(applied) {
					set((state) => {
						state.densityMap.colorScheme.applied = applied;
					});
				},
			},
		},
		resectionPlanes: {
			pair: {
				applied: false,
				setApplied(applied) {
					set((state) => {
						state.resectionPlanes.pair.applied = applied;
					});
				},
			},
			plane: {
				applied: false,
				setApplied(applied) {
					set((state) => {
						state.resectionPlanes.plane.applied = applied;
					});
				},
			},
		},
	})),
);
