import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';

import {type Landmark} from '@/library/landmarks';
import {useLandmarksStore} from '@/state/landmarks';
import {getViewports} from '@/state/viewports';

const sphereRadius = 2;
const sphereColor = [1, 1, 1];
const opacity = 0.75;

export function createLandmarks(landmarks: Landmark[]): void {
	const {addLandmark} = useLandmarksStore.getState();
	const {volume: volumeViewport} = getViewports();

	for (const landmark of landmarks) {
		const sphereSource = vtkSphereSource.newInstance({
			radius: sphereRadius,
			thetaResolution: 16,
			phiResolution: 16,
			center: landmark.center,
		});
		const actor = vtkActor.newInstance();
		const mapper = vtkMapper.newInstance();

		actor.getProperty().setEdgeVisibility(false);
		actor.getProperty().setOpacity(opacity);
		actor
			.getProperty()
			.setColor(sphereColor[0], sphereColor[1], sphereColor[2]);

		mapper.setInputConnection(sphereSource.getOutputPort());
		actor.setMapper(mapper);

		addLandmark({...landmark, actor});
		volumeViewport.addActor(actor);
	}

	volumeViewport.render();
}
