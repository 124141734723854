import {ProbeTool} from '@cornerstonejs/tools';
import {type IToolGroup} from '@cornerstonejs/tools/dist/cjs/types';

import * as toolGroups from './tool-groups';
import renderViewports from './render-viewports';

export function setVisibility(visibility: boolean): void {
	const toolGroup = toolGroups.ctViewport.get();

	if (!toolGroup) {
		throw new Error('Failed to get CT viewport tool group');
	}

	const method: keyof IToolGroup = visibility
		? 'setToolEnabled'
		: 'setToolDisabled';

	toolGroup[method](ProbeTool.toolName);

	renderViewports('2d');
}
