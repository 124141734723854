import {
	AngleTool,
	ArrowAnnotateTool,
	BidirectionalTool,
	CobbAngleTool,
	EllipticalROITool,
	Enums as CornerstoneEnums,
	LengthTool,
	RectangleROITool,
	ToolGroupManager,
	WindowLevelTool,
} from '@cornerstonejs/tools';
import {type IToolGroup} from '@cornerstonejs/tools/dist/cjs/types';

import {type AnnotationTool} from '@/library';

type ToolGroup = {
	id: string;
	create: () => IToolGroup | undefined;
	get: () => IToolGroup | undefined;
};

const ctViewport: ToolGroup = {
	id: 'ct',
	create() {
		return ToolGroupManager.createToolGroup(this.id);
	},
	get() {
		return ToolGroupManager.getToolGroup(this.id);
	},
};

const threeDimensionalViewport: ToolGroup = {
	id: '3d',
	create() {
		return ToolGroupManager.createToolGroup(this.id);
	},
	get() {
		return ToolGroupManager.getToolGroup(this.id);
	},
};

/**
 * Disable all tools in `toolGroup` except those named in `except`
 */
function setAllToolsDisabled({
	except = [],
	toolGroup,
}: {
	except?: string[];
	toolGroup: IToolGroup;
}) {
	for (const [name, tool] of Object.entries(toolGroup._toolInstances)) {
		if (!except.includes(name) && tool.mode === 'Active') {
			toolGroup.setToolDisabled(name);
		}
	}
}

function makeAnnotationsPassive() {
	const toolGroup = ctViewport.get();
	if (!toolGroup) return;
	//
	// Disable without hiding annotations made from other tools in viewports
	toolGroup.setToolPassive(LengthTool.toolName);
	toolGroup.setToolPassive(ArrowAnnotateTool.toolName);
	toolGroup.setToolPassive(AngleTool.toolName);
	toolGroup.setToolPassive(BidirectionalTool.toolName);
	toolGroup.setToolPassive(CobbAngleTool.toolName);
	toolGroup.setToolPassive(EllipticalROITool.toolName);
	toolGroup.setToolPassive(RectangleROITool.toolName);
}

function setAnnotationsEnabled(currentTool: AnnotationTool) {
	const toolGroup = ctViewport.get();
	if (!toolGroup) return;

	makeAnnotationsPassive();

	switch (currentTool) {
		case 'Length': {
			toolGroup.setToolActive(LengthTool.toolName, {
				bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
			});
			break;
		}

		case 'ArrowAnnotate': {
			toolGroup.setToolActive(ArrowAnnotateTool.toolName, {
				bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
			});
			break;
		}

		case 'Angle': {
			toolGroup.setToolActive(AngleTool.toolName, {
				bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
			});
			break;
		}

		case 'Bidirectional': {
			toolGroup.setToolActive(BidirectionalTool.toolName, {
				bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
			});
			break;
		}

		case 'CobbAngle': {
			toolGroup.setToolActive(CobbAngleTool.toolName, {
				bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
			});
			break;
		}

		case 'EllipticalROI': {
			toolGroup.setToolActive(EllipticalROITool.toolName, {
				bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
			});
			break;
		}

		case 'RectangleROI': {
			toolGroup.setToolActive(RectangleROITool.toolName, {
				bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
			});
			break;
		}

		default: {
			break;
		}
	}
}

function setAnnotationsDisabled() {
	const toolGroup = ctViewport.get();
	if (!toolGroup) return;

	makeAnnotationsPassive();

	toolGroup.setToolActive(WindowLevelTool.toolName, {
		bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
	});
}

export {
	ctViewport,
	setAllToolsDisabled,
	setAnnotationsDisabled,
	setAnnotationsEnabled,
	threeDimensionalViewport,
};
