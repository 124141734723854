import {Container, Stack} from '@mui/joy';
import React from 'react';
import {Outlet, useRouteError} from 'react-router-dom';

import {Alert, AppBar} from '@/components';
import {useLogReactRouterRouteError} from '@/hooks';

import {useRedirect} from './hooks';

export default function Root() {
	const error = useRouteError();

	useLogReactRouterRouteError();
	useRedirect();

	return (
		<Stack sx={{height: '100vh'}}>
			<AppBar />

			<Container sx={{flexGrow: 1, paddingY: 2}}>
				{error ? (
					<Alert showContactButton showIcon color="danger">
						An error occurred
					</Alert>
				) : (
					<Outlet />
				)}
			</Container>
		</Stack>
	);
}
