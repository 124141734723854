import {useCallback, useEffect} from 'react';

import {cornerstone} from '@/library';
import {useGlobalState} from '@/state';

export default function useAnnotations() {
	const {
		annotations: {currentTool},
	} = useGlobalState();

	const disableAnnotations = useCallback(() => {
		cornerstone.toolGroups.setAnnotationsDisabled();
	}, []);

	const enableAnnotations = useCallback(() => {
		cornerstone.toolGroups.setAnnotationsEnabled(currentTool);
	}, [currentTool]);

	useEffect(() => {
		enableAnnotations();
	}, [currentTool, enableAnnotations]);

	return {enableAnnotations, disableAnnotations};
}
