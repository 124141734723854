import {ListItem} from '@mui/joy';
import React from 'react';

import {Loading} from '@/components';

export default function LoadingListItem() {
	return (
		<ListItem>
			<Loading label="Loading" size="sm" />
		</ListItem>
	);
}
