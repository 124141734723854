import delay from 'delay';

import {updateDigitalTwinsVisibility} from '@/library';
import {getViewports} from '@/state/viewports';
import {useGlobalState} from '@/state';
import {type CheckboxChangeHandler} from '@/types';

export default function useDigitalTwinsVisibility(): {
	disabled: boolean;
	exist: boolean;
	handleVisibilityChange: CheckboxChangeHandler;
	visibility: boolean;
} {
	const {digitalTwins, visibility: globalVisibility} = useGlobalState();

	const handleVisibilityChange: CheckboxChangeHandler = async ({
		target: {checked: visibility},
	}) => {
		digitalTwins.setState('updating');

		await delay(0);

		updateDigitalTwinsVisibility({
			isVisible: globalVisibility.digitalTwins,
		});

		const {volume: volumeViewport} = getViewports();
		volumeViewport?.render();

		digitalTwins.setState('ready');
		globalVisibility.setVisibility('digitalTwins', visibility);
	};

	return {
		visibility: globalVisibility.digitalTwins,
		exist: digitalTwins.digitalTwins.length > 0,
		disabled: digitalTwins.state !== 'ready',
		handleVisibilityChange,
	};
}
