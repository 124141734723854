import * as cornerstone from '@cornerstonejs/core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';

export default function initializeCornerstoneWadoImageLoader(): void {
	cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
	cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
	cornerstoneWADOImageLoader.configure({
		useWebWorkers: true,
		decodeConfig: {
			convertFloatPixelDataToInt: false,
		},
	});

	let maxWebWorkers = 1;

	if (navigator.hardwareConcurrency) {
		maxWebWorkers = Math.min(navigator.hardwareConcurrency, 7);
	}

	const config = {
		maxWebWorkers,
		startWebWorkersOnDemand: true,
		taskConfiguration: {
			decodeTask: {
				initializeCodecsOnStartup: false,
				strict: false,
			},
		},
	};

	cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
}
