import {BrushTool} from '@cornerstonejs/tools';
import {ToolProps} from '@cornerstonejs/tools/dist/cjs/types';

import {eraseCircle} from './strategies/erase-circle';

export default class BrushEraseTool extends BrushTool {
	static toolName = 'BrushEraseTool';
	constructor(toolProps: ToolProps = {}, defaultToolProps: ToolProps = {}) {
		defaultToolProps.supportedInteractionTypes ??= ['Mouse', 'Touch'];
		defaultToolProps.configuration ??= {};
		defaultToolProps.configuration.strategies ??= {};
		defaultToolProps.configuration.strategies.ERASE_INSIDE ??= eraseCircle;
		defaultToolProps.configuration.defaultStrategy ??= 'ERASE_INSIDE';
		defaultToolProps.configuration.activeStrategy ??= 'ERASE_INSIDE';
		defaultToolProps.configuration.brushSize ??= 5;

		super(toolProps, defaultToolProps);
	}
}
