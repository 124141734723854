import {
	AlertOctagonIcon,
	AlertTriangleIcon,
	CheckCircleIcon,
	InfoIcon,
	Loader2Icon as LoaderIcon,
	type LucideIcon,
	type LucideProps,
} from 'lucide-react';
import React from 'react';

export type Props = LucideProps & {
	readonly loading?: boolean;
};

/**
 * Renders a [Lucide]{@link https://lucide.dev} icon based on the
 * `color` prop
 */
export default function StatusIcon({color, loading, ...props}: Props) {
	let Icon: LucideIcon;

	if (loading) {
		Icon = LoaderIcon;
	} else {
		switch (color) {
			case 'danger': {
				Icon = AlertOctagonIcon;
				break;
			}

			case 'info': {
				Icon = InfoIcon;
				break;
			}

			case 'success': {
				Icon = CheckCircleIcon;
				break;
			}

			case 'warning': {
				Icon = AlertTriangleIcon;
				break;
			}

			default: {
				return;
			}
		}
	}

	return <Icon className={loading ? 'animate-spin' : undefined} {...props} />;
}
