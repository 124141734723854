import {type Axis, type Vector3} from '../types/3d';

export const axesDirection: Record<Axis, Vector3> = {
	x: [1, 0, 0],
	y: [0, 1, 0],
	z: [0, 0, 1],
};

export const resectionPlanePosition = {
	// Relative position to its original
	min: -15,
	default: 0,
	max: 15,
};

export const resectionPlaneRotation = {
	// Relative rotation to its original
	min: -15,
	default: 0,
	max: 15,
};
