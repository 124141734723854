import delay from 'delay';

import {isDefined, setPointCloudThreshold} from '@/library';
import {useGlobalState} from '@/state';

export default function useThreshold() {
	const {densityMap} = useGlobalState();

	async function applyThreshold({
		lower,
		upper,
	}: {
		lower: number;
		upper: number;
	}) {
		if (!densityMap.threshold) return;

		densityMap.setState('updating');

		await delay(1); // Force render

		setPointCloudThreshold({
			colorScheme: densityMap.colorScheme,
			lower,
			lut: densityMap.threshold.lut,
			upper,
		});

		densityMap.setState('ready');
	}

	async function handleChange({
		committed,
		lower,
		upper,
	}: {
		committed?: boolean;
		lower?: number;
		upper?: number;
	}) {
		if (
			!isDefined(densityMap.threshold) ||
			[lower, upper].some((number) => Number.isNaN(number))
		) {
			return;
		}

		lower ??= densityMap.threshold.lower;
		upper ??= densityMap.threshold.upper;

		densityMap.updateThreshold({lower, upper});

		if (committed) await applyThreshold({lower, upper});
	}

	return {
		handleChange,
		value: densityMap.threshold,
	};
}
