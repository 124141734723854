import {signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword} from 'firebase/auth';

import {authInstance} from './firebase';

/**
 * Wait for auth to initialize, then return the current user
 */
export async function getCurrentUser() {
	await authInstance.authStateReady();

	return authInstance.currentUser;
}

export async function getIdToken() {
	const currentUser = await getCurrentUser();

	return currentUser?.getIdToken();
}

export async function signInWithEmailAndPassword(
	email: string,
	password: string,
) {
	return firebaseSignInWithEmailAndPassword(authInstance, email, password);
}
