import {type ColorPaletteProp} from '@mui/joy';
import {
	type FirestoreDataConverter,
	Timestamp,
	serverTimestamp,
} from 'firebase/firestore';

import {
	DigitalTwinData,
	SanitizedDigitalTwinData,
} from '@/library/digital-twins';

/* eslint-disable-next-line import/no-cycle --
 * TODO: Break this circular dependency
 */
import {updateScan} from '@/library/firebase/firestore';

import {Annotation, Annotations} from '../annotations';
import removeUndefinedProperties from '../remove-undefined-properties';

import {
	resectionPlanePosition,
	resectionPlaneRotation,
} from '../../constants/resection-planes';
import {type Landmarks} from '../../library/landmarks';
import {
	type ResectionPlanes,
	type ResectionPlaneState,
	type ResectionPlanesState,
} from '../../types/resection-planes';

/**
 * @see {@link https://github.com/VentCreativity/documentation/blob/main/scan.md#scan-state-diagram}
 */
export type Milestone =
	| 'prepared'
	| 'kneeSegmented'
	| 'segmented'
	| 'landmarked'
	| 'approved';

/**
 * @see {@link https://github.com/VentCreativity/documentation/blob/main/scan.md#scan-state-diagram}
 */
export type State =
	| 'created'
	| 'inputsUploaded'
	| 'ingestDicomDataInProgress'
	| 'ingestDicomDataFailed'
	| 'ingestDicomDataSucceeded'
	| 'segmentKneeInProgress'
	| 'segmentKneeFailed'
	| 'segmentKneeSucceeded'
	| 'segmentAnkleHipInProgress'
	| 'segmentAnkleHipFailed'
	| 'segmentAnkleHipSucceeded'
	| 'landmarkInProgress'
	| 'landmarkFailed'
	| 'landmarkSucceeded'
	| 'landmarkApproved';

export type ScanSegmentation = {
	state:
		| 'outputsSaved'
		| 'uploadingPointClouds'
		| 'savingOutputs'
		| 'savingOutputsFailed';
	version: number;
};

const resectionPlaneState: ResectionPlaneState = {
	position: {
		x: resectionPlanePosition.default,
		y: resectionPlanePosition.default,
		z: resectionPlanePosition.default,
	},
	rotation: {
		x: resectionPlaneRotation.default,
		y: resectionPlaneRotation.default,
		z: resectionPlaneRotation.default,
	},
};

export const initialResectionPlanesAdjustments: ResectionPlanesState = {
	anatomical: {
		femoral: structuredClone(resectionPlaneState),
		tibial: structuredClone(resectionPlaneState),
	},
	mechanical: {
		femoral: structuredClone(resectionPlaneState),
		tibial: structuredClone(resectionPlaneState),
	},
	kinematic: {
		femoral: structuredClone(resectionPlaneState),
		tibial: structuredClone(resectionPlaneState),
	},
	createdAt: new Date(),
};

export type UpdatableScanProperties = Omit<Partial<Scan>, 'id'> & {
	createdAt?: Date;
	updatedAt?: Date;
};

export default class Scan {
	static canBeSegmented(state: State): boolean {
		const canBeSegmentedStates: State[] = [
			'segmentAnkleHipFailed',
			// Todo: add segmentAnkleHipSucceeded here when we support re-segmenting
			//       after segmentAnkleHipSucceeded
		];

		return canBeSegmentedStates.includes(state);
	}

	static didLandmarkingFail(state: State): boolean {
		return state === 'landmarkFailed';
	}

	static didPreparationFail(state: State): boolean {
		return state === 'ingestDicomDataFailed';
	}

	static didSegmentationFail(state: State): boolean {
		const segmentFailedStates: State[] = [
			'segmentKneeFailed',
			'segmentAnkleHipFailed',
		];

		return segmentFailedStates.includes(state);
	}

	static hasReachedMilestone({
		milestone,
		state,
	}: {
		milestone: Milestone;
		state: State;
	}): boolean {
		const approvedStates: State[] = ['landmarkApproved'];
		const landmarkedStates: State[] = [...approvedStates, 'landmarkSucceeded'];
		const segmentedStates: State[] = [
			...landmarkedStates,
			'landmarkFailed',
			'landmarkInProgress',
			'segmentAnkleHipSucceeded',
		];
		const kneeSegmentedStates: State[] = [
			...segmentedStates,
			'segmentAnkleHipFailed',
			'segmentAnkleHipInProgress',
			'segmentKneeSucceeded',
		];
		const preparedStates: State[] = [
			...kneeSegmentedStates,
			'segmentKneeFailed',
			'segmentKneeInProgress',
			'ingestDicomDataSucceeded',
		];

		/**
		 * Map of milestones to states that the scan can be in to qualify as having
		 * reached the milestone
		 */
		const map = new Map<Milestone, State[]>([
			['prepared', preparedStates],
			['kneeSegmented', kneeSegmentedStates],
			['segmented', segmentedStates],
			['landmarked', landmarkedStates],
			['approved', approvedStates],
		]);

		if (!map.has(milestone)) {
			throw new Error(`Unexpected milestone '${milestone}'`);
		}

		return map.get(milestone)!.includes(state);
	}

	static isLandmarking(state: State): boolean {
		return state === 'landmarkInProgress';
	}

	static isPreparationPending(state: State): boolean {
		return ['created', 'inputsUploaded'].includes(state);
	}

	static isPreparing(state: State): boolean {
		return state === 'ingestDicomDataInProgress';
	}

	static isSegmentationUpdating(segmentation?: ScanSegmentation): boolean {
		return segmentation?.state
			? ['uploadingPointClouds', 'savingOutputs'].includes(segmentation.state)
			: false;
	}

	static isSegmenting(state: State): boolean {
		const segmentingStates: State[] = [
			// Treating prepared as 'segmenting' because it should flow straight
			// into segmenting
			'ingestDicomDataSucceeded',

			'segmentKneeInProgress',
			'segmentKneeSucceeded',
			'segmentAnkleHipInProgress',
		];

		return segmentingStates.includes(state);
	}

	public annotations: Annotations;

	public digitalTwins: SanitizedDigitalTwinData[] = [];

	public id: string;

	public landmarks: Landmarks | undefined;

	public resectionPlanes: ResectionPlanes | undefined;

	public resectionPlaneAdjustments: ResectionPlanesState[];

	/** Map of Firebase Authentication user UID to a role, e.g. 'owner' */
	public roles: Record<string, string>;

	public segmentation: ScanSegmentation | undefined;

	public seriesInstanceUid: string;

	public state: State;

	public studyInstanceUid: string;

	public createdAt: Date;

	public updatedAt: Date;

	constructor({
		annotations,
		digitalTwins,
		id,
		landmarks,
		resectionPlanes,
		resectionPlaneAdjustments,
		roles,
		segmentation,
		seriesInstanceUid,
		state,
		studyInstanceUid,
		createdAt,
		updatedAt,
	}: {
		annotations?: Annotations;
		digitalTwins?: SanitizedDigitalTwinData[];
		id?: string;
		landmarks?: Landmarks;
		state?: State;
		resectionPlanes?: ResectionPlanes;
		resectionPlaneAdjustments?: ResectionPlanesState[];
		roles: Record<string, string>;
		segmentation?: ScanSegmentation;
		studyInstanceUid?: string;
		seriesInstanceUid?: string;
		createdAt?: Date;
		updatedAt?: Date;
	}) {
		this.annotations = annotations ?? {annotations: [], notes: ''};
		this.digitalTwins = digitalTwins ?? [];
		this.id = id ?? '';
		this.landmarks = landmarks ?? undefined;
		this.resectionPlanes = resectionPlanes ?? undefined;
		this.roles = roles;
		this.segmentation = segmentation ?? undefined;
		this.seriesInstanceUid = seriesInstanceUid ?? '';
		this.state = state ?? 'created';
		this.studyInstanceUid = studyInstanceUid ?? '';
		this.createdAt = createdAt ?? new Date();
		this.updatedAt = updatedAt ?? new Date();
		this.resectionPlaneAdjustments = resectionPlaneAdjustments ?? [];
	}

	async addResectionPlaneAdjustment(adjustment: ResectionPlanesState) {
		const resectionPlanesAdjustmentWithTimestamp = structuredClone(adjustment);
		resectionPlanesAdjustmentWithTimestamp.createdAt = new Date();

		this.resectionPlaneAdjustments?.push(
			resectionPlanesAdjustmentWithTimestamp,
		);

		return updateScan({
			id: this.id,
			resectionPlaneAdjustments: this.resectionPlaneAdjustments,
		});
	}

	async saveAnnotations(annotations: Annotations) {
		this.annotations = annotations;

		return updateScan({
			id: this.id,
			annotations: {
				notes: this.annotations.notes,
				annotations: this.annotations.annotations.map(
					(annotation) => removeUndefinedProperties(annotation) as Annotation,
				),
			},
		});
	}

	async saveDigitalTwins(digitalTwins: DigitalTwinData[]) {
		const sanitizedDigitalTwins: SanitizedDigitalTwinData[] = digitalTwins.map(
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			({actor, source, matrix, quaternion, ...rest}) => ({
				...rest,
				matrix: matrix ? [...matrix] : [],
				quaternion: quaternion ? [...quaternion] : [],
			}),
		);

		this.digitalTwins = sanitizedDigitalTwins;

		return updateScan({
			id: this.id,
			digitalTwins: this.digitalTwins,
		});
	}

	get canBeSegmented(): boolean {
		return Scan.canBeSegmented(this.state);
	}

	get didLandmarkingFail(): boolean {
		return Scan.didLandmarkingFail(this.state);
	}

	get didPreparationFail(): boolean {
		return Scan.didPreparationFail(this.state);
	}

	get didSegmentationFail(): boolean {
		return Scan.didSegmentationFail(this.state);
	}

	hasReachedMilestone(milestone: Milestone): boolean {
		return Scan.hasReachedMilestone({milestone, state: this.state});
	}

	get humanReadableState(): {
		color: ColorPaletteProp;
		loading: boolean;
		text: string;
	} {
		let color: ColorPaletteProp;
		let loading = false;
		let text: string;

		if (this.isPreparationPending) {
			color = 'info';
			text = 'waiting for preparation';
		} else if (this.isPreparing) {
			color = 'info';
			loading = true;
			text = 'preparing';
		} else if (this.didPreparationFail) {
			color = 'danger';
			text = 'preparation failed';
		} else if (this.isSegmenting) {
			color = 'info';
			loading = true;
			text = 'segmenting';
		} else if (this.didSegmentationFail) {
			color = 'danger';
			text = 'segmentation failed';
		} else if (this.state === 'segmentAnkleHipSucceeded') {
			color = 'success';
			text = 'segmented';
		} else if (this.isLandmarking) {
			color = 'info';
			loading = true;
			text = 'landmarking';
		} else if (this.didLandmarkingFail) {
			color = 'success';
			color = 'danger';
			text = 'landmarking failed';
		} else if (this.state === 'landmarkSucceeded') {
			color = 'success';
			text = 'landmarked';
		} else if (this.state === 'landmarkApproved') {
			color = 'success';
			text = 'surgical plan approved';
		} else {
			color = 'warning';
			text = 'unknown state';
		}

		return {color, loading, text};
	}

	get isLandmarking(): boolean {
		return Scan.isLandmarking(this.state);
	}

	get isPreparationPending(): boolean {
		return Scan.isPreparationPending(this.state);
	}

	get isPreparing(): boolean {
		return Scan.isPreparing(this.state);
	}

	get isSegmentationUpdating(): boolean {
		return Scan.isSegmentationUpdating(this.segmentation);
	}

	get isSegmenting(): boolean {
		return Scan.isSegmenting(this.state);
	}

	get latestResectionPlanesAdjustment(): ResectionPlanesState | undefined {
		return this.resectionPlaneAdjustments.at(-1);
	}

	async update({...parameters}: Omit<Parameters<typeof updateScan>[0], 'id'>) {
		return updateScan({
			id: this.id,
			...parameters,
		});
	}
}

export const converter: FirestoreDataConverter<Scan> = {
	fromFirestore(snapshot, options): Scan {
		const {
			annotations,
			createdAt,
			digitalTwins,
			landmarks,
			resectionPlanes,
			resectionPlaneAdjustments,
			roles,
			segmentation,
			seriesInstanceUid,
			state,
			studyInstanceUid,
			updatedAt,
		} = snapshot.data(options);
		// TODO: Create Zod schema and parse document data returned by
		//       `snapshot.data()` because its type is unknown
		//       See User model for example

		return new Scan({
			annotations,
			createdAt: createdAt
				? createdAt.toDate()
				: new Date('1970-01-01T00:00:00'),
			digitalTwins,
			id: snapshot.id,
			landmarks,
			resectionPlanes,
			resectionPlaneAdjustments,
			roles,
			segmentation,
			seriesInstanceUid,
			state,
			studyInstanceUid,
			updatedAt: updatedAt
				? updatedAt.toDate()
				: new Date('1970-01-01T00:00:00'),
		});
	},
	toFirestore({
		annotations,
		createdAt,
		digitalTwins,
		landmarks,
		resectionPlanes,
		resectionPlaneAdjustments,
		roles,
		segmentation,
		seriesInstanceUid,
		state,
		studyInstanceUid,
		updatedAt,
	}) {
		/* eslint-disable unicorn/no-null -- Firestore only supports `null` */
		return {
			annotations,
			createdAt: createdAt
				? Timestamp.fromDate(createdAt as Date)
				: serverTimestamp(),
			digitalTwins: digitalTwins ?? null,
			landmarks: landmarks ?? null,
			resectionPlanes: resectionPlanes ?? null,
			resectionPlaneAdjustments,
			roles,
			segmentation: segmentation ?? null,
			seriesInstanceUid,
			state,
			studyInstanceUid,
			updatedAt: Timestamp.fromDate(updatedAt as Date),
		};
		/* eslint-enable */
	},
};
