import {createActor, setup} from 'xstate';

/**
 * Custom Default Settings state machine
 *
 * To visualize this state machine, copy and paste this file into
 * https://stately.ai/editor
 */
const machine = setup({
	types: {
		events: {} as
			| {type: 'activate'}
			| {type: 'cancel'}
			| {type: 'continue'}
			| {type: 'deactivate'}
			| {type: 'fail'}
			| {type: 'save'}
			| {type: 'succeed'},
	},
}).createMachine({
	initial: 'inactive',
	states: {
		inactive: {
			on: {
				activate: {
					target: 'instructing',
				},
			},
		},
		instructing: {
			on: {
				cancel: {
					target: 'inactive',
				},
				continue: {
					target: 'active',
				},
			},
		},
		active: {
			on: {
				save: {
					target: 'saving',
				},
				deactivate: {
					target: 'inactive',
				},
			},
		},
		saving: {
			on: {
				succeed: {
					target: 'save succeeded',
				},
				fail: {
					target: 'save failed',
				},
			},
		},
		'save succeeded': {
			on: {
				continue: {
					target: 'active',
				},
				deactivate: {
					target: 'inactive',
				},
			},
		},
		'save failed': {
			on: {
				deactivate: {
					target: 'inactive',
				},
				save: {
					target: 'saving',
				},
			},
		},
	},
});

export const customDefaultsXstateActor = createActor(machine);

customDefaultsXstateActor.start();
