import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

import {type SidePanelTool} from '@/library/side-panel-tools';

type State = {
	active: SidePanelTool | undefined;
	setActive: (tool: SidePanelTool | undefined) => void;
};

export const useToolsStore = create<State>()(
	immer((set) => ({
		active: undefined,
		setActive(tool) {
			set((state) => {
				state.active = tool;
			});
		},
	})),
);
