import {
	Checkbox as JoyCheckbox,
	type CheckboxProps as JoyCheckboxProps,
} from '@mui/joy';
import type Color from 'colorjs.io';
import React from 'react';

import {getCheckboxColors} from '@/library/colors';
import {isDefined} from '@/library';

export type CheckboxProps = JoyCheckboxProps & {
	readonly customColor?: Color;
};

/**
 * Joy UI [Checkbox]{@link https://mui.com/joy-ui/react-checkbox/} with
 * additional features:
 * - `Color` prop accepts a custom Color.js `Color` instance and will render the
 *   checkbox with accessible foreground and background colors based on the
 *   custom color
 */
export default function Checkbox({customColor, ...props}: CheckboxProps) {
	if (isDefined(customColor)) {
		const checkboxColors = getCheckboxColors(customColor);

		props.slotProps = {
			...props.slotProps,
			checkbox: {
				...props.slotProps?.checkbox,
				sx: {
					'&.Mui-checked': {
						backgroundColor: checkboxColors.background.normal,
						color: checkboxColors.foreground,
						'&:hover': {
							backgroundColor: checkboxColors.background.hover,
						},
						'&:active': {
							backgroundColor: checkboxColors.background.active,
						},
					},
				},
			},
		};
	}

	return <JoyCheckbox {...props} />;
}
