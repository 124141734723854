import {get as getRenderingEngine} from './rendering-engine';
import viewportIds from './viewport-ids';

import {useViewportsStore} from '@/state/viewports';

type Viewport = keyof typeof viewportIds;

export default function renderViewports(viewports: Viewport | '2d' | '3d') {
	const {volumeViewport} = useViewportsStore.getState();
	const viewportsToRender: Viewport[] = [];

	if (viewports === '2d') {
		viewportsToRender.push('axial', 'coronal', 'sagittal');
	} else if (viewports === '3d') {
		viewportsToRender.push('volume');
	} else {
		viewportsToRender.push(viewports);
	}

	const renderingEngine = getRenderingEngine();

	for (const viewport of viewportsToRender) {
		if (viewport === 'volume') {
			if (volumeViewport) {
				volumeViewport.render();
			}
		} else {
			renderingEngine.getViewport(viewportIds[viewport]).render();
		}
	}
}
