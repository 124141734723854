import {
	ArrowDownLeftIcon,
	CameraIcon,
	ChevronUpIcon,
	CircleIcon,
	PlusIcon,
	RectangleHorizontalIcon,
	RulerIcon,
	TriangleRightIcon,
	LucideIcon,
} from 'lucide-react';

import {AnnotationTool} from '@/library';

import makeIcon from './make-icon';

export type {Icon} from './make-icon';

/* eslint-disable @typescript-eslint/ban-ts-comment */
// SVG files get converted to React components with SVGR + Webpack
// @ts-expect-error
import DensitySvg from './svgs/density.svg';
// @ts-expect-error
import DigitalTwinSvg from './svgs/digital-twin.svg';
// @ts-expect-error
import ResectionRemainingSvg from './svgs/resection-remaining.svg?react';
// @ts-expect-error
import ResectionRemovedSvg from './svgs/resection-removed.svg';
// @ts-expect-error
import SphereSvg from './svgs/sphere.svg';
/* eslint-enable */

// Custom icons
export const DensityIcon = makeIcon({
	name: 'density',
	Svg: DensitySvg,
});
export const DigitalTwinIcon = makeIcon({
	name: 'digital-twin',
	Svg: DigitalTwinSvg,
});
export const ResectionRemainingIcon = makeIcon({
	name: 'resection-remaining',
	Svg: ResectionRemainingSvg,
});
export const ResectionRemovedIcon = makeIcon({
	name: 'resection-removed',
	Svg: ResectionRemovedSvg,
});
export const SphereIcon = makeIcon({
	name: 'sphere',
	Svg: SphereSvg,
});

// Lucide icon aliases
export {
	RulerIcon as AnnotationsIcon,
	Layers2Icon as ResectionPlanesIcon,
	EyeIcon as VisibilityIcon,
} from 'lucide-react';

export const annotationIcons: Record<AnnotationTool, LucideIcon> = {
	Angle: ChevronUpIcon,
	ArrowAnnotate: ArrowDownLeftIcon,
	Bidirectional: PlusIcon,
	CobbAngle: TriangleRightIcon,
	EllipticalROI: CircleIcon,
	Length: RulerIcon,
	RectangleROI: RectangleHorizontalIcon,
	Screenshot: CameraIcon,
};
