import {getRenderingEngine, RenderingEngine} from '@cornerstonejs/core';

const id = 'renderingEngineId';

export function create() {
	return new RenderingEngine(id);
}

export function get() {
	const renderingEngine = getRenderingEngine(id);

	if (!renderingEngine) throw new Error('Failed to get rendering engine');

	return renderingEngine;
}
