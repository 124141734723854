import {mat4, quat, vec3} from 'gl-matrix';

import {rotateGumball} from '@/library/vtk/gumball';
import {type AxisValue, type GumballData} from '@/library/gumball';
import {
	type DigitalTwinData,
	type ScreenPosition,
	type Vector3D,
} from '@/library/digital-twins';
import {getViewports} from '@/state/viewports';

import {
	getAxisVector,
	getScreenPositionRay,
	rayPlaneIntersection,
	setTransformationMatrix,
} from '../math';

export function handleRotate({
	currentPosition,
	gumball,
	lastPosition,
	pickedAxis,
	selectedActorData,
}: {
	currentPosition: ScreenPosition;
	lastPosition: ScreenPosition;
	pickedAxis: AxisValue;
	selectedActorData: DigitalTwinData;
	gumball: GumballData;
}) {
	const {
		volume: {renderWindow, renderer, camera},
	} = getViewports();
	if (!camera) {
		throw new Error('Camera not found');
	}

	if (!renderer) {
		throw new Error('Renderer not found');
	}

	if (!renderWindow) {
		throw new Error('Render window not found');
	}

	const planeNormal: Vector3D = getAxisVector(pickedAxis, selectedActorData);
	const actorCenter: Vector3D = selectedActorData.position;

	const currentRay: Vector3D = getScreenPositionRay(
		currentPosition.x,
		currentPosition.y,
		renderer,
	);

	const currentPlane: Vector3D = rayPlaneIntersection(
		camera.getPosition(),
		currentRay,
		actorCenter,
		planeNormal,
	);
	const currentInPlane: vec3 = vec3.subtract(
		vec3.create(),
		[currentPlane.x, currentPlane.y, currentPlane.z],
		[actorCenter.x, actorCenter.y, actorCenter.z],
	);
	const initialRay: Vector3D = getScreenPositionRay(
		lastPosition.x,
		lastPosition.y,
		renderer,
	);
	const initialPlaneIntersection: Vector3D = rayPlaneIntersection(
		camera.getPosition(),
		initialRay,
		actorCenter,
		planeNormal,
	);
	const initialInPlane: vec3 = vec3.subtract(
		vec3.create(),
		[
			initialPlaneIntersection.x,
			initialPlaneIntersection.y,
			initialPlaneIntersection.z,
		],
		[actorCenter.x, actorCenter.y, actorCenter.z],
	);
	const current: vec3 = vec3.normalize(vec3.create(), currentInPlane);
	const initial: vec3 = vec3.normalize(vec3.create(), initialInPlane);

	// Calculate rotation from reference to current
	const dotProduct: number = vec3.dot(initial, current);

	const angle: number = Math.acos(Math.min(Math.max(dotProduct, -1), 1));
	const cross: vec3 = vec3.cross(vec3.create(), initial, current);
	const direction: number =
		vec3.dot(cross, [planeNormal.x, planeNormal.y, planeNormal.z]) > 0 ? 1 : -1;

	// Apply this delta rotation to our last rotation
	const initialQuaternion: quat = selectedActorData.quaternion;
	const finalQuaternion: quat = (() => {
		switch (pickedAxis) {
			case 'x': {
				return quat.rotateX(
					quat.create(),
					initialQuaternion,
					direction * angle,
				);
			}

			case 'y': {
				return quat.rotateY(
					quat.create(),
					initialQuaternion,
					direction * angle,
				);
			}

			case 'z': {
				return quat.rotateZ(
					quat.create(),
					initialQuaternion,
					direction * angle,
				);
			}

			default: {
				console.error('Invalid axis picked');
				return quat.create(); // Return identity quaternion as fallback
			}
		}
	})();

	const currentMatrix: mat4 = mat4.fromQuat(mat4.create(), finalQuaternion);

	currentMatrix[12] = selectedActorData.position.x;
	currentMatrix[13] = selectedActorData.position.y;
	currentMatrix[14] = selectedActorData.position.z;

	// Create rotation matrix from the new quaternion
	setTransformationMatrix(selectedActorData.actor, currentMatrix);

	// Rotate the gumball around the actor's center
	rotateGumball({gumball, angles: currentMatrix});

	renderWindow.render();
}
