import {useEffect, RefObject} from 'react';
import {initializeVtk} from '@/state/viewports';
import {useViewportsStore} from '@/state';

function useInitializeVtk(vtkContainerRef: RefObject<HTMLDivElement>) {
	const {isVtkInitialized, setIsVtkInitialized} = useViewportsStore();

	useEffect(() => {
		if (!vtkContainerRef.current || isVtkInitialized) {
			return;
		}

		const initialize = async () => {
			try {
				const {centerCamera} = initializeVtk(vtkContainerRef.current!);
				useViewportsStore.getState().setCenterCamera(centerCamera);
				setIsVtkInitialized(true);
			} catch (error) {
				console.error('Error initializing VTK:', error);
			}
		};

		initialize();
	}, [vtkContainerRef, isVtkInitialized, setIsVtkInitialized]);
}

export default useInitializeVtk;
