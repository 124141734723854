import {useDensityMapStore} from '@/state/density-map';
import {useMeshesStore} from '@/state/meshes';
import {useResectionPlanesStore} from '@/state/resection-planes';
import {useVisibilityStore} from '@/state/visibility';

import {type Settings} from './settings';

/**
 * Get the currently applied settings
 */
export default function getCurrentSettings(): Settings {
	const densityMap = useDensityMapStore.getState();
	const meshes = useMeshesStore.getState();
	const resectionPlanes = useResectionPlanesStore.getState();
	const visibility = useVisibilityStore.getState();

	let meshesVisibility: boolean | undefined;
	if (meshes.all.visibility === 'hidden') meshesVisibility = false;
	else if (meshes.all.visibility === 'visible') meshesVisibility = true;

	let pointCloudsVisibility: boolean | undefined;
	if (densityMap.pointClouds.every((pointCloud) => pointCloud.isVisible)) {
		pointCloudsVisibility = true;
	} else if (
		densityMap.pointClouds.every((pointCloud) => !pointCloud.isVisible)
	) {
		pointCloudsVisibility = false;
	}

	return {
		densityMap: {
			colorScheme: densityMap.colorScheme.id,
			opacity: densityMap.opacity,
			threshold: densityMap.threshold,
		},
		resectionPlanes: {
			adjustments: resectionPlanes.adjustments,
			pair: resectionPlanes.selectedPairKey,
			plane: resectionPlanes.selectedPlaneKey,
		},
		visibility: {
			landmarks: visibility.landmarks,
			meshes: meshesVisibility,
			pointClouds: pointCloudsVisibility,
			resectionPlanes: resectionPlanes.visibility,
			segmentationOverlays: visibility.segmentationOverlays,
		},
	};
}
