import vtkCubeSource from '@kitware/vtk.js/Filters/Sources/CubeSource';
import vtkCylinderSource from '@kitware/vtk.js/Filters/Sources/CylinderSource';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';

import {quat, mat3, mat4} from 'gl-matrix';

import {useDigitalTwinsStore} from '@/state/digital-twins';
import {useInteractionStore} from '@/state/interaction';
import {getViewports} from '@/state/viewports';

import {setActorOpacity, setGumballHandlesOpacity} from '../gumball';
import {getScreenEventPositionFor, getPosition, getRotation} from '../math';
import {handlePicking} from '../picking';
import {handleResize} from './resize';
import {handleRotate} from './rotation';
import {handleTranslate} from './translation';

import {
	type DigitalTwinData,
	type DrillData,
	type ReamData,
	type ResectData,
	type Vector3D,
} from '@/library/digital-twins';
import {
	type AxisValue,
	type GumballData,
	type InteractionType,
} from '@/library/gumball';
import {applyDigitalTwins} from '@/library/vtk/digital-twins';

export function handleMouseDown(
	event: MouseEvent,
	vtkContainerRef: React.RefObject<HTMLDivElement>,
) {
	if (event.button === 0) {
		const position: {x: number; y: number} = getScreenEventPositionFor(
			vtkContainerRef,
			event,
		);

		handlePicking(position);

		const {
			setLastPosition,
			setIsDragging,
			setIsRKeyPressedAtStart,
			isRKeyPressed,
		} = useInteractionStore.getState();
		setLastPosition(position);
		setIsDragging(true);
		setIsRKeyPressedAtStart(isRKeyPressed);

		event.preventDefault();
		event.stopPropagation();
	}
}

export function handleMouseMove(
	event: MouseEvent,
	vtkContainerRef: React.RefObject<HTMLDivElement>,
) {
	const {
		isDragging,
		lastPosition,
		pickedAxis,
		gumball,
		pickedGumballHandle,
		currentInteractionType,
	} = useInteractionStore.getState();
	const {digitalTwins, selectedDigitalTwinId} = useDigitalTwinsStore.getState();

	if (!isDragging || !pickedGumballHandle || !lastPosition || !gumball) {
		return;
	}

	const selectedActorData = digitalTwins.find(
		(actorData: DigitalTwinData) => actorData.id === selectedDigitalTwinId,
	);
	if (!selectedActorData) {
		return;
	}

	const currentPosition: {x: number; y: number} = getScreenEventPositionFor(
		vtkContainerRef,
		event,
	);

	if (currentInteractionType === 'resize' && pickedAxis) {
		handleResize(
			currentPosition,
			lastPosition,
			pickedAxis as AxisValue,
			selectedActorData,
			gumball as GumballData,
		);
	} else if (currentInteractionType === 'translate' && pickedAxis) {
		handleTranslate(
			currentPosition,
			lastPosition,
			pickedAxis as AxisValue,
			selectedActorData,
			gumball as GumballData,
		);
	} else if (currentInteractionType === 'rotate' && pickedAxis) {
		handleRotate({
			currentPosition,
			lastPosition,
			pickedAxis: pickedAxis as AxisValue,
			selectedActorData,
			gumball: gumball as GumballData,
		});
	}
}

export async function handleMouseUp(event: MouseEvent) {
	if (event.button === 0) {
		const {
			gumball,
			pickedGumballHandle,
			currentInteractionType,
			setIsDragging,
			setPickedGumballHandle,
			setPickedAxis,
			setCurrentInteractionType,
		} = useInteractionStore.getState();
		const {updateDigitalTwin} = useDigitalTwinsStore.getState();

		const {deleteOutlineActor, getSelectedDigitalTwin, outlineActor} =
			useDigitalTwinsStore.getState();
		const {
			volume: {renderer, renderWindow},
		} = getViewports();

		if (!renderer || !renderWindow) {
			console.error('Renderer or render window is not initialized');
			return;
		}

		if (pickedGumballHandle) {
			if (currentInteractionType !== 'resize') {
				pickedGumballHandle.getProperty().setRepresentation(1); // Wireframe for non-resize handles
			}

			pickedGumballHandle.getProperty().setLineWidth(2);

			if (gumball) {
				setGumballHandlesOpacity(gumball as GumballData, 1);
			}

			const selectedActorData = getSelectedDigitalTwin();

			if (selectedActorData) {
				setActorOpacity(selectedActorData.actor, selectedActorData.opacity);
				const rotation: Vector3D = getRotation(selectedActorData.actor);
				const matrix: mat4 = selectedActorData.actor.getMatrix();
				const rotationMatrix: mat3 = [
					matrix[0],
					matrix[4],
					matrix[8],
					matrix[1],
					matrix[5],
					matrix[9],
					matrix[2],
					matrix[6],
					matrix[10],
				];
				const quaternion: quat = quat.fromMat3(quat.create(), rotationMatrix);
				const position: Vector3D = getPosition(selectedActorData.actor);
				const {type} = selectedActorData;

				switch (type) {
					case 'resect': {
						const source = selectedActorData.source as vtkCubeSource;
						const dimensions = {
							x: source.getXLength(),
							y: source.getYLength(),
							z: source.getZLength(),
						};
						updateDigitalTwin({
							id: selectedActorData.id,
							dimensions,
							rotation,
							position,
							matrix,
							quaternion,
							width: dimensions.x,
							height: dimensions.z,
							depth: dimensions.y,
						} as ResectData);
						break;
					}

					case 'drill': {
						const source = selectedActorData.source as vtkCylinderSource;
						const radius = source.getRadius();
						const height = source.getHeight();
						const dimensions = {
							x: radius * 2,
							y: radius * 2,
							z: height,
						};
						updateDigitalTwin({
							id: selectedActorData.id,
							dimensions,
							rotation,
							position,
							matrix,
							quaternion,
							radius,
							height,
						} as DrillData);
						break;
					}

					case 'ream': {
						const source = selectedActorData.source as vtkSphereSource;
						const radius = source.getRadius();
						const dimensions = {
							x: radius * 2,
							y: radius * 2,
							z: radius * 2,
						};
						updateDigitalTwin({
							id: selectedActorData.id,
							dimensions,
							rotation,
							position,
							matrix,
							quaternion,
							radius,
						} as ReamData);
						break;
					}

					default: {
						console.error(`Unsupported type: ${type}`);
						return;
					}
				}

				applyDigitalTwins();
			}

			if (outlineActor) {
				renderer.removeActor(outlineActor);
				outlineActor.delete();
				deleteOutlineActor();
			}

			renderWindow.render();
		}

		setIsDragging(false);
		setPickedGumballHandle(undefined);
		setPickedAxis(undefined);
		setCurrentInteractionType(undefined as InteractionType);
	}
}
