import {RefObject, useEffect, useCallback} from 'react';

import {
	handleMouseDown,
	handleMouseMove,
	handleMouseUp,
} from '@/library/vtk/interactions';
import {useInteractionStore} from '@/state/interaction';
import {useViewportsStore} from '@/state/viewports';

function useGumballInteractions(vtkContainerRef: RefObject<HTMLDivElement>) {
	const {isVtkInitialized} = useViewportsStore();
	const {setIsRKeyPressed, setIsRKeyPressedAtStart, isRKeyPressed} =
		useInteractionStore();

	const onMouseDown = useCallback(
		(event: MouseEvent) => {
			handleMouseDown(event, vtkContainerRef);
		},
		[vtkContainerRef],
	);

	const onMouseMove = useCallback(
		(event: MouseEvent) => {
			handleMouseMove(event, vtkContainerRef);
		},
		[vtkContainerRef],
	);

	const onMouseUp = useCallback((event: MouseEvent) => {
		handleMouseUp(event);
	}, []);

	useEffect(() => {
		const container = vtkContainerRef.current;
		if (!container || !isVtkInitialized) return;

		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'r' || event.key === 'R') {
				setIsRKeyPressed(true);
			}
		};

		const handleKeyUp = (event: KeyboardEvent) => {
			if (event.key === 'r' || event.key === 'R') {
				setIsRKeyPressed(false);
				setIsRKeyPressedAtStart(false);
			}
		};

		container.addEventListener('mousedown', onMouseDown);
		container.addEventListener('mousemove', onMouseMove);
		container.addEventListener('mouseup', onMouseUp);
		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);

		return () => {
			container.removeEventListener('mousedown', onMouseDown);
			container.removeEventListener('mousemove', onMouseMove);
			container.removeEventListener('mouseup', onMouseUp);
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, [
		isVtkInitialized,
		vtkContainerRef,
		onMouseDown,
		onMouseMove,
		onMouseUp,
		setIsRKeyPressed,
		setIsRKeyPressedAtStart,
	]);

	return {isRKeyPressed};
}

export default useGumballInteractions;
