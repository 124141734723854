import {Button, Tooltip} from '@mui/joy';
import {CameraIcon} from 'lucide-react'; // Assuming a similar icon for screenshots
import React, {type MouseEvent, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import {useAnnotations} from '@/hooks';
import {Annotation, captureScreenshot} from '@/library';
import {type ViewportElementKey} from '@/library/cornerstone';
import {useGlobalState} from '@/state';

type ScreenshotButtonProps = {
	readonly viewportKey: ViewportElementKey;
};

function ScreenshotButton({viewportKey}: ScreenshotButtonProps) {
	const {disableAnnotations, enableAnnotations} = useAnnotations();
	const {
		annotations: {addAnnotation, setAreAnnotationsDirty},
		tools: {active: activeTool},
		viewports,
	} = useGlobalState();
	const [isTakingScreenshot, setIsTakingScreenshot] = useState(false);

	const handleClickTakeScreenshot = async (event: MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();

		setAreAnnotationsDirty(true);
		setIsTakingScreenshot(true);

		if (activeTool === 'annotations') {
			disableAnnotations();
		}

		const screenshotDataUrl = await captureScreenshot({
			viewportKey,
		});

		if (screenshotDataUrl) {
			const annotation: Annotation = {
				id: uuidv4(),
				imageIndex:
					viewportKey === 'volume'
						? undefined
						: viewports[`${viewportKey}Viewport`]?.getCurrentImageIdIndex(),
				viewportId: viewportKey,
				note: '',
				toolType: 'Screenshot',
				screenshotDataUrl,
			};

			addAnnotation(annotation);
			setIsTakingScreenshot(false);

			if (activeTool === 'annotations') {
				enableAnnotations();
			}
		}
	};

	const handleMouseOver = () => {
		if (activeTool === 'annotations') {
			disableAnnotations();
		}
	};

	const handleMouseLeave = () => {
		if (activeTool === 'annotations' && !isTakingScreenshot) {
			enableAnnotations();
		}
	};

	return (
		<Tooltip color="primary" title="Take Screenshot" placement="right">
			<Button
				variant="outlined"
				color="neutral"
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '2rem',
					minWidth: 'unset',
					padding: '8px',
					width: '3rem',
					aspectRatio: '1/1',
					zIndex: 1000,
				}}
				onClick={handleClickTakeScreenshot}
				onMouseOver={handleMouseOver}
				onMouseLeave={handleMouseLeave}
			>
				<CameraIcon />
			</Button>
		</Tooltip>
	);
}

export default ScreenshotButton;
