import {
	addDoc,
	collection,
	query as createQuery,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	updateDoc,
	where,
} from 'firebase/firestore';

import {firestoreInstance} from './firebase';

/* eslint-disable-next-line import/no-cycle --
 * TODO: Break this circular dependency
 */
import Scan, {
	converter as scanConverter,
	UpdatableScanProperties,
} from '../models/scan';

import User, {
	converter as userConverter,
	Schema as UserSchema,
} from '../models/user';

const scansPath = `environments/${process.env.CLOUD_ENVIRONMENT}/scans`;
const usersPath = `environments/${process.env.CLOUD_ENVIRONMENT}/users`;

const scanCollectionReference = collection(
	firestoreInstance,
	scansPath,
).withConverter(scanConverter);

export function getScanDocumentReference({id}: {id: string}) {
	return doc(firestoreInstance, `${scansPath}/${id}`).withConverter(
		scanConverter,
	);
}

export function getUserDocumentReference({id}: {id: string}) {
	return doc(firestoreInstance, `${usersPath}/${id}`).withConverter(
		userConverter,
	);
}

export async function createScan({
	ownerUid,
}: {
	ownerUid: string;
}): Promise<Scan> {
	const newScan = new Scan({
		roles: {
			[ownerUid]: 'owner',
		},
	});
	const documentReference = await addDoc(scanCollectionReference, newScan);

	return (await getScan({id: documentReference.id}))!;
}

export async function getScan({id}: {id: string}): Promise<Scan | undefined> {
	const reference = getScanDocumentReference({id});
	const snapshot = await getDoc(reference);

	if (!snapshot.exists()) {
		console.warn("Scan doesn't exist", {id});

		return undefined;
	}

	return snapshot.data();
}

export async function getScans({ownerId}: {ownerId: string}): Promise<Scan[]> {
	const query = createQuery(
		scanCollectionReference,
		where(`roles.${ownerId}`, '==', 'owner'),
	);
	const snapshots = await getDocs(query);

	return snapshots.docs.map((snapshot) => snapshot.data());
}

export async function getUser({id}: {id: string}): Promise<User | undefined> {
	const reference = getUserDocumentReference({id});
	const snapshot = await getDoc(reference);

	if (!snapshot.exists()) {
		console.warn("User doesn't exist", {id});

		return undefined;
	}

	return snapshot.data();
}

export async function deleteScan({id}: {id: string}): Promise<void> {
	const reference = getScanDocumentReference({id});

	await deleteDoc(reference);
}

export async function updateScan({
	id,
	...props
}: {
	id: string;
} & UpdatableScanProperties): Promise<Scan> {
	const documentReference = getScanDocumentReference({id});

	const updateData: UpdatableScanProperties = {
		...props,
		updatedAt: new Date(),
	};

	await updateDoc(documentReference, updateData);

	return (await getScan({id}))!;
}

export async function updateUser({
	id,
	...properties
}: UserSchema): Promise<User> {
	const documentReference = getUserDocumentReference({id});

	await updateDoc(documentReference, properties);

	return (await getUser({id}))!;
}
