/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import {volumeLoader} from '@cornerstonejs/core';
import {cornerstoneStreamingImageVolumeLoader} from '@cornerstonejs/streaming-image-volume-loader';

export default function initializeVolumeLoader() {
	volumeLoader.registerUnknownVolumeLoader(
		cornerstoneStreamingImageVolumeLoader,
	);
	volumeLoader.registerVolumeLoader(
		'cornerstoneStreamingImageVolume',
		cornerstoneStreamingImageVolumeLoader,
	);
}
