import {cache as cornerstoneCache} from '@cornerstonejs/core';
import React from 'react';
import {createRoot} from 'react-dom/client';

import App from '@/app';

cornerstoneCache.setMaxCacheSize(
	process.env.MAX_CACHE_SIZE !== undefined && process.env.MAX_CACHE_SIZE !== ''
		? Number(process.env.MAX_CACHE_SIZE)
		: 4_294_967_296, // 4 GB
);

const container = document.querySelector('#root')!;
const root = createRoot(container);

root.render(<App />);
