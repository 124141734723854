import delay from 'delay';
import {useEffect} from 'react';
import {type mat4, type quat} from 'gl-matrix';

import {createDigitalTwin} from '@/library';
import {type Scan} from '@/library/models';
import {getViewports} from '@/state/viewports';
import {useGlobalState, useViewportsStore} from '@/state';

const useLoadDigitalTwins = ({
	arePointCloudsLoaded,
	scan,
}: {
	arePointCloudsLoaded: boolean;
	scan: Scan;
}) => {
	const {
		digitalTwins: {
			addDigitalTwin,
			digitalTwins,
			selectDigitalTwin,
			setState,
			state,
		},
	} = useGlobalState();
	const {isVtkInitialized} = useViewportsStore.getState();

	useEffect(() => {
		async function loadDigitalTwins() {
			const {volume: volumeViewport} = getViewports();

			if (!volumeViewport.renderer) {
				return;
			}

			setState('updating');

			await delay(1);

			for (const digitalTwin of scan.digitalTwins) {
				let newDigitalTwin;
				const matrix = digitalTwin.matrix as mat4;

				switch (digitalTwin.type) {
					case 'resect': {
						newDigitalTwin = createDigitalTwin({
							bone: digitalTwin.bone,
							depth: digitalTwin.depth,
							depthDirection: digitalTwin.depthDirection,
							height: digitalTwin.height,
							heightDirection: digitalTwin.heightDirection,
							id: digitalTwin.id,
							label: digitalTwin.label,
							matrix,
							opacity: digitalTwin.opacity,
							position: digitalTwin.position,
							quaternion: digitalTwin.quaternion as quat,
							rotation: digitalTwin.rotation,
							type: digitalTwin.type,
							width: digitalTwin.width,
							widthDirection: digitalTwin.widthDirection,
						});
						break;
					}

					case 'drill': {
						newDigitalTwin = createDigitalTwin({
							bone: digitalTwin.bone,
							height: digitalTwin.height,
							id: digitalTwin.id,
							label: digitalTwin.label,
							matrix,
							opacity: digitalTwin.opacity,
							position: digitalTwin.position,
							quaternion: digitalTwin.quaternion as quat,
							radius: digitalTwin.radius,
							rotation: digitalTwin.rotation,
							type: digitalTwin.type,
						});
						break;
					}

					case 'ream': {
						newDigitalTwin = createDigitalTwin({
							bone: digitalTwin.bone,
							id: digitalTwin.id,
							label: digitalTwin.label,
							matrix,
							opacity: digitalTwin.opacity,
							position: digitalTwin.position,
							quaternion: digitalTwin.quaternion as quat,
							radius: digitalTwin.radius,
							type: digitalTwin.type,
						});
						break;
					}

					default: {
						throw new Error('Invalid digital twin type');
					}
				}

				selectDigitalTwin({id: newDigitalTwin.id});
			}

			volumeViewport.render();

			setState('ready');
		}

		if (
			isVtkInitialized &&
			state === 'loading' &&
			arePointCloudsLoaded &&
			scan.digitalTwins?.length
		) {
			loadDigitalTwins().catch(console.error);
		}
	}, [
		addDigitalTwin,
		arePointCloudsLoaded,
		digitalTwins,
		scan.digitalTwins,
		selectDigitalTwin,
		setState,
		state,
		isVtkInitialized,
	]);
};

export default useLoadDigitalTwins;
