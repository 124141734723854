import {type Scan} from '@/library/models';

import {
	useSetDensityMap,
	useSetResectionPlanes,
	useSetTool,
	useSetVisibility,
} from './hooks';

export default function useSetCustomDefaults({scan}: {scan: Scan}) {
	useSetDensityMap();
	useSetResectionPlanes(scan);
	useSetTool(scan);
	useSetVisibility();
}
