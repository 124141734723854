import {useEffect, useState} from 'react';

import {isDefined, setPointCloudOpacity} from '@/library';
import {useGlobalState} from '@/state';

export default function useSetOpacity() {
	const {
		densityMap,
		user: {user},
	} = useGlobalState();
	const [applied, setApplied] = useState(false);

	useEffect(() => {
		if (applied || densityMap.state !== 'ready' || !isDefined(user)) {
			return;
		}

		const opacity = user.customDefaults?.densityMap?.opacity;

		if (isDefined(opacity)) {
			setPointCloudOpacity(opacity);
			densityMap.setOpacity(opacity);
		}

		setApplied(true);
	}, [applied, densityMap, user]);
}
