import {getViewports} from '@/state/viewports';
import {useMeshesStore} from '@/state/meshes';

export function setVisibility({
	id,
	visibility,
}: {
	id: string;
	visibility: boolean;
}): void {
	const {meshes} = useMeshesStore.getState();

	meshes.find((mesh) => id === mesh.id)?.actor.setVisibility(visibility);

	const {volume: volumeViewport} = getViewports();
	volumeViewport.render();
}
