import is from '@sindresorhus/is';
import mapObject, {mapObjectSkip} from 'map-obj';

/**
 * Recursively remove all properties from `object` whose values are `undefined`
 */
export default function removeUndefinedProperties(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	object: Record<string, any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> {
	return mapObject(object, (key, value) => {
		if (is.object(value)) {
			return [key, removeUndefinedProperties(value)];
		}

		if (value === undefined) {
			return mapObjectSkip;
		}

		return [key, value];
	});
}
