import meshConstants from '../constants/meshes';

const {colorDepth} = meshConstants;

export function colorize(index: number): [number, number, number] {
	const colors = [
		[221, 84, 84, 255], // Original red
		[189, 180, 116, 255], // Original khaki
		[77, 228, 121, 255], // Original green
		[166, 70, 235, 255], // Original purple
		[255, 255, 0, 255], // Yellow
		[0, 0, 255, 255], // Blue
		[255, 0, 255, 255], // Magenta
		[0, 255, 255, 255], // Cyan
		[128, 0, 0, 255], // Maroon
		[128, 128, 0, 255], // Olive
		[0, 128, 0, 255], // Dark Green
		[0, 128, 128, 255], // Teal
		[0, 0, 128, 255], // Navy
		[255, 128, 0, 255], // Orange
		[255, 0, 128, 255], // Pink
		[128, 0, 255, 255], // Violet
		[128, 255, 0, 255], // Chartreuse
		[0, 255, 128, 255], // Spring Green
		[0, 128, 255, 255], // Azure
		[255, 128, 128, 255], // Salmon
	];

	const color = colors[index];

	if (!color) {
		throw new Error('NO INDEX COLOR AVAILABLE');
	}

	const [r, g, b] = color;
	return [r / colorDepth, g / colorDepth, b / colorDepth];
}
