import {Enums, volumeLoader, setVolumesForViewports} from '@cornerstonejs/core';
import {
	AngleTool,
	ArrowAnnotateTool,
	BidirectionalTool,
	BrushTool,
	CobbAngleTool,
	CrosshairsTool,
	EllipticalROITool,
	Enums as csToolsEnums,
	LengthTool,
	PanTool,
	RectangleROITool,
	SegmentationDisplayTool,
	StackScrollTool,
	StackScrollMouseWheelTool,
	TrackballRotateTool,
	VolumeRotateMouseWheelTool,
	WindowLevelTool,
	ZoomTool,
	addTool,
	synchronizers,
} from '@cornerstonejs/tools';
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import '@kitware/vtk.js/Rendering/Profiles/Molecule'; // For vtkSphereMapper
import '@kitware/vtk.js/Rendering/Profiles/Volume';

import {cornerstone} from '@/library';
import {getViewports} from '@/state/viewports';

const {BrushEraseTool, LandmarkTool} = cornerstone.tools;

import createImageIdsAndCacheMetadata from './create-image-ids-and-cache-metadata';

import initializeCornerstone3d from './initialize-cornerstone-3d';
import setCtTransferFunctionForVolumeActor from './set-ct-transfer-function-for-volume-actor';
import {type Scan} from '../library/models';

const {ViewportType, OrientationAxis} = Enums;

const {viewportIds} = cornerstone;
const {MouseBindings} = csToolsEnums;
const {createVOISynchronizer} = synchronizers;
// eslint-enable

const ctVoiSynchronizerId = 'CT_VOI_SYNCHRONIZER_ID';

const resizeObserver = new ResizeObserver(() => {
	const renderingEngine = cornerstone.renderingEngine.get();

	const viewports = getViewports();

	const {volume: volumeViewport} = viewports;

	if (!volumeViewport.isMaximized) {
		renderingEngine.resize(true, false, false);
	}

	// To check: if we render the 2D views, after the 3D we get black rendering.
	setTimeout(() => {
		cornerstone.renderViewports('2d');
	}, 1);
});

const viewportColors: Record<string, string> = {
	[viewportIds.axial]: 'rgb(200, 0, 0)',
	[viewportIds.sagittal]: 'rgb(200, 200, 0)',
	[viewportIds.coronal]: 'rgb(0, 200, 0)',
};

const viewportReferenceLineControllable = [
	viewportIds.axial,
	viewportIds.sagittal,
	viewportIds.coronal,
];

const viewportReferenceLineDraggableRotatable = [
	viewportIds.axial,
	viewportIds.sagittal,
	viewportIds.coronal,
];

const viewportReferenceLineSlabThicknessControlsOn = [
	viewportIds.axial,
	viewportIds.sagittal,
	viewportIds.coronal,
];

function getReferenceLineColor(viewportId: string): string {
	return viewportColors[viewportId];
}

function getReferenceLineControllable(viewportId: string): boolean {
	const index = viewportReferenceLineControllable.indexOf(viewportId);
	return index !== -1;
}

function getReferenceLineDraggableRotatable(viewportId: string): boolean {
	const index = viewportReferenceLineDraggableRotatable.indexOf(viewportId);
	return index !== -1;
}

function getReferenceLineSlabThicknessControlsOn(viewportId: string): boolean {
	const index =
		viewportReferenceLineSlabThicknessControlsOn.indexOf(viewportId);
	return index !== -1;
}

function setupSynchronizers(): void {
	const ctVoiSynchronizer = createVOISynchronizer(ctVoiSynchronizerId);

	// Add viewports to VOI synchronizers
	for (const viewportId of [
		viewportIds.axial,
		viewportIds.sagittal,
		viewportIds.coronal,
	]) {
		ctVoiSynchronizer.add({
			renderingEngineId: cornerstone.renderingEngine.get().id,
			viewportId,
		});
	}
}

function setupToolGroups(): void {
	const renderingEngine = cornerstone.renderingEngine.get();

	// Add tools to Cornerstone3D
	addTool(PanTool);
	addTool(WindowLevelTool);
	addTool(StackScrollMouseWheelTool);
	addTool(StackScrollTool);
	addTool(ZoomTool);
	addTool(SegmentationDisplayTool);
	addTool(LandmarkTool);
	addTool(TrackballRotateTool);
	addTool(BrushTool);
	addTool(BrushEraseTool);
	addTool(VolumeRotateMouseWheelTool);
	addTool(CrosshairsTool);
	// Annotation tools
	addTool(AngleTool);
	addTool(ArrowAnnotateTool);
	addTool(BidirectionalTool);
	addTool(CobbAngleTool);
	addTool(EllipticalROITool);
	addTool(LengthTool);
	addTool(RectangleROITool);

	// Define a tool group, which defines how mouse events map to tool commands for
	// Any viewport using the group
	const toolGroup = cornerstone.toolGroups.ctViewport.create();

	if (!toolGroup) {
		throw new Error('Failed to create CT viewport tool group');
	}

	// Add tools to the tool group
	toolGroup.addTool(WindowLevelTool.toolName);
	toolGroup.addTool(PanTool.toolName);
	toolGroup.addTool(ZoomTool.toolName);
	toolGroup.addTool(StackScrollMouseWheelTool.toolName);
	toolGroup.addTool(StackScrollTool.toolName);
	toolGroup.addTool(SegmentationDisplayTool.toolName);
	toolGroup.addTool(LandmarkTool.toolName);
	toolGroup.addTool(BrushTool.toolName, {brushSize: 5});
	toolGroup.addTool(BrushEraseTool.toolName);
	toolGroup.addTool(CrosshairsTool.toolName, {
		getReferenceLineColor,
		getReferenceLineControllable,
		getReferenceLineDraggableRotatable,
		getReferenceLineSlabThicknessControlsOn,
	});

	// Annotation tools
	toolGroup.addTool(AngleTool.toolName);
	toolGroup.addTool(ArrowAnnotateTool.toolName);
	toolGroup.addTool(BidirectionalTool.toolName);
	toolGroup.addTool(CobbAngleTool.toolName);
	toolGroup.addTool(EllipticalROITool.toolName);
	toolGroup.addTool(LengthTool.toolName);
	toolGroup.addTool(RectangleROITool.toolName);

	// Enable segmentation rendering
	toolGroup.setToolEnabled(SegmentationDisplayTool.toolName);
	toolGroup.setToolEnabled(LandmarkTool.toolName);

	// Annotation tools
	toolGroup.setToolEnabled(AngleTool.toolName);
	toolGroup.setToolEnabled(ArrowAnnotateTool.toolName);
	toolGroup.setToolEnabled(BidirectionalTool.toolName);
	toolGroup.setToolEnabled(CobbAngleTool.toolName);
	toolGroup.setToolEnabled(EllipticalROITool.toolName);
	toolGroup.setToolEnabled(LengthTool.toolName);
	toolGroup.setToolEnabled(RectangleROITool.toolName);

	// Set the initial state of the tools, here all tools are active and bound to
	// Different mouse inputs
	toolGroup.setToolActive(WindowLevelTool.toolName, {
		bindings: [
			{
				mouseButton: MouseBindings.Primary, // Left Click
			},
		],
	});
	toolGroup.setToolActive(PanTool.toolName, {
		bindings: [
			{
				mouseButton: MouseBindings.Auxiliary, // Middle Click
			},
		],
	});
	toolGroup.setToolActive(ZoomTool.toolName, {
		bindings: [
			{
				mouseButton: MouseBindings.Secondary, // Right Click
			},
		],
	});
	// As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
	// hook instead of mouse buttons, it does not need to assign any mouse button.
	toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);
	toolGroup.setToolActive(StackScrollTool.toolName);

	// Set the tool group on the viewport
	for (const viewportId of [
		viewportIds.axial,
		viewportIds.sagittal,
		viewportIds.coronal,
	]) {
		toolGroup.addViewport(viewportId, renderingEngine.id);
	}
}

export default async function enableAndLoadCornerstoneViewports({
	scan,
}: {
	scan: Scan;
}): Promise<{imageIds: string[]; volumeId: string}> {
	if (!cornerstone.areElementsDefined()) {
		throw new Error('Cornerstone elements are not defined');
	}

	const {
		axial: axialElement,
		coronal: coronalElement,
		sagittal: sagittalElement,
	} = cornerstone.elements;

	if (!scan) {
		throw new Error('scan is missing');
	}

	if (!scan.seriesInstanceUid || !scan.studyInstanceUid) {
		throw new Error(
			'Scan object is missing one or both required properties (seriesInstanceUid, studyInstanceUid)',
		);
	}

	if (!axialElement || !sagittalElement || !coronalElement) {
		throw new Error('One or more cornerstone elements are null or undefined');
	}

	try {
		await initializeCornerstone3d();
	} catch (error) {
		if (error instanceof Error) {
			console.error('Failed to initialize Cornerstone 3D:', error.message);
		} else {
			console.error('Something went wrong:', error);
		}
	}

	let imageIds: string[];
	try {
		imageIds = await createImageIdsAndCacheMetadata({
			seriesInstanceUid: scan.seriesInstanceUid,
			studyInstanceUid: scan.studyInstanceUid,
		});
	} catch (error) {
		const error_ =
			error instanceof Error
				? new Error(
						`Failed to create image ids and cache metadata: ${error.message}`,
				  )
				: error;
		throw error_;
	}

	// Instantiate a rendering engine
	const renderingEngine = cornerstone.renderingEngine.create();

	// Attach resize observers
	resizeObserver.observe(axialElement);

	// Create a volume viewport
	const viewportInputArray = [
		{
			viewportId: viewportIds.axial,
			type: ViewportType.ORTHOGRAPHIC,
			element: axialElement,
			defaultOptions: {
				orientation: OrientationAxis.AXIAL,
			},
		},
		{
			viewportId: viewportIds.sagittal,
			type: ViewportType.ORTHOGRAPHIC,
			element: sagittalElement,
			defaultOptions: {
				orientation: OrientationAxis.SAGITTAL,
			},
		},
		{
			viewportId: viewportIds.coronal,
			type: ViewportType.ORTHOGRAPHIC,
			element: coronalElement,
			defaultOptions: {
				orientation: OrientationAxis.CORONAL,
			},
		},
	];

	for (const viewportInput of viewportInputArray) {
		if (
			!viewportInput.viewportId ||
			!viewportInput.type ||
			!viewportInput.element
		) {
			throw new Error(
				'A viewport input is missing one or more required properties (viewportId, type, element)',
			);
		}
	}

	renderingEngine.enableElement(viewportInputArray[0]);
	renderingEngine.enableElement(viewportInputArray[1]);
	renderingEngine.enableElement(viewportInputArray[2]);

	// Define a unique id for the volume
	const volumeName = 'CT_VOLUME_ID'; // Id of the volume less loader prefix
	const volumeLoaderScheme = 'cornerstoneStreamingImageVolume'; // Loader id which defines which volume loader to use
	const volumeId = `${volumeLoaderScheme}:${volumeName}`; // VolumeId with loader id + volume id

	// Define a volume in memory
	const volume = await volumeLoader.createAndCacheVolume(volumeId, {
		imageIds,
	});

	// Set the volume to load
	volume.load();

	await setVolumesForViewports(
		renderingEngine,
		[{volumeId, callback: setCtTransferFunctionForVolumeActor}],
		[viewportIds.axial, viewportIds.sagittal, viewportIds.coronal],
	);

	setupToolGroups();
	setupSynchronizers();

	return {imageIds, volumeId};
}
