import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

import {type User} from '@/library/models';

type Actions = {setUser: (user: User) => void};

type State = {
	user?: User;
};

export const useUserStore = create(
	immer<Actions & State>((set) => ({
		setUser(user) {
			set((state) => {
				state.user = user;
			});
		},
		user: undefined,
	})),
);
