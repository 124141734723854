import {type FirestoreDataConverter} from 'firebase/firestore';
import {z as zod} from 'zod';

import {resectionPlaneKeys, resectionPlanePairKeys} from '@/types';

import {sidePanelTools} from '../side-panel-tools';

const schema = zod.object({
	customDefaults: zod
		.object({
			densityMap: zod
				.object({
					colorScheme: zod.string().optional(),
					opacity: zod.number().optional(),
					threshold: zod
						.object({
							lower: zod.number().optional(),
							upper: zod.number().optional(),
						})
						.optional(),
				})
				.optional(),
			resectionPlanes: zod
				.object({
					adjustments: zod
						.record(
							zod.enum(resectionPlanePairKeys),
							zod.record(
								zod.enum(resectionPlaneKeys),
								zod.object({
									position: zod.object({
										z: zod.number().optional(),
									}),
								}),
							),
						)
						.optional(),
					pair: zod.enum(resectionPlanePairKeys).optional(),
					plane: zod.enum(resectionPlaneKeys).optional(),
				})
				.optional(),
			tool: zod.enum(sidePanelTools).optional(),
			visibility: zod
				.object({
					landmarks: zod.boolean().optional(),
					meshes: zod.boolean().optional(),
					resectionPlanes: zod.boolean().optional(),
					segmentationOverlays: zod.boolean().optional(),
				})
				.optional(),
		})
		.optional(),
	id: zod.string(),
});

export type Schema = zod.infer<typeof schema>;

export default class User {
	public readonly customDefaults?: Schema['customDefaults'];
	public readonly id: string;

	constructor({customDefaults, id}: Schema) {
		this.customDefaults = customDefaults;
		this.id = id;
	}
}

export const converter: FirestoreDataConverter<User> = {
	fromFirestore(snapshot, options) {
		const documentData = snapshot.data(options);
		schema.parse({
			id: snapshot.id,
			...documentData,
		});

		return new User({
			customDefaults: documentData.customDefaults,
			id: snapshot.id,
		});
	},
	toFirestore({customDefaults}) {
		return {
			customDefaults,
		};
	},
};
