import {
	getPickingDependencies,
	definePickingArea,
	captureAndValidateBuffers,
	getPickedActor,
	findClickedActorData,
	createActorPickResult,
	createNotPickedResult,
} from './utils';
import {checkGumballHandles} from './gumball';

import {PickResult} from '@/library/gumball';

export const pickActor = (
	position: {x: number; y: number},
	tolerance = 50,
): PickResult => {
	const {gumball, digitalTwins, selector} = getPickingDependencies();
	const pickingArea = definePickingArea(position, tolerance);

	if (!captureAndValidateBuffers(selector, pickingArea)) {
		return createNotPickedResult();
	}

	const pickedActor = getPickedActor(selector, position, tolerance);
	if (!pickedActor) {
		return createNotPickedResult();
	}

	const clickedActorData = findClickedActorData(digitalTwins, pickedActor);

	if (clickedActorData) {
		return createActorPickResult(clickedActorData);
	}

	if (!gumball) {
		return createNotPickedResult();
	}

	const gumballPickResult = checkGumballHandles(gumball, pickedActor);

	return gumballPickResult || createNotPickedResult();
};
