import {getViewports} from '@/state/viewports';
import {useLandmarksStore} from '@/state/landmarks';

export function setVisibility({visibility}: {visibility: boolean}) {
	const {landmarks} = useLandmarksStore.getState();

	for (const landmark of landmarks) {
		landmark.actor.setVisibility(visibility);
	}

	const {volume: volumeViewport} = getViewports();
	volumeViewport.render();
}
