import {useEffect} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {usePreviousDistinct} from 'react-use';

import {firestore} from '@/library/firebase';
import {type Scan} from '@/library/models';

export default function useScan({
	id,
}: {
	id?: string;
}): ReturnType<typeof useDocumentData<Scan>> {
	const documentReference = id
		? firestore.getScanDocumentReference({id})
		: undefined;

	const [scan, isLoading, error, snapshot] =
		useDocumentData<Scan>(documentReference);

	const previousSegmentationVersion = usePreviousDistinct<number | undefined>(
		scan?.segmentation?.version,
	);

	useEffect(() => {
		/* TODO: remove when we implement cornerstone cleanup
		 * triggers a reload when the segmentation version changes
		 * to ensure that we load all the latest pointclouds/meshes/binaries
		 */
		if (
			previousSegmentationVersion !== undefined &&
			scan?.segmentation?.version !== previousSegmentationVersion
		) {
			window.location.reload();
		}
	}, [scan, previousSegmentationVersion]);

	return [scan, isLoading, error, snapshot];
}
