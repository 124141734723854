import {Card, Link, Table, Stack, Typography} from '@mui/joy';
import {
	ReasonPhrases as HttpReasonPhrases,
	StatusCodes as HttpStatusCodes,
} from 'http-status-codes';
import React from 'react';
import {type LoaderFunction, useLoaderData} from 'react-router-dom';

import {Chip} from '@/components';
import {useTitle} from '@/hooks';
import {firebase} from '@/library';
import {type Scan} from '@/library/models';

const {firestore} = firebase;

export const loader: LoaderFunction = async () => {
	const currentUser = await firebase.auth.getCurrentUser();

	if (!currentUser) {
		throw new Response(HttpReasonPhrases.UNAUTHORIZED, {
			status: HttpStatusCodes.UNAUTHORIZED,
		});
	}

	const scans = await firestore.getScans({ownerId: currentUser.uid});

	return {scans};
};

export default function Scans() {
	useTitle('Scans');

	const {scans} = useLoaderData() as {scans: Scan[]};

	return (
		<Stack spacing={2} sx={{paddingBottom: 2}}>
			<Typography level="h1">Scans</Typography>

			<Card>
				<Table hoverRow>
					<thead>
						<tr>
							<th>Scan ID</th>
							<th>Status</th>
						</tr>
					</thead>

					<tbody>
						{scans.map((scan) => (
							<tr key={scan.id}>
								<td>
									<Link href={`/scans/${scan.id}`}>{scan.id}</Link>
								</td>
								<td>
									<Chip
										showIcon
										color={scan.humanReadableState.color}
										loading={scan.humanReadableState.loading}
									>
										{scan.humanReadableState.text}
									</Chip>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Card>
		</Stack>
	);
}
