import React, {useState, useEffect} from 'react';
import {Box, CircularProgress} from '@mui/joy';

type SmoothProgressBarProps = {
	readonly onComplete?: () => void;
	readonly targetProgress?: number;
};

function ViewportLoadingSpinner({
	onComplete,
	targetProgress,
}: SmoothProgressBarProps) {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		let interval: NodeJS.Timeout | undefined;

		if (!targetProgress) {
			return;
		}

		const progressDifference = Math.abs(targetProgress - progress);
		if (progressDifference > 10) {
			// Interpolate if difference is greater than 10
			interval = setInterval(() => {
				setProgress((previousProgress) => {
					const step = 1;
					const nextProgress =
						previousProgress +
						(previousProgress < targetProgress ? step : -step);
					if (
						(previousProgress < targetProgress &&
							nextProgress >= targetProgress) ||
						(previousProgress > targetProgress &&
							nextProgress <= targetProgress)
					) {
						clearInterval(interval);
						return targetProgress;
					}

					return nextProgress;
				});
			}, 10);
		} else {
			// Directly update to targetProgress
			setProgress(targetProgress);
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [targetProgress, progress]);

	useEffect(() => {
		if (onComplete && progress >= 100) {
			onComplete();
		}
	}, [progress, onComplete]);

	const pulseAnimation = {
		'@keyframes pulse': {
			'0%': {
				opacity: 1,
				transform: 'scale(1)',
			},

			'50%': {
				opacity: 0.5,
				transform: 'scale(1.1)',
			},

			'100%': {
				opacity: 1,
				transform: 'scale(1)',
			},
		},
	};

	return (
		<Box
			sx={{
				padding: 2,
				position: 'absolute',
				right: 0,
				top: 0,
				zIndex: 1,
				...pulseAnimation,
			}}
		>
			<CircularProgress
				determinate={targetProgress ? progress !== 0 : false}
				size="sm"
				value={progress === 0 ? undefined : progress}
				sx={{
					animation: 'pulse 1.5s infinite',
					transition: 'value 300ms linear',
				}}
			/>
		</Box>
	);
}

export default ViewportLoadingSpinner;
