import {firebase} from '@/library';

export async function getRawData(url: string): Promise<ArrayBuffer> {
	const firebaseAuthIdToken = await firebase.auth.getIdToken();

	if (!firebaseAuthIdToken) {
		throw new Error('Firebase Auth ID Token not found');
	}

	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);

		xhr.responseType = 'arraybuffer';

		xhr.addEventListener('load', () => {
			resolve(xhr.response);
		});

		xhr.addEventListener('error', (event) => {
			reject(event);
		});

		xhr.setRequestHeader('firebaseAuthIdToken', firebaseAuthIdToken);

		xhr.send();
	});
}

type CustomHeaders = Record<string, string>;

export async function postRawData(
	url: string,
	arrayBuffer: ArrayBuffer,
	customHeaders: CustomHeaders = {},
): Promise<ArrayBuffer> {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', url, true);
		xhr.responseType = 'arraybuffer';

		for (const key of Object.keys(customHeaders)) {
			xhr.setRequestHeader(key, customHeaders[key]);
		}

		xhr.addEventListener('readystatechange', () => {
			// Call a function when the state changes.
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				resolve(xhr.response);
			}

			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status !== 200) {
				reject(xhr.response);
			}
		});

		xhr.send(arrayBuffer);
	});
}

type ChunkData = {
	chunk: ArrayBuffer;
	customHeaders: CustomHeaders;
};

export async function postChunksRawData(
	url: string,
	data: ChunkData[],
): Promise<ArrayBuffer[]> {
	return Promise.all(
		data.map(async (item) => postRawData(url, item.chunk, item.customHeaders)),
	);
}
