import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';

import meshConstants from '../constants/meshes';

import initializeDracoReaderPromise from './initialize-draco-reader-promise';
import vtkDracoReader from './vtk-draco-reader';

export default async function dracoMeshReader(url: string) {
	await initializeDracoReaderPromise;

	const reader = vtkDracoReader.newInstance();

	// Trigger data download
	await reader.setUrl(url);

	const mapper = vtkMapper.newInstance();
	const actor = vtkActor.newInstance();

	actor.setMapper(mapper);
	mapper.setInputConnection(reader.getOutputPort());

	actor.getProperty().setOpacity(meshConstants.opacity);

	return actor;
}
