import type vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import type vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import type vtkCubeSource from '@kitware/vtk.js/Filters/Sources/CubeSource';
import type vtkCylinderSource from '@kitware/vtk.js/Filters/Sources/CylinderSource';
import type vtkCircleSource from '@kitware/vtk.js/Filters/Sources/CircleSource';
import type vtkLineSource from '@kitware/vtk.js/Filters/Sources/LineSource';
import type {vtkPlaneSource} from '@kitware/vtk.js/Filters/Sources/PlaneSource';
import {mat4, quat} from 'gl-matrix';
import {type Coordinate} from '@/types';
import {type Bone} from './bones';

export const digitalTwinModes = ['off', 'remaining', 'removed'] as const;
export type DigitalTwinMode = (typeof digitalTwinModes)[number];

export const digitalTwinPolygonTypes = ['drill', 'ream', 'resect'] as const;
export type DigitalTwinPolygonType = (typeof digitalTwinPolygonTypes)[number];

export type Vector3D = {
	x: number;
	y: number;
	z: number;
};

export type XyzDirections = {
	x: Coordinate;
	y: Coordinate;
	z: Coordinate;
};

export type RotationMatrix = [Coordinate, Coordinate, Coordinate];
export type DepthDirection = 'front' | 'back' | 'frontAndBack';
export type WidthDirection = 'left' | 'right' | 'leftAndRight';
export type HeightDirection = 'up' | 'down' | 'upAndDown';

export type WithWidth = {
	width: number;
	widthDirection: WidthDirection;
};

export type WithHeight = {
	height: number;
	heightDirection: HeightDirection;
};

export type WithDepth = {
	depth: number;
	depthDirection: DepthDirection;
};

export type WithOpacity = {
	opacity: number;
};

export type WithRadius = {
	radius: number;
};

export type WithPosition = {
	position: Vector3D;
};

export type WithRotation = {
	rotation: Vector3D;
};

export type WithDirections = {
	directions: XyzDirections;
};

export type VtkSource =
	| vtkCubeSource
	| vtkCircleSource
	| vtkLineSource
	| vtkCylinderSource
	| vtkPlaneSource
	| vtkSphereSource;

export type BaseDigitalTwinObject = {
	actor: vtkActor;
	bone: Bone;
	center: Coordinate;
	dimensions: Vector3D;
	id: string;
	label: string;
	landmarkId: string;
	source: VtkSource;
	type: DigitalTwinPolygonType;
	matrix: mat4;
	quaternion: quat;
};

export type ResectData = BaseDigitalTwinObject &
	WithOpacity &
	WithPosition &
	WithRotation &
	WithWidth &
	WithHeight &
	WithDepth & {
		source: vtkCubeSource;
		type: 'resect';
	};

export type DrillData = BaseDigitalTwinObject &
	WithOpacity &
	WithPosition &
	WithRotation &
	WithRadius &
	WithHeight &
	WithDirections & {
		type: 'drill';
	};

export type ReamData = BaseDigitalTwinObject &
	WithOpacity &
	WithPosition &
	WithRotation &
	WithRadius & {
		type: 'ream';
	};

export type DigitalTwinData = ResectData | DrillData | ReamData;

export function isDrillData(data: DigitalTwinData): data is DrillData {
	return data.type === 'drill';
}

export function isReamData(data: DigitalTwinData): data is ReamData {
	return data.type === 'ream';
}

export function isResectData(data: DigitalTwinData): data is ResectData {
	return data.type === 'resect';
}

type Sanitize<T> = Omit<T, 'actor' | 'source' | 'matrix' | 'quaternion'> & {
	matrix: number[];
	quaternion: number[];
};

export type SanitizedDrillData = Sanitize<DrillData>;
export type SanitizedReamData = Sanitize<ReamData>;
export type SanitizedResectData = Sanitize<ResectData>;

export type SanitizedDigitalTwinData =
	| SanitizedDrillData
	| SanitizedReamData
	| SanitizedResectData;

export type ScreenPosition = {
	x: number;
	y: number;
};
