import {getViewports} from '@/state/viewports';
import {CameraView, type Bounds, type Coordinate} from '@/types';

export function snapCameraToView(bounds: Bounds, view: CameraView) {
	const {volume: volumeViewport} = getViewports();

	const {camera} = volumeViewport;

	if (!bounds) {
		throw new Error('No bounds found');
	}

	if (!camera) {
		throw new Error('No camera found');
	}

	const center: Coordinate = [
		(bounds[0] + bounds[1]) / 2,
		(bounds[2] + bounds[3]) / 2,
		(bounds[4] + bounds[5]) / 2,
	];

	const distance = Math.hypot(
		(bounds[1] - bounds[0]) ** 2 +
			(bounds[3] - bounds[2]) ** 2 +
			(bounds[5] - bounds[4]) ** 2,
	);

	let position: Coordinate;
	let focalPoint: Coordinate;
	let viewUp: Coordinate;

	switch (view) {
		case CameraView.AxialSuperior: {
			position = [center[0], center[1], center[2] + distance];
			focalPoint = center;
			viewUp = [0, -1, 0];
			break;
		}

		case CameraView.AxialInferior: {
			position = [center[0], center[1], center[2] - distance];
			focalPoint = center;
			viewUp = [0, 1, 0];
			break;
		}

		case CameraView.SagittalMedial: {
			position = [center[0] - distance, center[1], center[2]];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		}

		case CameraView.SagittalLateral: {
			position = [center[0] + distance, center[1], center[2]];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		}

		case CameraView.CoronalAnterior: {
			position = [center[0], center[1] - distance, center[2]];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		}

		case CameraView.CoronalPosterior: {
			position = [center[0], center[1] + distance, center[2]];
			focalPoint = center;
			viewUp = [0, -1, 1];
			break;
		}

		case CameraView.IsometricAnterolateral: {
			position = [
				center[0] - distance,
				center[1] - distance,
				center[2] + distance,
			];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		}

		case CameraView.IsometricAnteromedial: {
			position = [
				center[0] + distance,
				center[1] - distance,
				center[2] + distance,
			];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		}

		default: {
			position = [
				center[0] + distance,
				center[1] + distance,
				center[2] + distance,
			];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		}
	}

	camera.setPosition(...position);
	camera.setFocalPoint(...focalPoint);
	camera.setViewUp(...viewUp);

	const desiredZ = Math.max(...bounds.map((bound) => Math.abs(bound))) * 5;
	camera.setClippingRange(desiredZ / 2, desiredZ);
	camera.setThicknessFromFocalPoint(desiredZ / 4);

	// Make micro adjustment so the spaces between the points in the point cloud don't make it look invisible
	const smallAngle = 1;
	camera.elevation(smallAngle);
	camera.azimuth(smallAngle);

	volumeViewport.renderWindow?.render();
}
