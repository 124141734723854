import {
	type CssVarsThemeOptions,
	extendTheme,
	type PaletteRange,
} from '@mui/joy/styles';

// https://mui.com/joy-ui/customization/theme-colors/#adding-new-palettes
declare module '@mui/joy/styles' {
	interface ColorPalettePropOverrides {
		info: true;
	}

	interface Palette {
		info: PaletteRange;
	}
}

/**
 * @see {@link https://mui.com/joy-ui/customization/theme-colors/#default-color-tokens}
 */
const joyUiDefaultPrimaryPaletteRange = {
	50: '#EDF5FD',
	100: '#E3EFFB',
	200: '#C7DFF7',
	300: '#97C3F0',
	400: '#4393E4',
	500: '#0B6BCB',
	600: '#185EA5',
	700: '#12467B',
	800: '#0A2744',
	900: '#051423',
};

/**
 * @see {@link https://mui.com/joy-ui/customization/theme-colors/#adding-new-palettes}
 */
const joyUiDefaultPrimaryDarkPalette = {
	...joyUiDefaultPrimaryPaletteRange,

	// These properties don't get automatically generated when adding a new
	// palette with only the numeric range (see `joyUiDefaultPrimaryPaletteRange`
	// above), so these were manually copied from Joy UI's default theme
	plainColor: 'var(--joy-palette-info-300)',
	plainHoverBg: 'var(--joy-palette-info-800)',
	plainActiveBg: 'var(--joy-palette-info-700)',
	plainDisabledColor: 'var(--joy-palette-neutral-500)',
	outlinedColor: 'var(--joy-palette-info-200)',
	outlinedBorder: 'var(--joy-palette-info-700)',
	outlinedHoverBg: 'var(--joy-palette-info-800)',
	outlinedActiveBg: 'var(--joy-palette-info-700)',
	outlinedDisabledColor: 'var(--joy-palette-neutral-500)',
	outlinedDisabledBorder: 'var(--joy-palette-neutral-800)',
	softColor: 'var(--joy-palette-info-200)',
	softBg: 'var(--joy-palette-info-800)',
	softHoverBg: 'var(--joy-palette-info-700)',
	softActiveColor: 'var(--joy-palette-info-100)',
	softActiveBg: 'var(--joy-palette-info-600)',
	softDisabledColor: 'var(--joy-palette-neutral-500)',
	softDisabledBg: 'var(--joy-palette-neutral-800)',
	solidColor: 'var(--joy-palette-common-white)',
	solidBg: 'var(--joy-palette-info-500)',
	solidHoverBg: 'var(--joy-palette-info-600)',
	solidActiveBg: 'var(--joy-palette-info-700)',
	solidDisabledColor: 'var(--joy-palette-neutral-500)',
	solidDisabledBg: 'var(--joy-palette-neutral-800)',
};

/**
 * @see {@link https://mui.com/joy-ui/customization/theme-colors/#adding-new-palettes}
 */
const joyUiDefaultPrimaryLightPalette = {
	...joyUiDefaultPrimaryPaletteRange,

	// See explanation for these properties above
	plainColor: 'var(--joy-palette-info-500)',
	plainHoverBg: 'var(--joy-palette-info-100)',
	plainActiveBg: 'var(--joy-palette-info-200)',
	plainDisabledColor: 'var(--joy-palette-neutral-400)',
	outlinedColor: 'var(--joy-palette-info-500)',
	outlinedBorder: 'var(--joy-palette-info-300)',
	outlinedHoverBg: 'var(--joy-palette-info-100)',
	outlinedActiveBg: 'var(--joy-palette-info-200)',
	outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
	outlinedDisabledBorder: 'var(--joy-palette-neutral-200)',
	softColor: 'var(--joy-palette-info-700)',
	softBg: 'var(--joy-palette-info-100)',
	softHoverBg: 'var(--joy-palette-info-200)',
	softActiveColor: 'var(--joy-palette-info-800)',
	softActiveBg: 'var(--joy-palette-info-300)',
	softDisabledColor: 'var(--joy-palette-neutral-400)',
	softDisabledBg: 'var(--joy-palette-neutral-50)',
	solidColor: 'var(--joy-palette-common-white)',
	solidBg: 'var(--joy-palette-info-500)',
	solidHoverBg: 'var(--joy-palette-info-600)',
	solidActiveBg: 'var(--joy-palette-info-700)',
	solidDisabledColor: 'var(--joy-palette-neutral-400)',
	solidDisabledBg: 'var(--joy-palette-neutral-100)',
};

/**
 * Generated by copying Joy UI's default primary blue palette into
 * [Palettte]{@link https://palettte.app}, then manually tweaking
 *
 * Import palettes.json into [Palettte]{@link https://palettte.app} to visualize
 */
const ventPurplePalette = {
	50: '#FAF0FD',
	100: '#F0E6F2',
	200: '#E1CFE6',
	300: '#D1B0D9',
	400: '#A672B3',
	500: '#8A5099',
	600: '#72477D',
	700: '#54345C',
	800: '#2F1D33',
	900: '#170F1A',
};

const themeOptions: CssVarsThemeOptions = {
	colorSchemes: {
		dark: {
			palette: {
				info: joyUiDefaultPrimaryDarkPalette,
				primary: ventPurplePalette,
			},
		},
		light: {
			palette: {
				info: joyUiDefaultPrimaryLightPalette,
				primary: ventPurplePalette,
			},
		},
	},
	components: {
		JoyLink: {
			defaultProps: {
				underline: 'always',
			},
		},
	},
};

const theme = extendTheme(themeOptions);

export const darkTheme = extendTheme({
	...themeOptions,
	cssVarPrefix: 'joy-dark',
	colorSchemes: {
		light: theme.colorSchemes.dark,
	},
});

export default theme;
