import {useEffect} from 'react';

import {getCircumscribedBounds} from '@/library';
import {getViewports} from '@/state/viewports';
import {snapCameraToView} from '@/library/vtk/camera';
import {useGlobalState, useViewportsStore} from '@/state';
import {useDensityMapStore} from '@/state/density-map';
import {type Bounds} from '@/types';
import {CameraView} from '@/types/camera';

import {type Scan} from '../library/models';
import dracoPointCloudReader from '../utils/draco-point-cloud-reader';

function useLoadPointClouds({scan}: {scan: Scan}) {
	const {
		densityMap,
		scan: {auxPointCloudObjects},
	} = useGlobalState();
	const {addPointCloud, addPointCloudBackup} = useDensityMapStore();
	const {centerCamera, isVtkInitialized} = useViewportsStore.getState();

	useEffect(() => {
		const loadAndSetPointCloudActors = async () => {
			if (
				!isVtkInitialized ||
				auxPointCloudObjects.length === 0 ||
				densityMap.state !== 'loading' ||
				!scan.hasReachedMilestone('kneeSegmented')
			) {
				return;
			}

			const {volume: volumeViewport} = getViewports();

			if (!volumeViewport || !volumeViewport.renderer) {
				throw new Error('Volume viewport not found');
			}

			// Extracting all the files into a single array
			const allFiles = auxPointCloudObjects.map((object) => object.file);

			// Feed all files into dracoPointCloudReader at once
			const {actors, backupActors, range, lut} =
				await dracoPointCloudReader(allFiles);

			if (actors.length === 0 || backupActors.length === 0) {
				return;
			}

			let outerBounds: Bounds = actors[0].getBounds();

			for (const [index, actor] of actors.entries()) {
				outerBounds = getCircumscribedBounds(outerBounds, actor.getBounds());

				const auxObject = auxPointCloudObjects[index];

				const pointCloud = {
					id: auxObject.id,
					isEditing: false,
					isVisible: actor.getVisibility(),
					actor,
					label: auxObject.label,
				};
				actor.setPickable(false);
				const pointCloudBackup = {
					...pointCloud,
					actor: backupActors[index],
				};

				addPointCloud(pointCloud);
				addPointCloudBackup(pointCloudBackup);

				volumeViewport.addActor(pointCloud.actor);
			}

			volumeViewport.render();
			densityMap.updateThreshold({
				lut,
				lower: range.min,
				max: range.max,
				min: range.min,
				upper: range.max,
			});

			if (centerCamera) {
				centerCamera(outerBounds);
			} else {
				throw new Error('centerCamera is not defined');
			}

			snapCameraToView(outerBounds, CameraView.IsometricAnteromedial);
			densityMap.setState('ready');
		};

		loadAndSetPointCloudActors().catch(console.error);
	}, [
		auxPointCloudObjects,
		densityMap.state,
		scan.state,
		isVtkInitialized,
		densityMap,
		scan,
		centerCamera,
		addPointCloud,
		addPointCloudBackup,
	]);
}

export default useLoadPointClouds;
