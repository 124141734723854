import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkOpenGLHardwareSelector from '@kitware/vtk.js/Rendering/OpenGL/HardwareSelector';

import {getViewports} from '@/state/viewports';

import {useDigitalTwinsStore} from '@/state/digital-twins';
import {useInteractionStore} from '@/state/interaction';
import {type DigitalTwinData} from '@/library/digital-twins';
import {PickResult, PickType} from '@/library/gumball';

export const getPickingDependencies = () => {
	const {gumball} = useInteractionStore.getState();
	const {digitalTwins} = useDigitalTwinsStore.getState();
	const {
		volume: {selector},
	} = getViewports();
	if (!selector) {
		throw new Error('Missing selector');
	}

	return {gumball, digitalTwins, selector};
};

export const definePickingArea = (
	position: {x: number; y: number},
	tolerance: number,
) => ({
	x1: Math.floor(position.x - tolerance),
	y1: Math.floor(position.y - tolerance),
	x2: Math.ceil(position.x + tolerance),
	y2: Math.ceil(position.y + tolerance),
});

export const captureAndValidateBuffers = (
	selector: vtkOpenGLHardwareSelector,
	area: {x1: number; y1: number; x2: number; y2: number},
) => {
	selector.setArea(area.x1, area.y1, area.x2, area.y2);
	return selector.captureBuffers();
};

export const createNotPickedResult = (): PickResult => ({
	picked: false,
	type: PickType.None,
	axis: undefined,
	handle: undefined,
	pickedActor: {actor: undefined, source: undefined},
	actorData: undefined,
});

export const getPickedActor = (
	selector: vtkOpenGLHardwareSelector,
	position: {x: number; y: number},
	tolerance: number,
) => {
	const info = selector.getPixelInformation(
		[position.x, position.y],
		tolerance,
		[position.x, position.y],
	);

	return info && info.prop ? (info.prop as vtkActor) : undefined;
};

export const findClickedActorData = (
	digitalTwins: DigitalTwinData[],
	pickedActor: vtkActor,
) => digitalTwins.find((actorData) => actorData.actor === pickedActor);

export const createActorPickResult = (
	clickedActorData: DigitalTwinData,
): PickResult => ({
	picked: true,
	type: PickType.Actor,
	axis: undefined,
	handle: undefined,
	pickedActor: {
		actor: clickedActorData.actor,
		source: clickedActorData.source,
	},
	actorData: clickedActorData,
});
