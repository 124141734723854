export enum CameraView {
	AxialSuperior = 'axial-superior',
	AxialInferior = 'axial-inferior',
	SagittalMedial = 'sagittal-medial',
	SagittalLateral = 'sagittal-lateral',
	CoronalAnterior = 'coronal-anterior',
	CoronalPosterior = 'coronal-posterior',
	IsometricAnteromedial = 'isometric-anteromedial',
	IsometricAnterolateral = 'isometric-anterolateral',
}
