import vtkResourceLoader from '@kitware/vtk.js/IO/Core/ResourceLoader';

import {getDracoDecoderUrl, getDracoWrapperUrl} from '../api/urls';

import vtkDracoReader from './vtk-draco-reader';

async function initializeDracoReader() {
	await vtkResourceLoader.loadScript(getDracoWrapperUrl());
	await vtkDracoReader.setWasmBinary(
		getDracoDecoderUrl(),
		'draco_decoder.wasm',
	);
}

// eslint-disable-next-line unicorn/prefer-top-level-await
const initializeDracoReaderPromise = initializeDracoReader();

export default initializeDracoReaderPromise;
