import type vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import {VtkSource, DigitalTwinData} from './digital-twins';

export enum PickType {
	None = 'none',
	Actor = 'actor',
	Gumball = 'gumball',
}

export enum HandleType {
	Rotation = 'rotation',
	Translation = 'translation',
	Resize = 'resize',
}

export type InteractionType = 'resize' | 'translate' | 'rotate' | undefined;

export const Axis = {
	X: 'x',
	Y: 'y',
	Z: 'z',
} as const;

export type AxisKey = keyof typeof Axis;
export type AxisValue = (typeof Axis)[AxisKey];
export const AXES: AxisValue[] = [Axis.X, Axis.Y, Axis.Z];

export type ActorAndSource = {
	actor: vtkActor;
	source: VtkSource;
};

export type ActorAndSourceNullable = {
	actor: vtkActor | undefined;
	source: VtkSource | undefined;
};

export type GumballData = {
	rotationHandles: Record<AxisValue, ActorAndSource>;
	translationHandles: Record<AxisValue, ActorAndSource>;
	resizeHandles: Record<AxisValue, ActorAndSource[]>;
};

export type PickResult = {
	picked: boolean;
	type: PickType;
	axis: AxisValue | undefined;
	handle: HandleType | undefined;
	pickedActor: ActorAndSourceNullable;
	actorData: DigitalTwinData | undefined;
};
