import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkCubeSource from '@kitware/vtk.js/Filters/Sources/CubeSource';
import vtkCylinderSource from '@kitware/vtk.js/Filters/Sources/CylinderSource';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkMatrixBuilder from '@kitware/vtk.js/Common/Core/MatrixBuilder';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import delay from 'delay';
import {mat3, mat4, quat} from 'gl-matrix';

import {type Landmark} from '@/library/landmarks';
import {type Scan} from '@/library/models';
import {
	getAxisVector,
	getPosition,
	setPosition,
	setRotation,
} from '@/library/vtk/math';
import {
	createGumball,
	removeGumball,
	resizeGumball,
	rotateGumball,
	translateGumball,
} from './gumball';
import {getViewports} from '@/state/viewports';
import {useDigitalTwinsStore} from '@/state/digital-twins';
import {useInteractionStore} from '@/state/interaction';
import {useLandmarksStore} from '@/state/landmarks';
import {type Coordinate} from '@/types';

import {Bone} from '../bones';
import {
	DepthDirection,
	type DigitalTwinData,
	DigitalTwinMode,
	DigitalTwinPolygonType,
	DrillData,
	HeightDirection,
	ReamData,
	ResectData,
	Vector3D,
	WidthDirection,
} from '../digital-twins';
import {hidePointsInPointCloud, hidePointsInPointClouds} from './point-clouds';

const createDigitalTwin = ({
	bone = 'femur',
	depth = 30,
	depthDirection = 'front',
	height = 30,
	heightDirection = 'down',
	id = String(Date.now()),
	label,
	matrix = vtkMatrixBuilder.buildFromDegree().identity().getMatrix(),
	opacity = 0.33,
	position,
	quaternion = quat.create(),
	radius = 3,
	rotation = {x: 0, y: 0, z: 0},
	type,
	width = 30,
	widthDirection = 'left',
}: {
	bone?: Bone;
	depth?: number;
	depthDirection?: DepthDirection;
	height?: number;
	heightDirection?: HeightDirection;
	id?: string;
	label?: string;
	matrix?: mat4;
	opacity?: number;
	position?: Vector3D;
	quaternion?: quat;
	radius?: number;
	rotation?: Vector3D;
	type: DigitalTwinPolygonType;
	width?: number;
	widthDirection?: WidthDirection;
}): DigitalTwinData => {
	const {volume: volumeViewport} = getViewports();
	const {addDigitalTwin} = useDigitalTwinsStore.getState();
	const {landmarks} = useLandmarksStore.getState();
	const femurFrontPoint = landmarks.find(
		(landmark) => landmark.id === 'femurFrontPoint',
	);

	if (!femurFrontPoint) {
		throw new Error('Femur front point not found');
	}

	const centerX = position?.x ?? femurFrontPoint.center[0];
	const centerY = position?.y ?? femurFrontPoint.center[1];
	const centerZ = position?.z ?? femurFrontPoint.center[2];

	let source;
	let newDigitalTwin: DigitalTwinData;

	switch (type) {
		case 'drill': {
			source = vtkCylinderSource.newInstance({
				direction: [0, 0, 1],
				resolution: 360,
				radius,
				height,
			});

			newDigitalTwin = {
				actor: vtkActor.newInstance(),
				bone,
				center: [centerX, centerY, centerZ] as Coordinate,
				dimensions: {
					x: radius * 2,
					y: radius * 2,
					z: height,
				},
				directions: {
					x: [1, 0, 0],
					y: [0, 1, 0],
					z: [0, 0, 1],
				},
				height,
				heightDirection: 'down',
				id,
				label: label ?? `drill-${Date.now()}`,
				landmarkId: femurFrontPoint.id,
				matrix,
				opacity,
				position: {x: centerX, y: centerY, z: centerZ},
				quaternion,
				radius,
				rotation,
				source,
				type,
			};

			break;
		}

		case 'ream': {
			source = vtkSphereSource.newInstance({
				phiResolution: 360,
				thetaResolution: 360,
				radius,
			});

			newDigitalTwin = {
				actor: vtkActor.newInstance(),
				bone,
				center: [centerX, centerY, centerZ] as Coordinate,
				dimensions: {
					x: radius * 2,
					y: radius * 2,
					z: radius * 2,
				},
				id,
				label: label ?? `ream-${Date.now()}`,
				landmarkId: femurFrontPoint.id,
				opacity,
				matrix,
				position: {x: centerX, y: centerY, z: centerZ},
				quaternion,
				radius,
				rotation,
				source,
				type,
			};

			break;
		}

		case 'resect': {
			source = vtkCubeSource.newInstance({
				xLength: width,
				yLength: depth,
				zLength: height,
			});

			newDigitalTwin = {
				actor: vtkActor.newInstance(),
				bone,
				center: [centerX, centerY, centerZ] as Coordinate,
				dimensions: {
					x: width,
					y: depth,
					z: height,
				},
				depth,
				depthDirection,
				height,
				heightDirection,
				id,
				label: label ?? `resect-${Date.now()}`,
				landmarkId: femurFrontPoint.id,
				matrix,
				opacity,
				position: {x: centerX, y: centerY, z: centerZ},
				quaternion,
				rotation,
				source,
				type,
				width,
				widthDirection,
			};

			break;
		}

		default: {
			throw new Error('Unsupported digital twin type');
		}
	}

	const mapper = vtkMapper.newInstance();
	mapper.setInputConnection(source.getOutputPort());

	newDigitalTwin.actor.setMapper(mapper);
	newDigitalTwin.actor.getProperty().setColor(1, 1, 1);
	setRotation(newDigitalTwin.actor, quaternion);
	setPosition(newDigitalTwin.actor, {x: centerX, y: centerY, z: centerZ});
	newDigitalTwin.actor.setPickable(false);
	newDigitalTwin.actor.getProperty().setOpacity(opacity);

	volumeViewport.addActor(newDigitalTwin.actor);

	addDigitalTwin(newDigitalTwin);

	hidePointsInPointCloud(bone);

	return newDigitalTwin;
};

const addResect = () => {
	const {selectDigitalTwin} = useDigitalTwinsStore.getState();

	const newResect = createDigitalTwin({
		type: 'resect',
	});

	selectDigitalTwin({id: newResect.id});
	removeGumball();
	createGumball(newResect);
};

const addDrill = () => {
	const {selectDigitalTwin} = useDigitalTwinsStore.getState();

	const newDrill = createDigitalTwin({
		type: 'drill',
	});

	selectDigitalTwin({id: newDrill.id});
	removeGumball();
	createGumball(newDrill);
};

const addReam = () => {
	const {selectDigitalTwin} = useDigitalTwinsStore.getState();

	const newReam = createDigitalTwin({
		type: 'ream',
	});

	selectDigitalTwin({id: newReam.id});
	removeGumball();
	createGumball(newReam);
};

async function applyDigitalTwins() {
	const {
		digitalTwins,
		selectedDigitalTwinId,
		setAreDigitalTwinsDirty,
		setState,
	} = useDigitalTwinsStore.getState();

	const selectedDigitalTwin = digitalTwins.find(
		(digitalTwin) => digitalTwin.id === selectedDigitalTwinId,
	);

	if (!selectedDigitalTwin) return;

	const {volume: volumeViewport} = getViewports();

	setState('updating');

	await delay(100);

	hidePointsInPointCloud(selectedDigitalTwin.bone);

	volumeViewport?.render();

	setAreDigitalTwinsDirty(true);

	volumeViewport?.render();

	setState('ready');
}

const deleteDigitalTwin = (id: string) => {
	const {deleteDigitalTwin, getDigitalTwin, selectedDigitalTwinId} =
		useDigitalTwinsStore.getState();
	const digitalTwin = getDigitalTwin({id});

	removeGumball();

	deleteDigitalTwin({id});

	const {digitalTwins} = useDigitalTwinsStore.getState();

	if (digitalTwins.length === 0) {
		updateDigitalTwinMode({
			mode: 'remaining',
		});
	} else if (id === selectedDigitalTwinId) {
		const newSelectedDigitalTwin = digitalTwins[0];
		updateSelectedDigitalTwin({
			id: newSelectedDigitalTwin.id,
		});

		createGumball(newSelectedDigitalTwin);
	}

	const {volume: volumeViewport} = getViewports();

	volumeViewport.removeActor(digitalTwin.actor);

	hidePointsInPointCloud(digitalTwin.bone);
};

const revertDigitalTwinChanges = ({scan}: {scan: Scan}) => {
	const {digitalTwins, selectDigitalTwin, setAreDigitalTwinsDirty} =
		useDigitalTwinsStore.getState();

	for (const digitalTwin of digitalTwins) {
		deleteDigitalTwin(digitalTwin.id);
	}

	for (const digitalTwin of scan.digitalTwins) {
		const newDigitalTwin = createDigitalTwin({
			position: digitalTwin.position,
			type: digitalTwin.type,
		});

		selectDigitalTwin({id: newDigitalTwin.id});
	}

	setAreDigitalTwinsDirty(false);
};

const snapDigitalTwinToLandmark = (landmark: Landmark) => {
	const {digitalTwins, selectedDigitalTwinId, updateSelectedDigitalTwin} =
		useDigitalTwinsStore.getState();

	const selectedDigitalTwin = digitalTwins.find(
		(digitalTwin) => digitalTwin.id === selectedDigitalTwinId,
	);

	if (!selectedDigitalTwin) {
		throw new Error('Digital twin not found');
	}

	const position = {
		x: Math.round(landmark.center[0]),
		y: Math.round(landmark.center[1]),
		z: Math.round(landmark.center[2]),
	};

	updateSelectedDigitalTwin({landmarkId: landmark.id});
	updateDigitalTwinPosition({
		id: selectedDigitalTwin.id,
		position,
	});

	hidePointsInPointCloud(selectedDigitalTwin?.bone);
};

const updateDigitalTwinAffectedBone = ({
	id,
	newBone,
	oldBone,
}: {
	id: string;
	newBone: Bone;
	oldBone: Bone;
}) => {
	const {updateDigitalTwin} = useDigitalTwinsStore.getState();

	updateDigitalTwin({
		id,
		bone: newBone,
	});

	hidePointsInPointCloud(oldBone);

	hidePointsInPointCloud(newBone);
};

const updateDigitalTwinMode = ({mode}: {mode: DigitalTwinMode}) => {
	const {setMode} = useDigitalTwinsStore.getState();

	setMode(mode);

	hidePointsInPointClouds();
};

const updateDigitalTwinOpacity = ({
	id,
	opacity,
}: {
	id: string;
	opacity: number;
}) => {
	const {getDigitalTwin, updateDigitalTwin} = useDigitalTwinsStore.getState();

	const digitalTwin = getDigitalTwin({id});

	digitalTwin.actor.getProperty().setOpacity(opacity);
	updateDigitalTwin({id, opacity});
};

// eslint-disable-next-line complexity
const updateDigitalTwinDimension = ({
	id,
	dimension,
	value,
	extensionDirection,
}: {
	id: string;
	dimension: 'height' | 'width' | 'depth';
	value: number;
	extensionDirection: HeightDirection | WidthDirection | DepthDirection;
}) => {
	const {digitalTwins, updateDigitalTwin} = useDigitalTwinsStore.getState();

	const digitalTwin = digitalTwins.find(
		(digitalTwin) => digitalTwin.id === id,
	) as DrillData | ResectData | undefined;

	if (!digitalTwin) {
		throw new Error('Digital twin not found');
	}

	let oldValue: number;
	if (dimension === 'height') {
		oldValue = digitalTwin.height;
	} else if (dimension === 'width' || dimension === 'depth') {
		oldValue = (digitalTwin as ResectData)[dimension];
	} else {
		throw new Error('Invalid dimension');
	}

	const change = value - oldValue;

	const rotatedVector = getAxisVector(
		dimension === 'height' ? 'z' : dimension === 'width' ? 'x' : 'y',
		digitalTwin,
	);

	const positionChange =
		extensionDirection !== 'upAndDown' &&
		extensionDirection !== 'leftAndRight' &&
		extensionDirection !== 'frontAndBack'
			? change / 2
			: 0;

	const currentPosition = getPosition(digitalTwin.actor);
	const delta =
		(extensionDirection === 'up' ||
		extensionDirection === 'right' ||
		extensionDirection === 'front'
			? 1
			: -1) * positionChange;

	const centerTranslationVector = Object.values(rotatedVector).map(
		(value) => value * delta,
	);

	const newCenter: Coordinate = [
		currentPosition.x + centerTranslationVector[0],
		currentPosition.y + centerTranslationVector[1],
		currentPosition.z + centerTranslationVector[2],
	];

	setPosition(digitalTwin.actor, {
		x: newCenter[0],
		y: newCenter[1],
		z: newCenter[2],
	});

	switch (dimension) {
		case 'height': {
			if (digitalTwin.type === 'drill') {
				(digitalTwin.source as vtkCylinderSource).setHeight(value);
			} else if (digitalTwin.type === 'resect') {
				digitalTwin.source.setZLength(value);
			}

			break;
		}

		case 'width': {
			(digitalTwin as ResectData).source.setXLength(value);

			break;
		}

		case 'depth': {
			(digitalTwin as ResectData).source.setYLength(value);

			break;
		}
		// No default
	}

	const newDimensions = {...digitalTwin.dimensions};

	newDimensions[
		dimension === 'height' ? 'z' : dimension === 'width' ? 'x' : 'y'
	] = value;

	const newDigitalTwin = {
		...digitalTwin,
		center: newCenter as Coordinate,
		dimensions: newDimensions,
		...(dimension === 'height' ? {height: value} : {}),
		...(dimension === 'width' && ['drill', 'resect'].includes(digitalTwin.type)
			? {width: value}
			: {}),
		...(dimension === 'depth' && digitalTwin.type === 'resect'
			? {depth: value}
			: {}),
		position: {
			x: newCenter[0],
			y: newCenter[1],
			z: newCenter[2],
		},
	};
	updateDigitalTwin({
		...newDigitalTwin,
	});

	const {gumball} = useInteractionStore.getState();

	if (gumball) {
		resizeGumball(gumball, newDigitalTwin);
	}
};

const updateDigitalTwinHeight = (arguments_: {
	id: string;
	height: number;
	extensionDirection: HeightDirection;
}) => {
	updateDigitalTwinDimension({
		...arguments_,
		dimension: 'height',
		value: arguments_.height,
	});
};

const updateDigitalTwinWidth = (arguments_: {
	id: string;
	width: number;
	extensionDirection: WidthDirection;
}) => {
	updateDigitalTwinDimension({
		...arguments_,
		dimension: 'width',
		value: arguments_.width,
	});
};

const updateDigitalTwinDepth = (arguments_: {
	id: string;
	depth: number;
	extensionDirection: DepthDirection;
}) => {
	updateDigitalTwinDimension({
		...arguments_,
		dimension: 'depth',
		value: arguments_.depth,
	});
};

const updateDigitalTwinRadius = ({
	id,
	radius,
}: {
	id: string;
	radius: number;
}) => {
	const {getDigitalTwin, updateDigitalTwin} = useDigitalTwinsStore.getState();
	const digitalTwin = getDigitalTwin({id});

	updateDigitalTwin({
		id,
		dimensions: {
			x: radius * 2,
			y: radius * 2,
			z: digitalTwin.type === 'drill' ? digitalTwin.height : radius * 2,
		},
		radius,
	} as DrillData | ReamData);

	if (digitalTwin.type === 'ream') {
		(digitalTwin.source as vtkSphereSource).setRadius(radius);
	} else if (digitalTwin.type === 'drill') {
		(digitalTwin.source as vtkCylinderSource).setRadius(radius);
	}

	const {gumball} = useInteractionStore.getState();

	if (gumball) {
		const newDimensions = {
			x: radius,
			y: radius,
			z: digitalTwin.type === 'drill' ? digitalTwin.height : radius,
		};

		resizeGumball(gumball, {...digitalTwin, dimensions: newDimensions});
	}

	getViewports().volume?.render();
};

function updateDigitalTwinRotation({
	id,
	rotation,
}: {
	id: string;
	rotation: Vector3D;
}) {
	const {getDigitalTwin, updateDigitalTwin} = useDigitalTwinsStore.getState();
	const digitalTwin = getDigitalTwin({id});

	const rotationMatrix = vtkMatrixBuilder
		.buildFromDegree()
		.identity()
		.rotateX(rotation.x)
		.rotateY(rotation.y)
		.rotateZ(rotation.z)
		.getMatrix();

	const mat3Matrix = mat3.fromMat4(mat3.create(), rotationMatrix);

	const quaternion = quat.fromMat3(quat.create(), mat3Matrix);

	setRotation(digitalTwin.actor, quaternion);

	updateDigitalTwin({
		id,
		matrix: digitalTwin.actor.getMatrix(),
		quaternion,
		...(['drill', 'resect'].includes(digitalTwin.type) ? {rotation} : {}),
	});

	const {gumball} = useInteractionStore.getState();

	if (gumball) {
		rotateGumball({gumball, angles: rotationMatrix});
	}

	getViewports().volume?.render();
}

const updateDigitalTwinPosition = ({
	id,
	position,
}: {
	id: string;
	position: Vector3D;
}) => {
	const {getDigitalTwin, updateDigitalTwin} = useDigitalTwinsStore.getState();
	const digitalTwin = getDigitalTwin({id});

	setPosition(digitalTwin.actor, position);

	updateDigitalTwin({
		id,
		center: [position.x, position.y, position.z] as Coordinate,
		position,
	});

	const {gumball} = useInteractionStore.getState();

	if (gumball) {
		translateGumball({
			gumball,
			translation: position,
			actorCenter: {x: 0, y: 0, z: 0},
		});
	}

	getViewports().volume?.render();
};

const updateSelectedDigitalTwin = ({id}: {id: string}) => {
	const {selectDigitalTwin, digitalTwins} = useDigitalTwinsStore.getState();

	selectDigitalTwin({id});

	removeGumball();

	for (const digitalTwin of digitalTwins) {
		if (digitalTwin.id === id) {
			digitalTwin.actor.setPickable(false);
			createGumball(digitalTwin);
		} else {
			digitalTwin.actor.setPickable(true);
		}
	}
};

const updateDigitalTwinsVisibility = ({isVisible}: {isVisible: boolean}) => {
	const {digitalTwins} = useDigitalTwinsStore.getState();

	for (const digitalTwin of digitalTwins) {
		const properties = digitalTwin.actor.getProperty();

		if (isVisible) {
			properties.setOpacity(digitalTwin.opacity);
		} else {
			properties.setOpacity(0);
		}
	}

	hidePointsInPointClouds();
};

const positionRelativeToLandmark = (landmarkId: string, position: Vector3D) => {
	const {landmarks} = useLandmarksStore.getState();

	const landmark = landmarks.find((landmark) => landmark.id === landmarkId);

	if (!landmark) {
		return position;
	}

	const relativePosition = {
		x: position.x - landmark.center[0],
		y: position.y - landmark.center[1],
		z: position.z - landmark.center[2],
	};

	return relativePosition;
};

const globalPositionFromLandmarkRelativePosition = (
	landmarkId: string,
	relativePosition: Vector3D,
) => {
	const {landmarks} = useLandmarksStore.getState();

	const landmark = landmarks.find((landmark) => landmark.id === landmarkId);

	if (!landmark) {
		return relativePosition;
	}

	const globalPosition = {
		x: relativePosition.x + landmark.center[0],
		y: relativePosition.y + landmark.center[1],
		z: relativePosition.z + landmark.center[2],
	};

	return globalPosition;
};

export {
	createDigitalTwin,
	addDrill,
	addReam,
	addResect,
	applyDigitalTwins,
	deleteDigitalTwin,
	globalPositionFromLandmarkRelativePosition,
	positionRelativeToLandmark,
	revertDigitalTwinChanges,
	snapDigitalTwinToLandmark,
	updateDigitalTwinAffectedBone,
	updateDigitalTwinDepth,
	updateDigitalTwinHeight,
	updateDigitalTwinMode,
	updateDigitalTwinOpacity,
	updateDigitalTwinPosition,
	updateDigitalTwinRadius,
	updateDigitalTwinRotation,
	updateDigitalTwinWidth,
	updateDigitalTwinsVisibility,
	updateSelectedDigitalTwin,
};
