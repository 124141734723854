import {useEffect} from 'react';
import {useRouteError} from 'react-router-dom';

export default function useLogRouteError() {
	const error = useRouteError();

	useEffect(() => {
		if (error) {
			console.error(error);
		}
	}, [error]);
}
