import {noCase} from 'change-case';
import {FileTextIcon, type LucideIcon} from 'lucide-react';

import {
	AnnotationsIcon,
	DensityIcon,
	DigitalTwinIcon,
	type Icon,
	ResectionPlanesIcon,
	VisibilityIcon,
} from '@/icons';
import {isDefined, type SidePanelTool} from '@/library';
import {type Scan} from '@/library/models';
import type {Milestone as ScanMilestone} from '@/library/models/scan';
import {useGlobalState} from '@/state';

import useAnnotations from './use-annotations';
import useCustomDefaultsState from './use-custom-defaults-state';

type Tool = {
	disableAt?: ScanMilestone;
	enableAt: ScanMilestone;
	hasCustomizableDefaults: boolean;
	Icon: LucideIcon | Icon;
};

const tools: Record<SidePanelTool, Tool> = {
	annotations: {
		enableAt: 'kneeSegmented',
		hasCustomizableDefaults: false,
		Icon: AnnotationsIcon,
	},
	visibility: {
		enableAt: 'kneeSegmented',
		hasCustomizableDefaults: true,
		Icon: VisibilityIcon,
	},
	resectionPlanes: {
		disableAt: 'approved',
		enableAt: 'landmarked',
		hasCustomizableDefaults: true,
		Icon: ResectionPlanesIcon,
	},
	densityMap: {
		enableAt: 'kneeSegmented',
		hasCustomizableDefaults: true,
		Icon: DensityIcon,
	},
	digitalTwins: {
		disableAt: 'approved',
		enableAt: 'landmarked',
		hasCustomizableDefaults: false,
		Icon: DigitalTwinIcon,
	},
	surgicalPlan: {
		enableAt: 'landmarked',
		hasCustomizableDefaults: false,
		Icon: FileTextIcon,
	},
};

export default function useTools({scan}: {scan?: Scan}) {
	const {disableAnnotations, enableAnnotations} = useAnnotations();
	const customDefaultsState = useCustomDefaultsState();
	const {tools: toolsState, visibility: visibilityState} = useGlobalState();

	function activateTool(toolName: SidePanelTool) {
		const previousToolName = toolsState.active;

		if (toolName === 'annotations') {
			enableAnnotations();
		} else if (previousToolName === 'annotations') {
			disableAnnotations();
		}

		if (toolName === 'surgicalPlan') {
			visibilityState.setVisibility('surgicalPlan', true);
		} else if (previousToolName === 'surgicalPlan') {
			visibilityState.setVisibility('surgicalPlan', false);
		}

		toolsState.setActive(toolName);
	}

	function deactivateTool() {
		if (toolsState.active === 'annotations') {
			disableAnnotations();
		}

		toolsState.setActive(undefined);
		visibilityState.setVisibility('surgicalPlan', false);
	}

	function setTool(toolName?: SidePanelTool): {notAllowedReason?: string} {
		if (!isDefined(scan)) return {};

		if (!isDefined(toolName) || toolName === toolsState.active) {
			deactivateTool();
			return {};
		}

		const tool = tools[toolName];
		const humanReadableToolName = noCase(toolName);

		if (!scan.hasReachedMilestone(tool.enableAt)) {
			let process = '';

			if (tool.enableAt === 'kneeSegmented') process = 'knee segmentation';
			else if (tool.enableAt === 'landmarked') process = 'landmarking';

			return {
				notAllowedReason: `The ${humanReadableToolName} tool will be enabled once ${process} has succeeded.`,
			};
		}

		if (isDefined(tool.disableAt) && scan.hasReachedMilestone(tool.disableAt)) {
			return {
				notAllowedReason: `The ${humanReadableToolName} tool can no longer be used because the scan has reached the '${noCase(
					tool.disableAt,
				)}' state.`,
			};
		}

		if (!tool.hasCustomizableDefaults && customDefaultsState !== 'inactive') {
			return {
				notAllowedReason: `The ${humanReadableToolName} tool will be enabled after you save your Custom Defaults.`,
			};
		}

		activateTool(toolName);
		return {};
	}

	return {
		setTool,
		tools,
	};
}
