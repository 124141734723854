import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

import {type Landmark} from '@/library/landmarks';

type State = {
	landmarks: Landmark[];
};

type Actions = {
	addLandmark: (landmark: Landmark) => void;
};

export const useLandmarksStore = create(
	immer<State & Actions>((set) => ({
		landmarks: [],
		addLandmark: (landmark) =>
			set((state) => {
				state.landmarks.push(landmark);
			}),
	})),
);
