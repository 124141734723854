import {Alert as JoyAlert, type AlertProps as JoyAlertProps} from '@mui/joy';
import React from 'react';

import ContactVent from './contact-vent';
import StatusIcon from './status-icon';

export type Props = JoyAlertProps & {
	readonly loading?: boolean;
	readonly showContactButton?: boolean;
	readonly showIcon?: boolean;
};

/**
 * Enhanced version of [Joy UI's Alert]{@link https://mui.com/joy-ui/react-alert/}
 * with the following features:
 * - Automatically adds icon based on `color`
 * - Optional 'Contact Vent' button
 */
export default function Alert({
	loading = false,
	showContactButton = false,
	showIcon,
	...props
}: Props) {
	if (showContactButton) {
		props.endDecorator = <ContactVent color={props.color} component="button" />;
	}

	if (showIcon) {
		props.startDecorator = <StatusIcon color={props.color} loading={loading} />;
	}

	return <JoyAlert {...props} />;
}
