import {cornerstone} from '@/library';
import {useGlobalState} from '@/state';
import {type CheckboxChangeHandler} from '@/types';

export default function useSegmentationOverlaysVisibility(): {
	handleVisibilityChange: CheckboxChangeHandler;
	visibility: boolean;
} {
	const {visibility: globalVisibility} = useGlobalState();

	const handleVisibilityChange: CheckboxChangeHandler = (event) => {
		setVisibility(event.target.checked);
	};

	function setVisibility(visibility: boolean) {
		cornerstone.segmentationOverlays.setVisibility(visibility);
		globalVisibility.setVisibility('segmentationOverlays', visibility);
	}

	return {
		handleVisibilityChange,
		visibility: globalVisibility.segmentationOverlays,
	};
}
