import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkPlaneSource from '@kitware/vtk.js/Filters/Sources/PlaneSource';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {initialResectionPlanesAdjustments} from '@/library/models/scan';
import {
	type Axis,
	type ResectionPlane,
	type ResectionPlaneProperty,
	type ResectionPlaneKey,
	type ResectionPlanePairKey,
	type ResectionPlanes,
	type ResectionPlanesState,
} from '@/types';

export type State = {
	adjustments: ResectionPlanesState;
	dirtyProperty?: ResectionPlaneProperty;
	resectionPlanes: ResectionPlanes;
	selectedPairKey: ResectionPlanePairKey;
	selectedPlaneKey: ResectionPlaneKey;
	state: 'loading' | 'initialRendering' | 'updating' | 'ready' | 'error';
	visibility: boolean;
};

type Actions = {
	setAdjustment: (parameters: {
		axis: Axis;
		pair: ResectionPlanePairKey;
		plane: ResectionPlaneKey;
		property: ResectionPlaneProperty;
		value: number;
	}) => void;
	setAdjustments: (adjustments: ResectionPlanesState) => void;
	setDirtyProperty: (property?: ResectionPlaneProperty) => void;
	setSelectedPairKey: (key: ResectionPlanePairKey) => void;
	setSelectedPlaneKey: (key: ResectionPlaneKey) => void;
	setState: (state: State['state']) => void;
	setVisibility: (visibility: boolean) => void;
	updateResectionPlane: (resectionPlane: ResectionPlane) => void;
};

export const useResectionPlanesStore = create<State & Actions>()(
	immer((set) => ({
		adjustments: initialResectionPlanesAdjustments,
		resectionPlanes: {
			anatomical: {
				femoral: {
					actor: vtkActor.newInstance(),
					normal: [0, 0, 0],
					origin: [0, 0, 0],
					pair: 'anatomical',
					plane: 'femoral',
					source: vtkPlaneSource.newInstance(),
					zOffsetInitial: 0,
				},
				tibial: {
					actor: vtkActor.newInstance(),
					normal: [0, 0, 0],
					origin: [0, 0, 0],
					pair: 'anatomical',
					plane: 'tibial',
					source: vtkPlaneSource.newInstance(),
					zOffsetInitial: 0,
				},
			},
			mechanical: {
				femoral: {
					actor: vtkActor.newInstance(),
					normal: [0, 0, 0],
					origin: [0, 0, 0],
					pair: 'mechanical',
					plane: 'femoral',
					source: vtkPlaneSource.newInstance(),
					zOffsetInitial: 0,
				},
				tibial: {
					actor: vtkActor.newInstance(),
					normal: [0, 0, 0],
					origin: [0, 0, 0],
					pair: 'mechanical',
					plane: 'tibial',
					source: vtkPlaneSource.newInstance(),
					zOffsetInitial: 0,
				},
			},
			kinematic: {
				femoral: {
					actor: vtkActor.newInstance(),
					normal: [0, 0, 0],
					origin: [0, 0, 0],
					pair: 'kinematic',
					plane: 'femoral',
					source: vtkPlaneSource.newInstance(),
					zOffsetInitial: 0,
				},
				tibial: {
					actor: vtkActor.newInstance(),
					normal: [0, 0, 0],
					origin: [0, 0, 0],
					pair: 'kinematic',
					plane: 'tibial',
					source: vtkPlaneSource.newInstance(),
					zOffsetInitial: 0,
				},
			},
		},
		selectedPairKey: 'anatomical',
		selectedPlaneKey: 'femoral',
		setAdjustment({axis, pair, plane, property, value}) {
			set((state) => {
				state.adjustments[pair][plane][property][axis] = value;
			});
		},
		setAdjustments(resectionPlanesAdjustments) {
			set((state) => {
				state.adjustments = resectionPlanesAdjustments;
			});
		},
		setDirtyProperty(property) {
			set((state) => {
				state.dirtyProperty = property;
			});
		},
		setSelectedPairKey(selectedResectionPlanePair) {
			set((state) => {
				state.selectedPairKey = selectedResectionPlanePair;
			});
		},
		setSelectedPlaneKey(selectedResectionPlane) {
			set((state) => {
				state.selectedPlaneKey = selectedResectionPlane;
			});
		},
		setState(newState: State['state']) {
			set((state) => {
				state.state = newState;
			});
		},
		setVisibility(visibility) {
			set((state) => {
				state.visibility = visibility;
			});
		},
		state: 'loading',
		updateResectionPlane(resectionPlane) {
			set((state) => {
				state.resectionPlanes[resectionPlane.pair][resectionPlane.plane] =
					resectionPlane;
			});
		},
		visibility: true,
	})),
);
