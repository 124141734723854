import {
	useSetLandmarksVisibility,
	useSetMeshesVisibility,
	useSetResectionPlanesVisibility,
	useSetSegmentationOverlaysVisibility,
} from './hooks';

export default function useSetVisibility() {
	useSetLandmarksVisibility();
	useSetMeshesVisibility();
	useSetResectionPlanesVisibility();
	useSetSegmentationOverlaysVisibility();
}
