import delay from 'delay';
import {useEffect, useRef} from 'react';

import {isDefined, setPointCloudThreshold} from '@/library';
import {useGlobalState} from '@/state';

export default function useSetThreshold() {
	const {
		customDefaults,
		densityMap,
		user: {user},
	} = useGlobalState();

	// Local state as ref to prevent async effect from running multiple times
	const applied = useRef<boolean>(false);

	useEffect(() => {
		async function apply() {
			if (
				applied.current ||
				!customDefaults.densityMap.colorScheme.applied ||
				densityMap.state !== 'ready' ||
				!isDefined(densityMap.threshold.lut) ||
				!isDefined(user)
			) {
				return;
			}

			applied.current = true;

			const customThreshold = user.customDefaults?.densityMap?.threshold;

			if (isDefined(customThreshold)) {
				let {lower, upper} = customThreshold;

				lower ??= densityMap.threshold.lower;
				upper ??= densityMap.threshold.upper;

				densityMap.updateThreshold({
					lower,
					upper,
				});

				densityMap.setState('updating');

				await delay(1); // Force render

				setPointCloudThreshold({
					colorScheme: densityMap.colorScheme,
					lower,
					lut: densityMap.threshold.lut,
					upper,
				});

				densityMap.setState('ready');
			}
		}

		apply().catch(console.error);
	}, [customDefaults, densityMap, user]);
}
