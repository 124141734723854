import {landmarks} from '@/library';
import {useGlobalState} from '@/state';
import {type CheckboxChangeHandler} from '@/types';

export default function useLandmarksVisibility(): {
	handleVisibilityChange: CheckboxChangeHandler;
	visibility: boolean;
} {
	const {visibility: globalVisibility} = useGlobalState();

	const handleVisibilityChange: CheckboxChangeHandler = ({
		target: {checked: visibility},
	}) => {
		landmarks.setVisibility({visibility});
		globalVisibility.setVisibility('landmarks', visibility);
	};

	return {
		handleVisibilityChange,
		visibility: globalVisibility.landmarks,
	};
}
