import {useEffect, useState} from 'react';

import {isDefined, landmarks} from '@/library';
import {useGlobalState} from '@/state';
import {useLandmarksStore} from '@/state/landmarks';

export default function useSetLandmarksVisibility() {
	const {
		user: {user},
		viewports: {areCornerstoneViewportsInitialized: viewportsInitialized},
		visibility: globalVisibility,
	} = useGlobalState();
	const landmarksState = useLandmarksStore();
	const [applied, setApplied] = useState<boolean>(false);

	useEffect(() => {
		if (
			applied ||
			!isDefined(user) ||
			!viewportsInitialized ||
			landmarksState.landmarks.length === 0
		) {
			return;
		}

		const visibility = user.customDefaults?.visibility?.landmarks;

		if (isDefined(visibility) && visibility !== globalVisibility.landmarks) {
			landmarks.setVisibility({visibility});
			globalVisibility.setVisibility('landmarks', visibility);
		}

		setApplied(true);
	}, [
		applied,
		user,
		viewportsInitialized,
		landmarksState.landmarks,
		globalVisibility,
	]);
}
