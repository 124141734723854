import {metaData, utilities} from '@cornerstonejs/core';

import ptScalingMetaDataProvider from './pt-scaling-metadata-provider';
import WADORSHeaderProvider from './wado-rs-header-provider';

const {calibratedPixelSpacingMetadataProvider} = utilities;

export default function initializeProviders() {
	metaData.addProvider(
		WADORSHeaderProvider.get.bind(WADORSHeaderProvider),
		9999,
	);
	metaData.addProvider(
		ptScalingMetaDataProvider.get.bind(ptScalingMetaDataProvider),
		10_000,
	);
	metaData.addProvider(
		calibratedPixelSpacingMetadataProvider.get.bind(
			calibratedPixelSpacingMetadataProvider,
		),
		11_000,
	);
}
