import {capitalCase} from 'change-case';
import Color from 'colorjs.io';
import {useEffect} from 'react';

import {type Scan} from '@/library/models';
import {type Mesh, useGlobalState, useViewportsStore} from '@/state';
import {dracoMeshReader} from '@/utils';
import {getViewports} from '@/state/viewports';

function useLoadMeshes({scan}: {scan: Scan}) {
	const {
		meshes: globalMeshes,
		scan: {auxMeshObjects},
	} = useGlobalState();

	const {isVtkInitialized} = useViewportsStore.getState();

	useEffect(() => {
		async function loadAndSetMeshActors() {
			if (
				!isVtkInitialized ||
				auxMeshObjects.length === 0 ||
				globalMeshes.loaded ||
				!scan.hasReachedMilestone('kneeSegmented')
			) {
				return;
			}

			const meshes: Mesh[] = [];

			await Promise.all(
				auxMeshObjects.map(async (auxMeshObject) => {
					const vtkActor = await dracoMeshReader(auxMeshObject.file);
					vtkActor.getProperty().setColor(...auxMeshObject.color);
					vtkActor.setPickable(false);

					meshes.push({
						actor: vtkActor,
						color: new Color({
							coords: auxMeshObject.color,
							space: 'srgb',
						}),
						id: auxMeshObject.id,
						labels: {
							humanReadable: capitalCase(auxMeshObject.label),
							raw: auxMeshObject.label,
						},
						visibility: vtkActor.getVisibility(),
					});
				}),
			);

			const {volume: volumeViewport} = getViewports();
			volumeViewport.addActors(meshes.map((mesh) => mesh.actor));
			volumeViewport.render();

			globalMeshes.setMeshes(meshes);
			globalMeshes.setLoaded(true);
		}

		loadAndSetMeshActors().catch((error) => {
			if (error instanceof Error) {
				globalMeshes.setError(error);
			}

			console.error(error);
		});
	}, [
		auxMeshObjects,
		auxMeshObjects.length,
		globalMeshes,
		globalMeshes.loaded,
		globalMeshes.setLoaded,
		isVtkInitialized,
		scan,
		scan.state,
	]);
}

export default useLoadMeshes;
