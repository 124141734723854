import vtkMath from '@kitware/vtk.js/Common/Core/Math';

import {translateGumball} from '@/library/vtk/gumball';
import {
	type DigitalTwinData,
	type ScreenPosition,
	type Vector3D,
} from '@/library/digital-twins';
import {type AxisValue, type GumballData} from '@/library/gumball';
import {getViewports} from '@/state/viewports';

import {
	getAxisVector,
	getScreenPositionRay,
	rayPlaneIntersection,
	setPosition,
} from '../math';

// eslint-disable-next-line max-params
export function handleTranslate(
	currentPosition: ScreenPosition,
	lastPosition: ScreenPosition,
	pickedAxis: AxisValue,
	selectedActorData: DigitalTwinData,
	gumball: GumballData,
) {
	const {
		volume: {renderWindow, renderer, camera},
	} = getViewports();

	if (!camera) {
		throw new Error('Camera not found');
	}

	if (!renderer) {
		throw new Error('Renderer not found');
	}

	if (!renderWindow) {
		throw new Error('Render window not found');
	}

	const actorCenter: Vector3D = selectedActorData.position;
	let planeNormal: Vector3D;

	switch (pickedAxis) {
		case 'x': {
			planeNormal = getAxisVector('z', selectedActorData);

			break;
		}

		case 'y': {
			planeNormal = getAxisVector('x', selectedActorData);

			break;
		}

		case 'z': {
			planeNormal = getAxisVector('y', selectedActorData);

			break;
		}

		default: {
			throw new Error('Invalid axis');
		}
	}

	const currentRay: Vector3D = getScreenPositionRay(
		currentPosition.x,
		currentPosition.y,
		renderer,
	);
	const current: Vector3D = rayPlaneIntersection(
		camera.getPosition(),
		currentRay,
		actorCenter,
		planeNormal,
	);
	const initialRay: Vector3D = getScreenPositionRay(
		lastPosition.x,
		lastPosition.y,
		renderer,
	);
	const initial: Vector3D = rayPlaneIntersection(
		camera.getPosition(),
		initialRay,
		actorCenter,
		planeNormal,
	);
	const pickedAxisVector: Vector3D = getAxisVector(
		pickedAxis,
		selectedActorData,
	);
	const deltaVector: Vector3D = {
		x: current.x - initial.x,
		y: current.y - initial.y,
		z: current.z - initial.z,
	};
	const scalarDelta: number =
		vtkMath.dot(
			[deltaVector.x, deltaVector.y, deltaVector.z],
			[pickedAxisVector.x, pickedAxisVector.y, pickedAxisVector.z],
		) /
		vtkMath.dot(
			[pickedAxisVector.x, pickedAxisVector.y, pickedAxisVector.z],
			[pickedAxisVector.x, pickedAxisVector.y, pickedAxisVector.z],
		);
	const translation: Vector3D = {
		x: scalarDelta * pickedAxisVector.x,
		y: scalarDelta * pickedAxisVector.y,
		z: scalarDelta * pickedAxisVector.z,
	};

	const newPosition: Vector3D = {
		x: actorCenter.x + translation.x,
		y: actorCenter.y + translation.y,
		z: actorCenter.z + translation.z,
	};

	setPosition(selectedActorData.actor, newPosition);

	translateGumball({actorCenter, translation, gumball});

	renderWindow.render();
}
