import React from 'react';

export type IconProps = {
	readonly size?: number;
};

export type Icon = React.FunctionComponent<IconProps>;

export default function makeIcon({
	name,
	Svg,
}: {
	name: string;
	Svg: React.ElementType;
}): Icon {
	function Icon({size = 24}: IconProps) {
		return (
			<Svg className={`lucide lucide-${name}`} height={size} width={size} />
		);
	}

	return Icon;
}
