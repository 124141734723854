import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

import {type Landmarks} from '@/library/landmarks';
import {
	type AuxMeshObject,
	type AuxPointCloudObject,
} from '@/types/cornerstone';

export type ScanState = {
	auxMeshObjects: AuxMeshObject[];
	auxPointCloudObjects: AuxPointCloudObject[];
	bonemaskBinaries: ArrayBuffer | undefined;
	landmarks: Landmarks;
};

export type ScanActions = {
	setAuxMeshObjects: (objects: AuxMeshObject[]) => void;
	setAuxPointCloudObjects: (objects: AuxPointCloudObject[]) => void;
	setLandmarks: (landmarks: Landmarks) => void;
	setBonemaskBinaries: (binaries: ArrayBuffer | undefined) => void;
};

export const useScanStore = create(
	immer<ScanState & ScanActions>((set) => ({
		auxMeshObjects: [],
		auxPointCloudObjects: [],
		bonemaskBinaries: undefined,
		landmarks: {primary: [], secondary: []},
		setAuxMeshObjects(objects) {
			set((state) => {
				state.auxMeshObjects = objects;
			});
		},
		setAuxPointCloudObjects(objects) {
			set((state) => {
				state.auxPointCloudObjects = objects;
			});
		},
		setBonemaskBinaries(binaries) {
			set((state) => {
				state.bonemaskBinaries = binaries;
			});
		},
		setLandmarks(landmarks) {
			set((state) => {
				state.landmarks = landmarks;
			});
		},
	})),
);
