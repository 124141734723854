import {useEffect} from 'react';
import {Entries} from 'type-fest';

import {
	calculateRotationAnglesInDegrees,
	createResectionPlanes,
	initializeResectionPlanes,
	isDefined,
	setSelectedResectionPlanePair,
} from '@/library';
import Scan, {initialResectionPlanesAdjustments} from '@/library/models/scan';
import {getViewports} from '@/state/viewports';
import {useGlobalState, useViewportsStore} from '@/state';
import {type ResectionPlaneKey, type ResectionPlanePairKey} from '@/types';

const useLoadResectionPlanes = ({
	arePointCloudsLoaded,
	scan,
}: {
	arePointCloudsLoaded: boolean;
	scan: Scan;
}) => {
	const {
		meshes,
		resectionPlanes: {
			adjustments: resectionPlanesAdjustments,
			setAdjustments: setResectionPlanesAdjustments,
			state,
			setState,
		},
		scan: {landmarks},
	} = useGlobalState();
	const {bounds, isVtkInitialized, setBounds} = useViewportsStore();

	// Instantiate resection planes
	useEffect(() => {
		if (
			state === 'loading' &&
			isVtkInitialized &&
			arePointCloudsLoaded &&
			meshes.loaded &&
			isDefined(scan.resectionPlanes) &&
			scan.hasReachedMilestone('landmarked')
		) {
			const {volume: volumeViewport} = getViewports();

			createResectionPlanes({
				resectionPlanes: scan.resectionPlanes,
				setBounds,
			});
			volumeViewport.render();

			const initialAdjustments = initialResectionPlanesAdjustments;
			const adjustedAdjustments = structuredClone(initialAdjustments);

			if (scan.latestResectionPlanesAdjustment) {
				setResectionPlanesAdjustments(scan.latestResectionPlanesAdjustment);
			} else {
				for (const [pairKey, pairValue] of Object.entries(
					scan.resectionPlanes,
				) as Entries<typeof scan.resectionPlanes>) {
					for (const [planeKey, planeValue] of Object.entries(
						pairValue,
					) as Entries<typeof pairValue>) {
						adjustedAdjustments[pairKey][planeKey].position.z =
							planeValue.zOffsetInitial;

						adjustedAdjustments[pairKey][planeKey].rotation =
							calculateRotationAnglesInDegrees({
								landmarks,
								normal:
									scan.resectionPlanes[pairKey as ResectionPlanePairKey][
										planeKey as ResectionPlaneKey
									].normal,
								planeKey: planeKey as ResectionPlaneKey,
							});
					}
				}

				setResectionPlanesAdjustments(adjustedAdjustments);
			}

			setState('initialRendering');
		}
	}, [
		meshes.loaded,
		arePointCloudsLoaded,
		scan.resectionPlanes,
		scan.state,
		setResectionPlanesAdjustments,
		state,
		isVtkInitialized,
		scan,
		setBounds,
		setState,
		landmarks,
	]);

	// Update resection planes position/rotation from saved state
	useEffect(() => {
		async function initialize() {
			if (
				state !== 'initialRendering' ||
				bounds === undefined ||
				scan.resectionPlanes === undefined
			) {
				return;
			}

			initializeResectionPlanes({
				landmarks,
				resectionPlanes: scan.resectionPlanes,
				resectionPlanesAdjustments,
				resectionPlanesAdjustmentsSaved: scan.latestResectionPlanesAdjustment,
			});

			await setSelectedResectionPlanePair({
				pair: 'anatomical',
				setState: false, // We want to control the state
			});

			setState('ready');
		}

		initialize().catch(console.error);
	}, [
		resectionPlanesAdjustments,
		bounds,
		scan.resectionPlanes,
		setState,
		state,
		scan.latestResectionPlanesAdjustment,
		landmarks,
	]);
};

export default useLoadResectionPlanes;
