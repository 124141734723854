import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkCircleSource from '@kitware/vtk.js/Filters/Sources/CircleSource';
import vtkLineSource from '@kitware/vtk.js/Filters/Sources/LineSource';
import vtkCubeSource from '@kitware/vtk.js/Filters/Sources/CubeSource';
import vtkCylinderSource from '@kitware/vtk.js/Filters/Sources/CylinderSource';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';

import {type DigitalTwinData, type Vector3D} from '@/library/digital-twins';
import {type ActorAndSource, type GumballData} from '@/library/gumball';

export function makeCircle(options: {
	center: [number, number, number];
	direction: [number, number, number];
	radius: number;
	resolution: number;
	color: [number, number, number];
}): ActorAndSource {
	const {center, direction, radius, resolution, color} = options;
	const circleSource = vtkCircleSource.newInstance({
		radius,
		resolution,
	});
	circleSource.setCenter(...center);
	circleSource.setDirection(...direction);

	const circleMapper = vtkMapper.newInstance();
	circleMapper.setInputConnection(circleSource.getOutputPort());

	const circleActor = vtkActor.newInstance();
	circleActor.setMapper(circleMapper);
	const property = circleActor.getProperty();
	property.setColor(...color);
	property.setEdgeVisibility(true);
	property.setEdgeColor(...color);
	property.setRepresentation(1); // Wireframe representation
	property.setLineWidth(3);
	property.setLighting(false);
	property.setAmbient(1);
	property.setDiffuse(0);
	property.setSpecular(0);

	return {actor: circleActor, source: circleSource};
}

export function makeLine(options: {
	axis: 'x' | 'y' | 'z';
	color: [number, number, number];
	end: [number, number, number];
	start: [number, number, number];
}): ActorAndSource {
	const {color, end, start} = options;
	const lineSource = vtkLineSource.newInstance({resolution: 1000});
	lineSource.setPoint1(...start);
	lineSource.setPoint2(...end);

	const lineMapper = vtkMapper.newInstance();
	lineMapper.setInputConnection(lineSource.getOutputPort());

	const lineActor = vtkActor.newInstance();
	lineActor.setMapper(lineMapper);
	const property = lineActor.getProperty();
	property.setColor(...color);
	property.setLineWidth(3);
	property.setLighting(false);
	property.setAmbient(1);
	property.setDiffuse(0);
	property.setSpecular(0);

	return {actor: lineActor, source: lineSource};
}

export function makeCube(options: {
	center: Vector3D;
	color: [number, number, number];
	dimensions: Vector3D;
	lighting?: boolean;
}): ActorAndSource {
	const {center, color, dimensions, lighting = true} = options;
	const cubeSource = vtkCubeSource.newInstance({
		center: [center.x, center.y, center.z],
		xLength: dimensions.x,
		yLength: dimensions.y,
		zLength: dimensions.z,
	});

	const cubeMapper = vtkMapper.newInstance();
	cubeMapper.setInputConnection(cubeSource.getOutputPort());

	const cubeActor = vtkActor.newInstance();
	cubeActor.setMapper(cubeMapper);
	const property = cubeActor.getProperty();
	property.setColor(...color);
	property.setLighting(lighting);

	return {actor: cubeActor, source: cubeSource};
}

export function makeCylinder(options: {
	center: Vector3D;
	color: [number, number, number];
	dimensions: Vector3D;
	lighting?: boolean;
	resolution?: number;
}): ActorAndSource {
	const {center, color, dimensions, lighting = true} = options;
	const cylinderSource = vtkCylinderSource.newInstance({
		height: dimensions.z,
		center: [center.x, center.y, center.z],
		radius: 0.5 * Math.max(dimensions.x, dimensions.y),
		direction: [0, 0, 1],
	});
	cylinderSource.setResolution(options.resolution ?? 100);
	const cylinderMapper = vtkMapper.newInstance();
	cylinderMapper.setInputConnection(cylinderSource.getOutputPort());

	const cylinderActor = vtkActor.newInstance();
	cylinderActor.setMapper(cylinderMapper);
	const property = cylinderActor.getProperty();
	property.setColor(...color);
	property.setLighting(lighting);
	return {actor: cylinderActor, source: cylinderSource};
}

export function makeSphere(options: {
	center: Vector3D;
	color: [number, number, number];
	dimensions: Vector3D;
	lighting?: boolean;
}): ActorAndSource {
	const {center, color, dimensions, lighting = true} = options;
	const sphereSource = vtkSphereSource.newInstance({
		center: [center.x, center.y, center.z],
		radius: 0.5 * Math.max(dimensions.x, dimensions.y, dimensions.z),
	});
	const sphereMapper = vtkMapper.newInstance();
	sphereMapper.setInputConnection(sphereSource.getOutputPort());

	const sphereActor = vtkActor.newInstance();
	sphereActor.setMapper(sphereMapper);
	const property = sphereActor.getProperty();
	property.setColor(...color);
	property.setLighting(lighting);

	return {actor: sphereActor, source: sphereSource};
}

export function makeGumball(actorData: DigitalTwinData): GumballData {
	const {dimensions, type} = actorData;

	const maxDimension = Math.max(dimensions.x, dimensions.y, dimensions.z);
	let radius = (maxDimension * 2) / 2;
	let circularObject = false;

	if (type === 'drill' || type === 'ream') {
		circularObject = true;

		radius =
			type === 'ream'
				? maxDimension / 2
				: Math.max(dimensions.x / 2, dimensions.y / 2, dimensions.z);
	}

	const rotationHandles = {
		x: makeCircle({
			center: [0, 0, 0],
			direction: [1, 0, 0],
			radius,
			resolution: 1000,
			color: [1, 0, 0],
		}),
		y: makeCircle({
			center: [0, 0, 0],
			direction: [0, 1, 0],
			radius,
			resolution: 1000,
			color: [0, 1, 0],
		}),
		z: makeCircle({
			center: [0, 0, 0],
			direction: [0, 0, 1],
			radius,
			resolution: 1000,
			color: [0, 0, 1],
		}),
	};

	const translationHandles = {
		x: makeLine({
			start: [-radius, 0, 0],
			end: [radius, 0, 0],
			color: [1, 0, 0],
			axis: 'x',
		}),
		y: makeLine({
			start: [0, -radius, 0],
			end: [0, radius, 0],
			color: [0, 1, 0],
			axis: 'y',
		}),
		z: makeLine({
			start: [0, 0, -radius],
			end: [0, 0, radius],
			color: [0, 0, 1],
			axis: 'z',
		}),
	};

	const resizeHandleWidth = 2;
	const resizeHandleDimensions: Vector3D = {
		x: resizeHandleWidth,
		y: resizeHandleWidth,
		z: resizeHandleWidth,
	};

	const resizeHandles = {
		x: [
			makeCube({
				center: {
					x: circularObject ? dimensions.x / 2 : dimensions.x,
					y: 0,
					z: 0,
				},
				color: [1, 0, 0],
				dimensions: resizeHandleDimensions,
				lighting: false,
			}),
			makeCube({
				center: {
					x: circularObject ? -dimensions.x / 2 : -dimensions.x,
					y: 0,
					z: 0,
				},
				color: [1, 0, 0],
				dimensions: resizeHandleDimensions,
				lighting: false,
			}),
		],
		y: [
			makeCube({
				center: {
					x: 0,
					y: circularObject ? dimensions.y / 2 : dimensions.y,
					z: 0,
				},
				color: [0, 1, 0],
				dimensions: resizeHandleDimensions,
				lighting: false,
			}),
			makeCube({
				center: {
					x: 0,
					y: circularObject ? -dimensions.y / 2 : -dimensions.y,
					z: 0,
				},
				color: [0, 1, 0],
				dimensions: resizeHandleDimensions,
				lighting: false,
			}),
		],
		z: [
			makeCube({
				center: {
					x: 0,
					y: 0,
					z: type === 'ream' ? dimensions.z / 2 : dimensions.z,
				},
				color: [0, 0, 1],
				dimensions: resizeHandleDimensions,
				lighting: false,
			}),
			makeCube({
				center: {
					x: 0,
					y: 0,
					z: type === 'ream' ? -dimensions.z / 2 : -dimensions.z,
				},
				color: [0, 0, 1],
				dimensions: resizeHandleDimensions,
				lighting: false,
			}),
		],
	};

	return {
		rotationHandles,
		translationHandles,
		resizeHandles,
	};
}
