import type vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

import * as cornerstone from './cornerstone';
import * as vtk from './vtk';

import {type Coordinate} from '../types/3d';

export type Landmarks = {
	primary: Landmark[];
	secondary: Landmark[];
};

export type Landmark = {
	actor: vtkActor;
	center: Coordinate;
	id: string;
	label: string;
};

export function setVisibility({visibility}: {visibility: boolean}) {
	cornerstone.landmarks.setVisibility(visibility);
	vtk.landmarks.setVisibility({visibility});
}
