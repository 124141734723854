import React, {type ReactNode} from 'react';
import {Button, ToggleButtonGroup} from '@mui/joy';
import {
	type DepthDirection,
	type HeightDirection,
	type WidthDirection,
} from '@/library';

const isHeightDirection = (direction: string): direction is HeightDirection =>
	['up', 'down', 'upAndDown'].includes(direction);

const isWidthDirection = (direction: string): direction is WidthDirection =>
	['left', 'right', 'leftAndRight'].includes(direction);

const isDepthDirection = (direction: string): direction is DepthDirection =>
	['front', 'back', 'frontAndBack'].includes(direction);

function DirectionToggleButtonGroup({
	disabled,
	options,
	selectedDirection,
	onHeightDirectionChange,
	onWidthDirectionChange,
	onDepthDirectionChange,
}: {
	readonly disabled: boolean;
	readonly options: Array<{
		value: HeightDirection | WidthDirection | DepthDirection;
		icon: ReactNode;
	}>;
	readonly selectedDirection: HeightDirection | WidthDirection | DepthDirection;
	readonly onHeightDirectionChange?: (newDirection: HeightDirection) => void;
	readonly onWidthDirectionChange?: (newDirection: WidthDirection) => void;
	readonly onDepthDirectionChange?: (newDirection: DepthDirection) => void;
}) {
	return (
		<ToggleButtonGroup<HeightDirection | WidthDirection | DepthDirection>
			disabled={disabled}
			value={selectedDirection}
			onChange={(event, newDirection) => {
				if (!newDirection) {
					return;
				}

				if (isHeightDirection(newDirection)) {
					onHeightDirectionChange?.(newDirection);
				} else if (isWidthDirection(newDirection)) {
					onWidthDirectionChange?.(newDirection);
				} else if (isDepthDirection(newDirection)) {
					onDepthDirectionChange?.(newDirection);
				}
			}}
		>
			{options.map(({value, icon}) => (
				<Button key={value} value={value} name={value}>
					{icon}
				</Button>
			))}
		</ToggleButtonGroup>
	);
}

export default DirectionToggleButtonGroup;
