import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

import {type Annotation, type AnnotationTool} from '@/library';

type State = {
	annotations: Annotation[];
	areAnnotationsDirty: boolean;
	notes: string;
	currentTool: AnnotationTool;
	setState: (state: State['state']) => void;
	state: 'ready' | 'loading' | 'updating' | 'error';
};

type Actions = {
	addAnnotation: (annotation: Annotation) => void;
	removeAnnotation: (id: string) => void;
	setAreAnnotationsDirty: (areAnnotationsDirty: boolean) => void;
	setCurrentTool: (tool: AnnotationTool) => void;
	setNotes: (notes: string) => void;
	updateAnnotation: (id: string, newData: Partial<Annotation>) => void;
};

export const useAnnotationsStore = create(
	immer<State & Actions>((set) => ({
		annotations: [],
		addAnnotation(annotation) {
			set((state) => {
				const newAnnotation = {...annotation};
				state.annotations.push(newAnnotation);
			});
		},
		areAnnotationsDirty: false,
		currentTool: 'RectangleROI',
		notes: '',
		removeAnnotation(id) {
			set((state) => {
				state.annotations = state.annotations.filter((a) => a.id !== id);
			});
		},
		setAreAnnotationsDirty(areAnnotationsDirty) {
			set((state) => {
				state.areAnnotationsDirty = areAnnotationsDirty;
			});
		},
		setCurrentTool(tool) {
			set((state) => {
				state.currentTool = tool;
			});
		},
		setNotes(notes) {
			set((state) => {
				state.notes = notes;
			});
		},
		setState(newState: State['state']) {
			set((state) => {
				state.state = newState;
			});
		},
		state: 'loading',
		updateAnnotation(id, newData) {
			set((state) => {
				const index = state.annotations.findIndex((a) => a.id === id);

				if (index !== -1) {
					const existingAnnotation = state.annotations[index];
					state.annotations[index] = {
						...existingAnnotation,
						points: newData.points ?? existingAnnotation.points,
						note: newData.note ?? existingAnnotation.note,
						data: newData.data ?? existingAnnotation.data,
						screenshotDataUrl:
							newData.screenshotDataUrl ?? existingAnnotation.screenshotDataUrl,
					};
				}
			});
		},
	})),
);
