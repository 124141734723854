import html2canvas from 'html2canvas';
import {elements as cornerstoneElements} from '@/library/cornerstone/elements';
import {getViewports} from '@/state/viewports';
import {ViewportElementKey} from '@/library/cornerstone/viewport-element-keys';

const toggleVisibility = (
	element: HTMLElement,
	visibility: 'hidden' | 'visible',
) => {
	element.style.visibility = visibility;
};

async function captureVolumeViewport(): Promise<string> {
	const {volume: volumeViewport} = getViewports();

	// Force a render
	volumeViewport.renderWindow?.render();

	// Set up the capture
	const capturePromise =
		volumeViewport.openGLRenderWindow?.captureNextImage('image/png');

	// Trigger another render to resolve the capture
	volumeViewport.renderWindow?.render();

	// Wait for the capture to complete
	const imageDataUrl = await capturePromise;

	if (!imageDataUrl) {
		throw new Error('Failed to capture volume viewport image');
	}

	// The imageDataUrl is already a complete data URL, so we can return it directly
	return imageDataUrl;
}

async function captureOtherViewport(
	viewportElement: HTMLElement,
	elementId: string,
): Promise<string> {
	const elementToHide = document.querySelector(`#${elementId}`) as HTMLElement;
	if (!elementToHide) {
		throw new Error(`Element with id ${elementId} not found`);
	}

	toggleVisibility(elementToHide, 'hidden');

	const canvas = await html2canvas(viewportElement, {
		onclone(document) {
			const clonedElementToHide = document.querySelector(
				`#${elementId}`,
			) as HTMLElement;
			if (!clonedElementToHide) {
				throw new Error(`Cloned element with id ${elementId} not found`);
			}

			toggleVisibility(clonedElementToHide, 'hidden');
		},
	});
	toggleVisibility(elementToHide, 'visible');
	return canvas.toDataURL();
}

export async function captureScreenshot({
	viewportKey,
}: {
	viewportKey: ViewportElementKey;
}): Promise<string> {
	const elementId = `viewport\\:${viewportKey}`;
	if (viewportKey === 'volume') {
		return captureVolumeViewport();
	}

	const viewportElement = cornerstoneElements[viewportKey];

	if (!viewportElement) {
		throw new Error(`Viewport element with key ${viewportKey} not found`);
	}

	return captureOtherViewport(viewportElement, elementId);
}
